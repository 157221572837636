import React, { useEffect, useState } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { getUrls } from "./routes";
import history from "./history";

const Routes = (props) => {
  const [dynamicRoutes, setDynamicRoutes] = useState(false);

  useEffect(() => {
    if (!dynamicRoutes) {
      getUrls().then((res) => {
        setDynamicRoutes(res);
      });
    }
  },[]);
  return (
    <Router history={history}>
      <Switch>
        {dynamicRoutes &&
          dynamicRoutes.map((route, index) => {
            const { component: RouteComponent, routes, exact, path } = route;
            return (
              <Route
                exact={exact}
                key={index}
                path={path}
                render={() => <RouteComponent {...props} routes={routes} />}
              />
            );
          })}
      </Switch>
    </Router>
  );
};

export default Routes;
