import axios from "axios";
import Survey from "../components/survey-page";
import LandingPage from "../components/landing";
import AboutUs from "../components/about-us";
import ContactUs from "../components/contact-us";
import PrivacyPolicy from "../components/privacy-policy";
import TermaCondition from "../components/terms-condition";
import ThankyouPage from "../components/thankyou";
import DoNotQualifyPage from "../components/do-not-qualify";
import BlogPage from "../components/blog";
import ErrorPage from "../components/page-not-found";
import Utils from "../utils/index";
import PageNotFound from "../components/page-not-found";

const dynamicRoute = [
  {
    component: LandingPage,
    exact: true,
    path: "/:pageurl",
    title: "Home Page",
  },
  {
    component: DoNotQualifyPage,
    exact: true,
    path: "/:pageurl/do-not-qualify/",
    title: "do-not-qualify",
  },
  {
    component: ThankyouPage,
    exact: true,
    path: "/:pageurl/thank-you/",
    title: "Thankyou Page",
  },
];

const getUrls = async () => {
  const host = Utils.getHost();
  const headers = {
    Authorization: "c2xkZ21sa21nbG1s",
  };
  const pathName = "/"+host.pathname.split('/')[1];
  const getWebsiteName = `${host.apiUrl}get-domain/${host.domain}${pathName}`
  const subDomainName = await axios.get(`${getWebsiteName}`, { headers });

  const subdomain = subDomainName.data.response.domain;
  localStorage.setItem("subDomainName", subdomain);
  const hostUrl = `${host.apiUrl}getpageurl/list/${subdomain}`;

  const res = await axios.get(`${hostUrl}`, { headers });
  const arr = res?.data?.response?.content_manage_sites?.map((item) => {
    if (
      (!item.is_external || item.is_external === "false") &&
      item.page_id !== 1
    ) {
      dynamicRoute.push({
        pageId: item.page_id,
        component:
          item.page_id === 2
            ? AboutUs
            : item.page_id === 3
              ? ContactUs
              : item.page_id === 4
                ? PrivacyPolicy
                : item.page_id === 5
                  ? TermaCondition
                  : item.page_id === 6
                    ? Survey
                    : item.page_id === 7
                      ? ThankyouPage
                      : item.page_id === 8
                        ? BlogPage
                        : PageNotFound,
        exact: true,
        path: pathName + item.page_url || "/page_not_found",
        title: "",
      });
    }
  });
  dynamicRoute.push({
    component: ErrorPage,
    path: "*",
    title: "Page Not Found",
  });
  return dynamicRoute;
};

export { getUrls };
