import React, { useEffect, useState } from "react";
import { object } from "prop-types";
import queryString from "query-string";
import history from "../../routes/history";
import $ from "jquery";
import AdvertlyPlugin from "../shared/advertly-plugin";
import Utils from "../../utils";
import Card from "../../views/card/card";

const ThankyouMain = (props) => {
  const { siteData, layoutType } = props;
  const [showbutton, setShowbutton] = useState(false);
  const [isLoading,setIsLoading]=useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowbutton(true);
    }, 3000);
  }, []);

  let siteDataValue = siteData.content_manage_sites.filter(filterByID)[0];
  let siteDataHome = siteData.content_manage_sites.filter((item) => {
    return item.page_id === 1;
  })[0];
  let siteDataVal = Utils.getPageContent(siteData, 7);
  const showAdvertlyAds =
  siteDataVal && siteDataVal?.section_third_header === "yes";

  var isQuickfinn = localStorage.getItem("quickandeasyFinnace");

  function filterByID(item) {
    if (item.page_id === (layoutType === "3" || layoutType === 3 ? 1 : 7)) {
      return item;
    }
  }

  const renderScript = (siteDataHome) => {
    var email = localStorage.getItem("email");
    var scr = "";

    if (email && email !== undefined && email !== "" && email !== null) {
      //my broadband compare pixel
      if (
        window.location.host === "www.mybroadbandcompare.com.au" ||
        window.location.host === "mybroadbandcompare.com.au"
      ) {
        scr = `<!-- Offer Conversion: My Broadband Compare - July 2020 - CPL -->
        <iframe src="https://tracking.gopsjump.com.au/SL5E9?adv_sub=${email}" scrolling="no" frameborder="0" width="1" height="1"></iframe>
        <!-- // End Offer Conversion -->`;
        $("head").append(`${scr}`);
      }

      if (
        window.location.host === "www.quickeasyfinance.com.au" ||
        window.location.host === "quickeasyfinance.com.au"
      ) {
        if (isQuickfinn === "NA" || isQuickfinn === null) {
          scr = `<!-- Offer Conversion: Quick and Easy Finance - Personal Loan - CPL -->
          <iframe src="https://tracking.gopsjump.com.au/SL5GC?adv_sub=${email}" scrolling="no" frameborder="0" width="1" height="1"></iframe>
          <!-- // End Offer Conversion --> `;
          $("head").append(`${scr}`);
        }
      }

      //quick and easy hearing pixel
      if (
        window.location.host === "www.quickandeasyhearing.com" ||
        window.location.host === "quickandeasyhearing.com"
      ) {
        scr = `<!-- Offer Conversion: Quick & Easy Hearing - Aug 20 - CPL -->
        <iframe src="https://tracking.gopsjump.com.au/SL5EN?adv_sub=${email}" scrolling="no" frameborder="0" width="1" height="1"></iframe>
        <!-- // End Offer Conversion -->`;
        $("head").append(`${scr}`);
      }
      if (
        window.location.host ===
          "www.govthearingaids.quickandeasyhearing.com" ||
        window.location.host === "govthearingaids.quickandeasyhearing.com"
      ) {
        scr = `<!-- Offer Conversion: Quick & Easy Hearing - Aug 20 - CPL -->
        <iframe src="https://tracking.gopsjump.com.au/SL5EN?adv_sub=${email}" scrolling="no" frameborder="0" width="1" height="1"></iframe>
        <!-- // End Offer Conversion -->`;
        $("head").append(`${scr}`);
      }

      //support with debt pixel
      if (
        window.location.host === "www.supportwithdebt.com.au" ||
        window.location.host === "supportwithdebt.com.au"
      ) {
        scr = `<!-- Offer Conversion: Support with Debt - Jun 20 - CPA -->
        <iframe src="https://tracking.gopsjump.com.au/SL5Dh?adv_sub=${email}" scrolling="no" frameborder="0" width="1" height="1"></iframe>
        <!-- // End Offer Conversion -->`;
        $("head").append(`${scr}`);
      }
      //my energy compare pixel
      if (
        window.location.host === "www.myenergycompare.com.au" ||
        window.location.host === "myenergycompare.com.au"
      ) {
        scr = `<!-- Offer Conversion: My Energy Compare - Sept 23-->
        <iframe src="https://tracking.gopsjump.com.au/SL5Em?adv_sub=${email}" scrolling="no" frameborder="0" width="1" height="1"></iframe>
        <!-- // End Offer Conversion -->`;
        $("head").append(`${scr}`);
      }

      //my health compare pixel
      if (
        window.location.host === "www.myhealthcompare.com.au" ||
        window.location.host === "myhealthcompare.com.au"
      ) {
        scr = `<!-- Offer Conversion: MyHealthCompare -  SEP2020 -->
        <iframe src="https://tracking.gopsjump.com.au/SL5Db?adv_sub=${email}" scrolling="no" frameborder="0" width="1" height="1"></iframe>
        <!-- // End Offer Conversion -->`;
        $("head").append(`${scr}`);
      }

      // CRS-492 - Added on 16 dec 2021
      //quickandeasyhearing pixel codes
      if (
        window.location.host === "www.au.quickandeasyhearing.com" ||
        window.location.host === "au.quickandeasyhearing.com" ||
        window.location.host === "www.ca.quickandeasyhearing.com" ||
        window.location.host === "ca.quickandeasyhearing.com" ||
        window.location.host === "www.au1.quickandeasyhearing.com" ||
        window.location.host === "au1.quickandeasyhearing.com" ||
        window.location.host === "www.canadian2.quickandeasyhearing.com" ||
        window.location.host === "canadian2.quickandeasyhearing.com" ||
        window.location.host === "www.canadian.quickandeasyhearing.com" ||
        window.location.host === "canadian.quickandeasyhearing.com" ||
        window.location.host === "www.nz2.quickandeasyhearing.com" ||
        window.location.host === "nz2.quickandeasyhearing.com" ||
        window.location.host === "www.nz.quickandeasyhearing.com" ||
        window.location.host === "nz.quickandeasyhearing.com" ||
        window.location.host ===
          "www.govthearingaids.quickandeasyhearing.com" ||
        window.location.host === "govthearingaids.quickandeasyhearing.com" ||
        window.location.host === "www.trial.quickandeasyhearing.com" ||
        window.location.host === "trial.quickandeasyhearing.com" ||
        window.location.host === "www.cochlear.quickandeasyhearing.com" ||
        window.location.host === "cochlear.quickandeasyhearing.com" ||
        window.location.host === "www.hearingrelief.quickandeasyhearing.com" ||
        window.location.host === "hearingrelief.quickandeasyhearing.com" ||
        window.location.host === "www.quickandeasyhearing.com" ||
        window.location.host === "quickandeasyhearing.com"
      ) {
        let query_string = queryString.parse(
          history.location.search ? history.location.search.toLowerCase() : ""
        );
        //Taboola Conversion: quickandeasyhearing -  Dec2021
        if (query_string.tbclid) {
          var scrTaboola = `<!-- Taboola Conversion: quickandeasyhearing -  Dec2021 -->
        <iframe src="https://trc.taboola.com/actions-handler/log/3/s2s-action?click-id=${query_string.tbclid}&name=lead" scrolling="no" frameborder="0" width="1" height="1"></iframe>
        <!-- // End Offer Conversion -->`;
          $("body").append(`${scrTaboola}`);
        }
        //Outbrain Conversion: quickandeasyhearing -  Dec2021
        if (query_string.outbrainclickid) {
          let name =
            siteData.country === "AU"
              ? "Quick and Easy Conversion"
              : siteData.country === "NZ"
              ? "NZ Quick and Easy Hearing"
              : siteData.country === "CA"
              ? "QEH Canada"
              : "";
          var scrOutbrain = `<!-- Outbrain Conversion: quickandeasyhearing -  Dec2021 -->
          <iframe src="https://tr.outbrain.com/unifiedPixel?ob_click_id=${query_string.outbrainclickid}&name=${name}" scrolling="no" frameborder="0" width="1" height="1"></iframe>
          <!-- // End Offer Conversion -->`;
          $("body").append(`${scrOutbrain}`);
        }
      }
    }

    $("head").append(
      siteDataHome && siteDataHome.sub_heading && `${siteDataHome.sub_heading}`
    );
  };

  const backTohome = () => {
    const host = Utils.getHost().websiteUrl;
    return (
      <>
        {host === "v6.ozhouseprice.com.au" ||
        host === "www.v6.ozhouseprice.com.au" ||
        host === "v5.ozhouseprice.com.au" ||
        host === "www.v5.ozhouseprice.com.au" ||
        host === "v4.ozhouseprice.com.au" ||
        host === "www.v4.ozhouseprice.com.au" ||
        host === "www.hca.quickandeasyhearing.com" ||
        host === "hca.quickandeasyhearing.com" ||
        host === "www.hca2.quickandeasyhearing.com" ||
        host === "hca2.quickandeasyhearing.com" ? (
          <></>
        ) : (
          <>
            <div className="button-home">
              <a href={`/${window.location.pathname.split('/')[1]}`} onClick={() => localStorage.removeItem("userdata")}>
                back to homepage
              </a>
            </div>
          </>
        )}
      </>
    );
  };

  const handlecallback=(data)=>{
    setIsLoading(data);
  }
  return (
    <>
    {showAdvertlyAds&&isLoading&& <Card />}
      {<AdvertlyPlugin siteData={siteData} callback={handlecallback} />}
      {layoutType === "1" ||
      layoutType === "2" ||
      layoutType === "7" ||
      layoutType === 1 ||
      layoutType === 2 ||
      layoutType === 7 ||
      layoutType === "8" ||
      layoutType === 8 ? (
        <>
          {
            <section
              className="thankyou-section"
              style={{
                backgroundImage: `url(${
                  siteDataValue.section_first_img !== undefined
                    ? siteDataValue.section_first_img
                    : ""
                })`,
              }}
            >
              <div className="container tankkyou-container">
                {renderScript(siteDataHome)}
                <div className="row">
                  <div className="col-sm-12">
                    <div className="descript-thankyou">
                      {isQuickfinn === "NA" || isQuickfinn === null ? (
                        <h1>{siteDataValue.page_title}</h1>
                      ) : (
                        <div>
                          <h1>{isQuickfinn}</h1>
                          <a href="http://tracking.gopsjump.com.au/aff_c?offer_id=6364&aff_id=8181&url_id=25606">
                            Click here
                          </a>
                        </div>
                      )}
                      <p
                        dangerouslySetInnerHTML={{
                          __html: siteDataValue.section_second_paragraph,
                        }}
                      ></p>
                      {showbutton && backTohome()}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          }
        </>
      ) : (
        <>
          <section className="bgbluew" style={{ height: "0px" }}></section>
          <section
            className="innerpage thankyou-section"
            style={{
              backgroundImage: `url(${
                siteDataValue.section_first_img !== undefined
                  ? siteDataValue.section_first_img
                  : ""
              })`,
            }}
          >
            <div className="container tankkyou-container">
              {renderScript(siteDataHome)}
              <div className="row">
                <div className="col-sm-12">
                  <div className="descript-thankyou">
                    {isQuickfinn === "NA" || isQuickfinn === null ? (
                      <h1>{siteDataValue.page_title}</h1>
                    ) : (
                      <div>
                        <h1>{isQuickfinn}</h1>
                        <a href="http://tracking.gopsjump.com.au/aff_c?offer_id=6364&aff_id=8181&url_id=25606">
                          Click here
                        </a>
                      </div>
                    )}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: siteDataValue.section_second_paragraph,
                      }}
                    ></p>
                    {showbutton && backTohome()}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

ThankyouMain.propTypes = {
  siteData: object,
};

ThankyouMain.defaultProp = {
  layoutType: "3",
};
export default ThankyouMain;
