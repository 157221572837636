import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { string, object } from "prop-types";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import $ from "jquery";
import Utils from "../../utils/index";
import { MapAu } from "../../assets/svg/map_au.js";
import { MapNz } from "../../assets/svg/map_nz.js";
import { MapCa } from "../../assets/svg/map_ca.js";
import { MapUsa } from "../../assets/svg/map_us";
import "./blog.scss";
import UserIcon from "../../assets/images/userIcon.svg";
import CalendarIcon from "../../assets/images/calendarIcon.svg";
import FooterDisclaimer from "../shared/footer-disclaimer";

const BlogBody = (props) => {
  const { siteData, layoutType, page, isPageRefreshed } = props;
  let siteDataValue = Utils.getPageContent(siteData, 8);

  if (siteDataValue === undefined && siteDataValue === null) {
    window.location.href = "/";
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit);
    }
    watchScroll();

    $(".group-editible:has(div.tableGrid)").addClass("expendEditable");
  });

  function logit() {
    var scroll = window.pageYOffset;
    var totalHeight = document.body.scrollHeight;
    var persentHeign = (scroll * 100) / totalHeight;
    if (persentHeign > 68) {
      $("#scrollImg").css({ position: "absolute" });
    } else {
      $("#scrollImg").css({ position: "fixed" });
    }
  }

  const handleClick = ({ page_url, is_external, target }) => {
    if (
      (window.location.host === "abcb.supportwithdebt.com.au" ||
        window.location.host === "www.abcb.supportwithdebt.com.au") &&
      (page_url === siteDataContactUs?.page_url || page_url === "contact_us")
    ) {
      window.open("https://www.acquirely.com.au/contact", "_blank");
    } else {
      Utils.handleClickUrl(page_url, is_external, target);
    }
  };

  const handleClickNewTab = ({ page_url, is_external, target }) => {
    Utils.handleClickUrl(page_url, is_external, target);
  };

  const handleAdRedirectClick = (redirectUrl, target) => {
    Utils.windowOpen(redirectUrl, target === "true" ? "_blank" : "_self");
  };

  const homeData = Utils.getPageContent(siteData, 1);
  const siteDataContactUs = Utils.getPageContent(siteData, 3);
  const siteDataPrivacyPolicy = Utils.getPageContent(siteData, 4);
  const siteDataTermsCondition = Utils.getPageContent(siteData, 5);
  const survey_page = Utils.getPageContent(siteData, 6);
  const hideLogoLink = Utils.hideLogoLink(homeData);
  const reverseAdBannerOrder = Utils.reverseOrderAdBanner(siteDataValue);
  const hideAdBannerFirst = Utils.hideFirstAdBanner(siteDataValue);
  const greenGoldMap = Utils.greenGoldMap();
  const goldGreenMap = Utils.goldGreenMap();
  const hideSurveyPage = Utils.hideSurveyPageLink(homeData);
  //console.log("survey_page", survey_page);
  const renderScript = (siteData) => {
    if (isPageRefreshed) {
      $("head").append(siteData && siteData.fb_script && siteData.fb_script);
      $("body").append(
        siteData && siteData.google_script && siteData.google_script
      );
    }
  };

  const rendermetatags = (siteData) => {
    return (
      <Helmet>
        <title>{siteDataValue.meta_title}</title>
        <meta name="description" content={siteDataValue.meta_description} />
        <link rel="icon" href={siteData.favicon} sizes="16x16" />
      </Helmet>
    );
  };

  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  var surveyMapUrl =
    siteDataValue.section_first_img &&
    siteDataValue.section_first_img !== undefined &&
    siteDataValue.section_first_img !== null &&
    siteDataValue.section_first_img !== ""
      ? {
          page_url: siteDataValue?.section_first_img,
          is_external: survey_page?.is_external,
          target: survey_page?.target,
        }
      : survey_page;

  const renderAdBannerImages = () => {
    return reverseAdBannerOrder ? (
      <>
        {!hideAdBannerFirst && (
          <li>
            <span
              onClick={() =>
                handleAdRedirectClick(
                  siteDataValue.list_second_header,
                  siteDataValue.target
                )
              }
              style={{ cursor: "pointer" }}
            >
              <img
                src={siteDataValue.list_second_img}
                className="img-responsive"
                alt={siteDataValue.lsi_alt_text}
              ></img>
            </span>
          </li>
        )}
        <li>
          <span
            onClick={() =>
              handleAdRedirectClick(
                siteDataValue.list_first_header,
                siteDataValue.target
              )
            }
            style={{ cursor: "pointer" }}
          >
            <img
              src={siteDataValue.list_first_img}
              className="img-responsive"
              alt={siteDataValue.lfi_alt_text}
            ></img>
          </span>
        </li>
      </>
    ) : (
      <>
        {!hideAdBannerFirst && (
          <li>
            <span
              onClick={() =>
                handleAdRedirectClick(
                  siteDataValue.list_first_header,
                  siteDataValue.target
                )
              }
              style={{ cursor: "pointer" }}
            >
              <img
                src={siteDataValue.list_first_img}
                className="img-responsive"
                alt={siteDataValue.lfi_alt_text}
              ></img>
            </span>
          </li>
        )}
        <li>
          <span
            onClick={() =>
              handleAdRedirectClick(
                siteDataValue.list_second_header,
                siteDataValue.target
              )
            }
            style={{ cursor: "pointer" }}
          >
            <img
              src={siteDataValue.list_second_img}
              className="img-responsive"
              alt={siteDataValue.lsi_alt_text}
            ></img>
          </span>
        </li>
      </>
    );
  };
  return (
    <>
      {layoutType === "1" && (
        <>
          {renderScript(siteData)}
          {(page === 8 || page === "8") && rendermetatags(siteData)}
          <div
            className={`standard_blog_${
              siteData && siteData.primary_color
                ? siteData.primary_color
                : "default-theme"
            }`}
          >
            <Navbar
              className="header"
              collapseOnSelect
              expand="lg"
              bg="ligth"
              variant="dark"
            >
              <div className="container">
                <div className="row">
                  {hideLogoLink ? (
                    <img
                      src={siteData.logo}
                      className="img-responsive"
                      alt=""
                    />
                  ) : (
                    <Navbar.Brand
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick({ page_url: "/" })}
                    >
                      <img
                        src={siteData.logo}
                        className="img-responsive"
                        alt=""
                      />
                    </Navbar.Brand>
                  )}
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto" />

                    <Nav>
                      {hideSurveyPage ? (
                        <></>
                      ) : (
                        <Nav.Link
                          onClick={() => handleClickNewTab(survey_page)}
                          className="header-button"
                        >
                          {siteDataValue.section_first_header}
                        </Nav.Link>
                      )}
                    </Nav>
                  </Navbar.Collapse>
                </div>
              </div>
            </Navbar>
            {siteData &&
            (siteData.primary_color === "fourth-theme" ||
              siteData.primary_color === "fifth-theme") ? (
              <section className="home-section-top blogSectionTop">
                <div className="container">
                  <div className="row">
                    <div className="col-md-9">
                      <div className="blogHeader">
                        <div className="blogHeaderText">
                          <h1>{siteDataValue.section_first_paragraph}</h1>
                        </div>

                        <ul>
                          <li>
                            <p>
                              <b>Editor name:</b>
                              {siteDataValue.list_third_header}
                            </p>
                            <p>
                              <b>Date:</b>{" "}
                              {new Date(
                                siteDataValue.updated_at
                              ).toDateString()}
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="mid-content">
                            <div className="blogImg">
                              <img
                                src={siteDataValue.section_third_img}
                                className="img-responsive"
                                alt=""
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(survey_page)}
                              />
                            </div>
                            <div className="mi-section-content">
                              <div className="articletextarea blogTextaria">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      siteDataValue.section_second_paragraph,
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="mi-section-content">
                              <div className="group-editible">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      siteDataValue.section_third_paragraph,
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="mi-section-content">
                              <div className="boollets-points">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      siteDataValue.section_fourth_paragraph,
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="mi-section-content">
                              <div className="choos-boollets-points">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      siteDataValue.section_fourth_paragraph_left,
                                  }}
                                ></div>
                                <div className="choosearea-section">
                                  {siteDataValue.section_second_header ===
                                    "true" ||
                                    (siteDataValue.section_second_header ===
                                      "True" && (
                                      <span
                                        onClick={() =>
                                          handleClickNewTab(surveyMapUrl)
                                        }
                                        className="svg-holder"
                                        style={{ cursor: "pointer" }}
                                      >
                                        {/* map here */}
                                        {siteData.country === "NZ" ? (
                                          <MapNz
                                            greenGoldMap={greenGoldMap}
                                            goldGreenMap={goldGreenMap}
                                          />
                                        ) : siteData.country === "CA" ? (
                                          <MapCa
                                            greenGoldMap={greenGoldMap}
                                            goldGreenMap={goldGreenMap}
                                          />
                                        ):siteData.country === "USA" ? (
                                          <MapUsa
                                            greenGoldMap={greenGoldMap}
                                            goldGreenMap={goldGreenMap}
                                          />
                                        ): (
                                          <MapAu
                                            greenGoldMap={greenGoldMap}
                                            goldGreenMap={goldGreenMap}
                                          />
                                        )}
                                      </span>
                                    ))}

                                  <div className="map__desc clearfix"></div>
                                  <div className="comparebtn">
                                    <span
                                      onClick={() =>
                                        handleClickNewTab(survey_page)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {siteDataValue.list_third_paragraph}
                                    </span>
                                  </div>
                                  <div className="averagetext">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          siteDataValue.section_fourth_paragraph_right,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <ul className="advertisement-images">
                        {renderAdBannerImages()}
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              <section className="home-section-top blogSectionTop">
                <div className="blogHeader">
                  <div className="container">
                    <div className="headerContainer">
                      <div className="blogHeaderText">
                        <h1>{siteDataValue.section_first_paragraph}</h1>
                        <ul>
                          <li>
                            <p>
                              <b>By:</b>
                              <span> {siteDataValue.list_third_header}</span>
                            </p>
                            <p>
                              {/* <b>Date:</b> */}{" "}
                              {new Date(
                                siteDataValue.updated_at
                              ).toDateString()}
                            </p>
                            {(window.location.host ===
                              "abcb.supportwithdebt.com.au" ||
                              window.location.host ===
                                "www.abcb.supportwithdebt.com.au") && (
                              <p>
                                <b>Advertorial</b>
                              </p>
                            )}
                          </li>
                        </ul>
                      </div>

                      <div className="blogImg">
                        <div
                          className="post_featured_image"
                          style={{
                            backgroundImage: `url(${siteDataValue.section_third_img})`,
                            cursor: "pointer",
                          }}
                          onClick={() => handleClick(survey_page)}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container">
                  <div className="row">
                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="mid-content">
                            <div className="mi-section-content">
                              <div className="articletextarea blogTextaria">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      siteDataValue.section_second_paragraph,
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="mi-section-content">
                              <div className="group-editible">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      siteDataValue.section_third_paragraph,
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="mi-section-content">
                              <div className="boollets-points">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      siteDataValue.section_fourth_paragraph,
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="mi-section-content">
                              <div className="choos-boollets-points">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      siteDataValue.section_fourth_paragraph_left,
                                  }}
                                ></div>
                                <div className="choosearea-section">
                                  {siteDataValue.section_second_header ===
                                    "true" ||
                                    (siteDataValue.section_second_header ===
                                      "True" && (
                                      <span
                                        onClick={() =>
                                          handleClickNewTab(surveyMapUrl)
                                        }
                                        className="svg-holder"
                                        style={{ cursor: "pointer" }}
                                      >
                                        {/* map here */}

                                        {siteData.country === "NZ" ? (
                                          <MapNz
                                            greenGoldMap={greenGoldMap}
                                            goldGreenMap={goldGreenMap}
                                          />
                                        ) : siteData.country === "CA" ? (
                                          <MapCa
                                            greenGoldMap={greenGoldMap}
                                            goldGreenMap={goldGreenMap}
                                          />
                                        ): siteData.country === "USA" ? (
                                          <MapUsa
                                            greenGoldMap={greenGoldMap}
                                            goldGreenMap={goldGreenMap}
                                          />
                                        ): (
                                          <MapAu
                                            greenGoldMap={greenGoldMap}
                                            goldGreenMap={goldGreenMap}
                                          />
                                        )}
                                      </span>
                                    ))}

                                  <div className="map__desc clearfix"></div>
                                  <div className="comparebtn">
                                    <span
                                      onClick={() =>
                                        handleClickNewTab(survey_page)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {siteDataValue.list_third_paragraph}
                                    </span>
                                  </div>
                                  <div className="averagetext">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          siteDataValue.section_fourth_paragraph_right,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <ul className="advertisement-images">
                        {renderAdBannerImages()}
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            )}
            <section className="foter-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <ul>
                      <li>
                        {siteDataPrivacyPolicy && (
                          <span
                            onClick={() => handleClick(siteDataPrivacyPolicy)}
                            style={{ cursor: "pointer" }}
                          >{`Privacy Policy`}</span>
                        )}
                      </li>
                      {siteDataTermsCondition && (
                        <>
                          &nbsp;&nbsp;|&nbsp;&nbsp;
                          <li>
                            <span
                              onClick={() =>
                                handleClick(siteDataTermsCondition)
                              }
                              style={{ cursor: "pointer" }}
                            >{`Terms & Conditions`}</span>
                          </li>
                        </>
                      )}

                      {siteDataContactUs && (
                        <>
                          &nbsp;&nbsp;|&nbsp;&nbsp;
                          <li>
                            <span
                              onClick={() => handleClick(siteDataContactUs)}
                              style={{ cursor: "pointer" }}
                            >{`Contact Us`}</span>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                  <div className="col-sm-12 text-center">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: siteData.footer_content,
                      }}
                    ></p>
                    <p>
                      Copyright © {new Date().getFullYear()} Acquirely Pty Ltd
                    </p>
                  </div>
                </div>
                <FooterDisclaimer siteData={siteData}/>
              </div>
            </section>
          </div>
        </>
      )}
      {layoutType === "8" && (
        <>
          {renderScript(siteData)}
          {(page === 8 || page === "8") && rendermetatags(siteData)}
          <div
            className={`beta-blog-theme beta-blog_${
              siteData && siteData.primary_color
                ? siteData.primary_color
                : "default-theme"
            }`}
          >
            <Navbar
              className="header"
              collapseOnSelect
              expand="lg"
              bg="ligth"
              variant="light"
            >
              <div className="container">
                <div className="row">
                  {hideLogoLink ? (
                    <img
                      src={siteData.logo}
                      className="img-responsive"
                      alt=""
                    />
                  ) : (
                    <Navbar.Brand
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick({ page_url: "/" })}
                    >
                      <img
                        src={siteData.logo}
                        className="img-responsive"
                        alt=""
                      />
                    </Navbar.Brand>
                  )}
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto" />

                    <Nav>
                      {hideSurveyPage ? (
                        <></>
                      ) : (
                        <Nav.Link
                          onClick={() => handleClick({ page_url: "/" })}
                          className="header-button"
                        >
                          {siteDataValue.section_first_header}
                        </Nav.Link>
                      )}
                    </Nav>
                  </Navbar.Collapse>
                </div>
              </div>
            </Navbar>
            <section className="beta-blog-layout">
              <div className="beta-blogHeader">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-sm-6">
                      <div className="blogHeaderText">
                        <h1>{siteDataValue.section_first_paragraph}</h1>
                        <div className="postDetails">
                          <p className="postedBy">
                            <b>
                              <img src={UserIcon} alt="" />
                            </b>
                            <span> {siteDataValue.list_third_header}</span>
                          </p>
                          <p className="postDate">
                            <b>
                              <img src={CalendarIcon} alt=""/>
                            </b>{" "}
                            {new Date(siteDataValue.updated_at).toDateString()}
                          </p>
                          {(window.location.host ===
                            "abcb.supportwithdebt.com.au" ||
                            window.location.host ===
                              "www.abcb.supportwithdebt.com.au") && (
                            <p>
                              <b>Advertorial</b>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="betaBlogImg">
                        <img src={siteDataValue.section_third_img} alt=""/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="beta-mainContent">
                <div className="container">
                  <div className="row">
                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="mid-content">
                            <div className="mi-section-content">
                              <div className="articletextarea blogTextaria">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      siteDataValue.section_second_paragraph,
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="mi-section-content">
                              <div className="group-editible">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      siteDataValue.section_third_paragraph,
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="mi-section-content">
                              <div className="boollets-points">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      siteDataValue.section_fourth_paragraph,
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className="mi-section-content">
                              <div className="choos-boollets-points">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      siteDataValue.section_fourth_paragraph_left,
                                  }}
                                ></div>
                                <div className="choosearea-section">
                                  {siteDataValue.section_second_header ===
                                    "true" ||
                                    (siteDataValue.section_second_header ===
                                      "True" && (
                                      <span
                                        onClick={() =>
                                          handleClickNewTab(surveyMapUrl)
                                        }
                                        className="svg-holder"
                                        style={{ cursor: "pointer" }}
                                      >
                                        {/* map here */}

                                        {siteData.country === "NZ" ? (
                                          <MapNz
                                            greenGoldMap={greenGoldMap}
                                            goldGreenMap={goldGreenMap}
                                          />
                                        ) : siteData.country === "CA" ? (
                                          <MapCa
                                            greenGoldMap={greenGoldMap}
                                            goldGreenMap={goldGreenMap}
                                          />
                                        ): siteData.country === "USA" ? (
                                          <MapUsa
                                            greenGoldMap={greenGoldMap}
                                            goldGreenMap={goldGreenMap}
                                          />
                                        ): (
                                          <MapAu
                                            greenGoldMap={greenGoldMap}
                                            goldGreenMap={goldGreenMap}
                                          />
                                        )}
                                      </span>
                                    ))}

                                  <div className="map__desc clearfix"></div>
                                  <div className="comparebtn">
                                    <span
                                      onClick={() =>
                                        handleClickNewTab({ page_url: "/" })
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {siteDataValue.list_third_paragraph}
                                    </span>
                                  </div>
                                  <div className="averagetext">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          siteDataValue.section_fourth_paragraph_right,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <ul className="advertisement-images">
                        {renderAdBannerImages()}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="foter-bottom">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-sm-6">
                    <p>
                      Copyright © {new Date().getFullYear()} Acquirely Pty Ltd
                      &nbsp; &nbsp; | &nbsp;&nbsp;
                      <span
                        dangerouslySetInnerHTML={{
                          __html: siteData.footer_content,
                        }}
                      ></span>
                    </p>
                  </div>
                  <div className="col-sm-6">
                    <ul>
                      <li>
                        {siteDataPrivacyPolicy && (
                          <span
                            onClick={() => handleClick(siteDataPrivacyPolicy)}
                            style={{ cursor: "pointer" }}
                          >{`Privacy Policy`}</span>
                        )}
                      </li>
                      {siteDataTermsCondition && (
                        <>
                          <li>
                            <span
                              onClick={() =>
                                handleClick(siteDataTermsCondition)
                              }
                              style={{ cursor: "pointer" }}
                            >{`Terms & Conditions`}</span>
                          </li>
                        </>
                      )}

                      {siteDataContactUs && (
                        <>
                          <li>
                            <span
                              onClick={() => handleClick(siteDataContactUs)}
                              style={{ cursor: "pointer" }}
                            >{`Contact Us`}</span>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
                <FooterDisclaimer siteData={siteData}/> 
              </div>
            </section>
          </div>
        </>
      )}

      {layoutType === "5" && (
        <>
          {renderScript(siteData)}
          {(page === 8 || page === "8") && rendermetatags(siteData)}
          <div className="sixth_layout_global">
            <div
              className={`sixth_blog_${
                siteData && siteData.primary_color
                  ? siteData.primary_color
                  : "default-theme"
              }`}
            >
              <Navbar
                className="header"
                collapseOnSelect
                expand="lg"
                bg="ligth"
                variant="dark"
              >
                <div className="container">
                  <div className="row">
                    {hideLogoLink ? (
                      <img
                        src={siteData.logo}
                        className="img-responsive"
                        alt=""
                      />
                    ) : (
                      <Navbar.Brand
                        style={{ cursor: "pointer" }}
                        onClick={() => handleClick({ page_url: "/" })}
                      >
                        <img
                          src={siteData.logo}
                          className="img-responsive"
                          alt=""
                        />
                      </Navbar.Brand>
                    )}
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                      <Nav className="mr-auto" />

                      <Nav>
                        {hideSurveyPage ? (
                          <></>
                        ) : (
                          <Nav.Link
                            onClick={() => handleClickNewTab(survey_page)}
                            className="header-button"
                          >
                            {siteDataValue.section_first_header}
                          </Nav.Link>
                        )}
                      </Nav>
                    </Navbar.Collapse>
                  </div>
                </div>
              </Navbar>
              {siteData &&
              (siteData.primary_color === "fourth-theme" ||
                siteData.primary_color === "fifth-theme") ? (
                <section className="home-section-top blogSectionTop">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-9">
                        <div className="blogHeader">
                          <div className="blogHeaderText">
                            <h1>{siteDataValue.section_first_paragraph}</h1>
                          </div>

                          <ul>
                            <li>
                              <p>
                                <b>Editor name:</b>
                                {siteDataValue.list_third_header}
                              </p>
                              <p>
                                <b>Date:</b>{" "}
                                {new Date(
                                  siteDataValue.updated_at
                                ).toDateString()}
                              </p>
                            </li>
                          </ul>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="mid-content">
                              <div className="blogImg">
                                <img
                                  src={siteDataValue.section_third_img}
                                  className="img-responsive"
                                  alt=""
                                  onClick={() => handleClick(survey_page)}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                              <div className="mi-section-content">
                                <div className="articletextarea blogTextaria">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        siteDataValue.section_second_paragraph,
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <div className="mi-section-content">
                                <div className="group-editible">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        siteDataValue.section_third_paragraph,
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <div className="mi-section-content">
                                <div className="boollets-points">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        siteDataValue.section_fourth_paragraph,
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <div className="mi-section-content">
                                <div className="choos-boollets-points">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        siteDataValue.section_fourth_paragraph_left,
                                    }}
                                  ></div>
                                  <div className="choosearea-section">
                                    {siteDataValue.section_second_header ===
                                      "true" ||
                                      (siteDataValue.section_second_header ===
                                        "True" && (
                                        <span
                                          onClick={() =>
                                            handleClickNewTab(surveyMapUrl)
                                          }
                                          className="svg-holder"
                                          style={{ cursor: "pointer" }}
                                        >
                                          {/* map here */}
                                          {siteData.country === "NZ" ? (
                                            <MapNz
                                              greenGoldMap={greenGoldMap}
                                              goldGreenMap={goldGreenMap}
                                            />
                                          ) : siteData.country === "CA" ? (
                                            <MapCa
                                              greenGoldMap={greenGoldMap}
                                              goldGreenMap={goldGreenMap}
                                            />
                                          ) : siteData.country === "USA" ? (
                                            <MapUsa
                                              greenGoldMap={greenGoldMap}
                                              goldGreenMap={goldGreenMap}
                                            />
                                          ): (
                                            <MapAu
                                              greenGoldMap={greenGoldMap}
                                              goldGreenMap={goldGreenMap}
                                            />
                                          )}
                                        </span>
                                      ))}

                                    <div className="map__desc clearfix"></div>
                                    <div className="comparebtn">
                                      <span
                                        onClick={() =>
                                          handleClickNewTab(survey_page)
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {siteDataValue.list_third_paragraph}
                                      </span>
                                    </div>
                                    <div className="averagetext">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            siteDataValue.section_fourth_paragraph_right,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <ul className="advertisement-images">
                          {renderAdBannerImages()}
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
              ) : (
                <section className="home-section-top blogSectionTop blogNewDesign">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-9">
                        <div className="blogHeader">
                          <div className="container">
                            <div className="headerContainer">
                              <div className="blogHeaderText">
                                <h1>{siteDataValue.section_first_paragraph}</h1>
                                <ul>
                                  <li>
                                    <p>
                                      <b>By:</b>
                                      <span>
                                        {" "}
                                        {siteDataValue.list_third_header}
                                      </span>
                                    </p>
                                    <p>
                                      {new Date(
                                        siteDataValue.updated_at
                                      ).toDateString()}
                                    </p>
                                    {(window.location.host ===
                                      "abcb.supportwithdebt.com.au" ||
                                      window.location.host ===
                                        "www.abcb.supportwithdebt.com.au") && (
                                      <p>
                                        <b>Advertorial</b>
                                      </p>
                                    )}
                                  </li>
                                </ul>
                              </div>

                              <div className="blogImg">
                                <img
                                  src={siteDataValue.section_third_img}
                                  onClick={() => handleClick(survey_page)}
                                  style={{ cursor: "pointer" }}
                                  alt=""
                                ></img>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-12">
                            <div className="mid-content">
                              <div className="mi-section-content">
                                <div className="articletextarea blogTextaria">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        siteDataValue.section_second_paragraph,
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <div className="mi-section-content">
                                <div className="group-editible">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        siteDataValue.section_third_paragraph,
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <div className="mi-section-content">
                                <div className="boollets-points">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        siteDataValue.section_fourth_paragraph,
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <div className="mi-section-content">
                                <div className="choos-boollets-points">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        siteDataValue.section_fourth_paragraph_left,
                                    }}
                                  ></div>
                                  <div className="choosearea-section">
                                    {siteDataValue.section_second_header ===
                                      "true" ||
                                      (siteDataValue.section_second_header ===
                                        "True" && (
                                        <span
                                          onClick={() =>
                                            handleClickNewTab(surveyMapUrl)
                                          }
                                          className="svg-holder"
                                          style={{ cursor: "pointer" }}
                                        >
                                          {siteData.country === "NZ" ? (
                                            <MapNz
                                              greenGoldMap={greenGoldMap}
                                              goldGreenMap={goldGreenMap}
                                            />
                                          ) : siteData.country === "CA" ? (
                                            <MapCa
                                              greenGoldMap={greenGoldMap}
                                              goldGreenMap={goldGreenMap}
                                            />
                                          ) : siteData.country === "USA" ? (
                                            <MapUsa
                                              greenGoldMap={greenGoldMap}
                                              goldGreenMap={goldGreenMap}
                                            />
                                          ): (
                                            <MapAu
                                              greenGoldMap={greenGoldMap}
                                              goldGreenMap={goldGreenMap}
                                            />
                                          )}
                                        </span>
                                      ))}

                                    <div className="map__desc clearfix"></div>
                                    <div className="comparebtn">
                                      <span
                                        onClick={() =>
                                          handleClickNewTab(survey_page)
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {siteDataValue.list_third_paragraph}
                                      </span>
                                    </div>
                                    <div className="averagetext">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            siteDataValue.section_fourth_paragraph_right,
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <ul className="advertisement-images">
                          {renderAdBannerImages()}
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
              )}
              <section className="foter-bottom">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12 text-center">
                      <p className="copyright_footer">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: siteData.footer_content,
                        }}
                      ></span>
                      <br></br>
                        Copyright © {new Date().getFullYear()}
                        {" Acquirely Pty Ltd"}
                      </p>
                      <ul>
                        <li>
                          {siteDataPrivacyPolicy && (
                            <span
                              onClick={() => handleClick(siteDataPrivacyPolicy)}
                              style={{ cursor: "pointer" }}
                            >{`Privacy Policy`}</span>
                          )}
                        </li>
                        {siteDataTermsCondition && (
                          <>
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            <li>
                              <span
                                onClick={() =>
                                  handleClick(siteDataTermsCondition)
                                }
                                style={{ cursor: "pointer" }}
                              >{`Terms & Conditions`}</span>
                            </li>
                          </>
                        )}

                        {siteDataContactUs && (
                          <>
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            <li>
                              <span
                                onClick={() => handleClick(siteDataContactUs)}
                                style={{ cursor: "pointer" }}
                              >{`Contact Us`}</span>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                  <FooterDisclaimer siteData={siteData}/>
                </div>
              </section>
            </div>
          </div>
        </>
      )}
      {layoutType === "7" && (
        <>
          {renderScript(siteData)}
          {(page === 8 || page === "8") && rendermetatags(siteData)}

          <div className="seventh_layout_global">
            <div
              className={`seventh_blog_${
                siteData && siteData.primary_color
                  ? siteData.primary_color
                  : "default-theme"
              }`}
            >
              <Navbar
                className="header"
                collapseOnSelect
                expand="lg"
                bg="ligth"
                // variant="dark"
              >
                <div className="container">
                  {hideLogoLink ? (
                    <img
                      src={siteData.logo}
                      className="img-responsive"
                      alt=""
                    />
                  ) : (
                    <Navbar.Brand
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick({ page_url: "/" })}
                    >
                      <img
                        src={siteData.logo}
                        className="img-responsive"
                        alt=""
                      />
                    </Navbar.Brand>
                  )}
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto" />

                    <Nav>
                      {hideSurveyPage ? (
                        <></>
                      ) : (
                        <Nav.Link
                          onClick={() => handleClick({ page_url: "/" })}
                          className="header-button"
                        >
                          {siteDataValue.section_first_header}
                        </Nav.Link>
                      )}
                    </Nav>
                  </Navbar.Collapse>
                </div>
              </Navbar>
              <div className="seventhLayoutBlog">
                <div className="container">
                  <div className="seventhLayoutBlogHeader">
                    <h1>{siteDataValue.section_first_paragraph}</h1>
                    <div className="profileHeader">
                      <div className="firstLetter">J</div>
                      <div className="profile">
                        <div className="autherName">
                          {" "}
                          {siteDataValue.list_third_header}
                        </div>
                        <div className="blogDate">
                          {" "}
                          {new Date(siteDataValue.updated_at).toDateString()}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="seventhLayoutFeatureImg">
                    <img src={siteDataValue.section_third_img} alt="" />
                  </div>
                  <div className="blogSeventhContent">
                    <div className="ContentSectionBlog">
                      {/* section two */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: siteDataValue.section_second_paragraph,
                        }}
                      ></div>
                      {/* section three */}
                      <div
                        className="ChooseOptions"
                        dangerouslySetInnerHTML={{
                          __html: siteDataValue.section_third_paragraph,
                        }}
                      ></div>
                      {/* section four */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: siteDataValue.section_fourth_paragraph,
                        }}
                      ></div>
                      {/* section Map */}
                      <div className="mapSectionSeventh">
                        {siteDataValue.section_second_header === "true" ||
                          (siteDataValue.section_second_header === "True" && (
                            <span
                              onClick={() => handleClick(surveyMapUrl)}
                              className="svg-holder"
                              style={{ cursor: "pointer" }}
                            >
                              {/* map here */}
                              {siteData.country === "NZ" ? (
                                <MapNz
                                  greenGoldMap={greenGoldMap}
                                  goldGreenMap={goldGreenMap}
                                />
                              ) : siteData.country === "CA" ? (
                                <MapCa
                                  greenGoldMap={greenGoldMap}
                                  goldGreenMap={goldGreenMap}
                                />
                              ) : siteData.country === "USA" ? (
                                <MapUsa
                                  greenGoldMap={greenGoldMap}
                                  goldGreenMap={goldGreenMap}
                                />
                              ): (
                                <MapAu
                                  greenGoldMap={greenGoldMap}
                                  goldGreenMap={goldGreenMap}
                                />
                              )}
                            </span>
                          ))}

                        <div className="compareNowMap">
                          <span
                            onClick={() => handleClick({ page_url: "/" })}
                            className="compareNowMapBtn"
                          >
                            {siteDataValue.list_third_paragraph}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-footer">
                <section className="copyright">
                  <div className="container">
                    <p>
                      Copyright © {new Date().getFullYear()} |{" "}
                      <span
                        className="smallText"
                        dangerouslySetInnerHTML={{
                          __html: siteData.footer_content,
                        }}
                      ></span>
                    </p>
                  </div>
                </section>
                <section className="footer-content redesignfooter">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="custome-links">
                          <ul>
                            <li>
                              {siteDataPrivacyPolicy && (
                                <span
                                  onClick={() =>
                                    handleClick(siteDataPrivacyPolicy)
                                  }
                                  style={{ cursor: "pointer" }}
                                >{`Privacy Policy`}</span>
                              )}
                            </li>
                            {siteDataTermsCondition && (
                              <>
                                <li>
                                  <span
                                    onClick={() =>
                                      handleClick(siteDataTermsCondition)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >{`Terms & Conditions`}</span>
                                </li>
                              </>
                            )}

                            {siteDataContactUs && (
                              <>
                                <li>
                                  <span
                                    onClick={() =>
                                      handleClick(siteDataContactUs)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >{`Contact Us`}</span>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                   <FooterDisclaimer siteData={siteData}/>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

BlogBody.propTypes = {
  siteData: object,
  layoutType: string,
};

BlogBody.defaultProp = {
  layoutType: "1",
};

export default BlogBody;
