import React from "react";
import queryString from "query-string";
import InputField from "../../views/input-field/input-field";
import constants from "../../constants";
import { button_appearances } from "../../views/button";
import CustomSelectBox from "../../views/dropdown/custom-dropdown";
import ComboBox from "../../views/combo-box";
import RadioBox from "../../views/radio-box/radio-box";
import DropDownAutocomplete from "../../views/drop-down-auto-complete/DropdownAndAutoComplete";
import CheckBox from "../../views/check-box/checkBox";
import DateField from "../../views/date-field";
import FileField from "../../views/file-field/file-field";
import TextArea from "../../views/text-area/index";
import Button from "../../views/button";
import SurveyFormSliderNew from "../../views/survey-form-slider/survey-form-slider-new";
import NumberField from "../../views/number-field";
import { leadData } from "../../actions/manage-site-action-type";
import { connect } from "react-redux";
import axios from "axios";
import profanityWords from "../../assets/profanityWords.json";
import RangeSlider from "../../views/range-slider";
import Card from "../../views/card/card";
import AutoCompletePostSuburb from "../../views/drop-down-auto-complete/autocomplete-post-suburb";
import Utils from "../../utils";
import history from "../../routes/history";
import themecolors from "../../utils/themecolors";
import Otpcard from "../../views/card/otpcard";
import Api from "../../api";
import { comparesolarmarket } from "../../utils/microsites-constants";

class SurveyFormNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      fieldsData: {},
      error: "",
      isValidate: false,
      activeStep: 0,
      isPhoneValid: true,
      backSteps: [],
      isDependantQuestion: false,
      defaultval: "(Keeling) Islands, WA, 6799",
      showOtpPop: false,
    };
  }

  componentDidMount = () => {
    const { data } = this.props;

    data &&
      data.map((item) => {
        if (item.type !== "button" && item.name !== undefined) {
          let returnVal = this.returnValue(item);

          this.setState((prevState, props) => ({
            fields: {
              ...prevState.fields,
              [item.name]: returnVal,
            },
            [`has${item.name}Error`]: returnVal !== "" ? false : true,
          }));
        }
      });
    Utils.getData().then((res) => {
      this.setState({ ipv4: res });
    });

    Utils.renderBingPixel2022SupportWithDebt(`<script>
      window.uetq = window.uetq || [];
      window.uetq.push("event", "submit_lead_form", {});
      </script><script>
      function uet_report_conversion() {
        window.uetq = window.uetq || [];
        window.uetq.push("event", "submit_lead_form", {});
      }
      </script>`);
  };

  returnValue = (item) => {
    var returnValue = "";

    if (item.value) {
      returnValue = item.value;
    } else if (item.values) {
      var valuesArray = [];
      // var temparr = [];
      if (item.type === "select") {
        valuesArray = item.values.filter((item) => {
          return item.selected === true;
        });
        valuesArray =
          valuesArray.length === 0
            ? item.values.filter((item, index) => {
              return index === 0;
            })
            : valuesArray;
      } else if (
        item.type === "autocomplete" &&
        item.className.includes("postcode_suburb")
      ) {
        return returnValue;
      } else {
        valuesArray = item.values.filter((item) => {
          return item.selected === true;
        });
      }

      if (valuesArray.length > 0) {
        valuesArray.map((item) => {
          return (returnValue = returnValue + "," + item.value);
        });
      } else {
        returnValue = "";
      }
    } else {
      returnValue = "";
    }

    returnValue = returnValue.replace(/^,|,$/g, "");
    return returnValue;
  };

  handleFileZoneChange = (field, data, e) => {
    const { target } = e;
    const { fields } = this.state;
    fields[field] = target;
    this.setState({ fields });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let isTrue = true;
    const { fieldsData, fields, backSteps, activeStep } = this.state;
    const { siteData, data, theme_id } = this.props;
    let siteFormData = siteData.dynamic_forms.filter((item) => {
      return item.page_id === 6;
    })[0];

    let siteSurveyContent = Utils.getPageContent(siteData, 6);

    let formData = [];

    this.setState({ isValidate: true });
    // var cont = 0;

    Object.keys(fields).map((key, index) => {
      var fielddata = data.filter((inner_item) => {
        return inner_item.name === key;
      })[0];
      var formValue = fields[key];
      if (
        fielddata &&
        fielddata.type === "checkbox-group" &&
        fielddata.isOtherSelected &&
        fielddata.isOtherText
      ) {
        formValue = formValue + "," + fielddata.isOtherText;
        formValue = formValue.replace(/^,|,$/g, "");
      }
      if (
        fielddata &&
        fielddata.type === "radio-group" &&
        fielddata.isOtherSelected &&
        fielddata.isOtherText
      ) {
        formValue = fielddata.isOtherText;
      }

      // cont++;

      var ifExist =
        backSteps &&
        backSteps.length > 0 &&
        backSteps.filter((item) => {
          return item === index;
        });

      //Only track form fields which are being filled by dependancy questions, other will skip
      if ((ifExist && ifExist.length > 0) || index === activeStep) {
        formData.push({
          label: fielddata.label,
          value: formValue,
          name: fielddata.name,
          subtype:
            fielddata.subtype && fielddata.subtype !== undefined
              ? fielddata.subtype
              : fielddata.type,
        });
      }
    });

    fieldsData.data = JSON.stringify(formData);
    fieldsData.domain = localStorage.getItem("subDomainName"); //window.location.host;
    fieldsData.site_id = siteData.id;
    fieldsData.page_id = "6";
    fieldsData.form_type = "2";
    fieldsData.site_name = siteData.name;
    fieldsData.interested_in = siteFormData && siteFormData.tag_id;
    const thankyou = Utils.getPageContent(siteData, 7);
    fieldsData.thankyou_page_url = thankyou?.page_url || "/";

    let fullName = "";
    let i, len;
    let fName = "";
    let lName = "";

    for (i = 0, len = formData.length; i < len; i++) {
      let tname = formData[i].name ? formData[i].name.toLowerCase() : "";

      if (tname === "name" || tname === "fullname" || tname === "full-name") {
        fullName = formData[i].value;
      }

      if (
        tname === "firstname" ||
        tname === "fname" ||
        tname === "first-name"
      ) {
        fName = formData[i].value;
      }

      if (tname === "lastname" || tname === "lname" || tname === "last-name") {
        lName = formData[i].value;
      }

      if (
        tname === "dob" ||
        tname === "dateofbirth" ||
        tname === "date-of-birth"
      ) {
        let date = formData[i].value;
        if (date instanceof Date && !isNaN(date)) {
          fieldsData["dob"] = date;

          var today = new Date();
          var birthDate = new Date(date);
          var age_now = today.getFullYear() - birthDate.getFullYear();
          var m = today.getMonth() - birthDate.getMonth();
          if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
          }
          fieldsData["age"] = age_now;
        }
      }

      if (tname === "gender" || tname === "sex") {
        fieldsData["gender"] = formData[i].value;
      }

      if (formData[i].subtype === "email") {
        fieldsData["email"] = formData[i].value;
      }

      if (formData[i].subtype === "tel" || tname === "phone-number") {
        fieldsData["phone_number"] = formData[i].value;
      }

      if (
        tname === "town_city" ||
        tname === "towncity" ||
        tname === "town" ||
        tname === "city"
      ) {
        fieldsData["town_city"] = formData[i].value;
      }

      if (
        tname === "post_code" ||
        tname === "postcode" ||
        tname === "zip_code" ||
        tname === "zip"
      ) {
        fieldsData["postcode"] = formData[i].value;
      }

      fieldsData[tname] = formData[i].value;


      if (this.state[`has${formData[i].name}Error`] === true) {
        if (this.state.isDependantQuestion === true) {
          isTrue = true;
        } else {
          isTrue = false;
          break;
        }
      }
    }

    // If the last form field is phone number, validate it before submitting the form by numverify api
    if (
      formData[formData.length - 1].subtype &&
      formData[formData.length - 1].subtype === "tel"
    ) {
      if (!this.state[`has${formData[formData.length - 1].name}Error`]) {
        this.validatePhoneStep(
          this,
          formData[formData.length - 1].value,
          data,
          activeStep
        );
      }
    }
    //End

    if (this.state[`has${formData[formData.length - 1].name}Error`]) {
      isTrue = false;
    }

    if (fullName) {
      fieldsData.full_name = fullName.trim(" ");
    } else {
      fieldsData.full_name = fName.trim(" ") + " " + lName.trim(" ");
    }

    fieldsData.theme_id = theme_id;
    fieldsData.qb_data = "";
    fieldsData.fName = fName.trim(" ");
    fieldsData.lName = lName.trim(" ");
    fieldsData.ipv4 = this.state.ipv4;
    fieldsData.pn = "survey-form-new";
    var isIncludes = false;
    try {
      formData &&
        formData.forEach((item) => {
          profanityWords.list.forEach((word) => {
            if (
              item.value !== undefined &&
              word !== undefined &&
              this.isIncludeProfanityWord(
                word.toLowerCase(),
                item.value.toLowerCase()
              )
            ) {
              isIncludes = true;
            }
          });
        });
    } catch (e) {
      console.log(e);
    }

    if (isTrue) {
      if (!isIncludes) {
        //End Section
        localStorage.setItem("email", fieldsData["email"]);
        siteSurveyContent.section_second_header === "yes"
          ? setTimeout(() => {
            this.setState({ showOtpPop: true });
          }, [500])
          : this.setState({ isLoading: true });
        setTimeout(() => {
          var obj = formData[formData.length - 1];
          var name = `has${obj.name}Error`;
          var lead = Utils.getPageContent(siteData, 1);
          var isLead = lead.send_lead_by_main;
          var subject = lead.email_subject;
          var emailTo = lead.email_to;
          fieldsData.sendLeadByMail = isLead;
          fieldsData.email_subject = subject;
          fieldsData.email_to = emailTo;
          if (!this.state[name]) {
            Utils.callBingPixel2022SupportWithDebt();

            siteSurveyContent.section_second_header !== "yes" &&
              this.handleSuccessCallback(false);
          }
        }, 1000);
      } else {
        localStorage.setItem("email", "");
        window.location.href = '/' + window.location.pathname.split('/')[1] + (thankyou?.page_url || "/thank-you");
      }
    }
  };

  isIncludeProfanityWord = (str, word) => {
    return str === word;
  };

  headerTag = (item, index) => {
    const { activeStep } = this.state;
    return (
      <div
        key={index}
        id={item.name}
        className={activeStep >= 0 ? `step_${activeStep}` : ""}
        style={{ display: "flex" }}
      >
        <item.subtype>
          {item.label}
          {window.location.host === "www.ds.supportwithdebt.com.au" ||
            (window.location.host === "ds.supportwithdebt.com.au" && (
              <>
                <a
                  href="http://tracking.gopsjump.com.au/aff_c?offer_id=6514&aff_id=8181"
                  target="blank"
                >
                  {" Click here"}
                </a>
                {" to find out more"}
              </>
            ))}
        </item.subtype>
      </div>
    );
  };

  buttonTag = (item, index) => {
    return (
      <div
        className="space-between"
        key={index}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div style={{ display: "flex", width: "200px" }}>
          <Button
            type={item.subtype}
            name={item.name}
            appearance={
              item.style
                ? button_appearances[item.style]
                : button_appearances.primary_large
            }
            clickHandler={this.handleSubmit}
            text={item.label}
          />
        </div>
      </div>
    );
  };

  handleChange = (field, data, e) => {
    const { target } = e;
    const { fields } = this.state;
    fields[field] = target.value;
    this.setState({
      field,
      [`has${[e.target.name]}Error`]: false,
      isPhoneValid: true,
    });
  };

  comboChange = (value, data, field) => {
    let { fields, selectedArr } = this.state;
    fields[field] = value.value;
    selectedArr = value;
    this.setState({ fields, [`customddl-${field}`]: selectedArr });
  };

  comboClick = (value, field, displayName) => {
    const { fields } = this.state;
    fields[field] = value !== null ? value[displayName] : "";
    this.setState({ fields });
  };

  handleCustomDropDownClick = (options, value, name) => {
    const { fields } = this.state;
    fields[name] = value;
    this.setState(
      {
        fields,
        [`has${[name]}Error`]: false,
        isPhoneValid: true,
      },
      () => {
        this.setTimeOutForNextClick();
      }
    );
  };

  postCodeAutoComplteOnChange = (value, data, field) => {
    let { fields, selectedArr } = this.state;
    fields[field] = value;
    selectedArr = value;
    this.setState(
      {
        fields,
        [`customddl-${field}`]: selectedArr,
        [`has${[field]}Error`]: false,
      },
      () => {
        this.setTimeOutForNextClick();
      }
    );
  };

  PostZipAutoCompleteTag = (item, index) => {
    const { isValidate, activeStep } = this.state;
    const { siteData } = this.props;
    const selectedArr = this.state[`customddl-${item.name}`];
    var theme = siteData.primary_color;
    var selectedArry = selectedArr === undefined ? "" : selectedArr;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];

    return (
      <>
        <div
          className="space-between"
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <AutoCompletePostSuburb
            type={"survey"}
            options={item.values}
            name1={item.name}
            label={item.label}
            handleChange={(value) =>
              this.postCodeAutoComplteOnChange(value, item, item.name)
            }
            description={item.description}
            validateOptions={{
              check: true,
              required: item.required,
            }}
            allowMultiple={item.multiple}
            validate={isValidate}
            validationCallback={(res) =>
              this.setState({
                [`has${item.name}Error`]: res,
                isValidate: false,
              })
            }
            value={selectedArry}
            requireValidOption={item.requireValidOption}
            theme_id={theme}
            iconName={item.icon}
            placeholder={item.placeholder}
            country={siteData.country}
            themecolors={themecolors.colors(
              theme,
              siteData.theme,
              siteFormData.page_title
            )}
            activestep={activeStep}
          />
        </div>
      </>
    );
  };

  DropDownAutocompleteTag = (item, index) => {
    const { isValidate, activeStep } = this.state;
    const { siteData } = this.props;

    const selectedArr = this.state[`customddl-${item.name}`];
    var theme = siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    var selectedArry =
      selectedArr === undefined
        ? item.values &&
        item.values.filter((inneritem) => {
          return inneritem.selected === true;
        })[0]
        : selectedArr;

    return (
      <div
        className="space-between"
        key={index}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DropDownAutocomplete
          type={"survey"}
          options={item.values}
          name={item.name}
          label={item.label}
          handleChange={(value) => this.comboChange(value, item, item.name)}
          description={item.description}
          validateOptions={{
            check: true,
            required: true,
          }}
          allowMultiple={item.multiple}
          validate={isValidate}
          validationCallback={(res) =>
            this.setState({ [`has${item.name}Error`]: res, isValidate: false })
          }
          value={selectedArry}
          requireValidOption={item.requireValidOption}
          theme_id={theme}
          iconName={item.icon}
          // theme_id={siteData.theme}
          themecolors={themecolors.colors(
            theme,
            siteData.theme,
            siteFormData.page_title
          )}
          activestep={activeStep}
        />
      </div>
    );
  };

  selectTag = (item, index) => {
    const { isValidate, fields, activeStep } = this.state;
    const { siteData } = this.props;
    const dataIcon = siteData.content_manage_sites.filter(
      (item) => item.page_id === 1
    )[0].col_three;

    var theme = siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    return (
      <div
        className="space-between"
        key={index}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CustomSelectBox
          type={"survey"}
          options={item.values}
          name={item.name}
          label={item.label}
          fieldAttributes={{
            onChange: this.handleChange.bind(this, item.name, item),
          }}
          description={item.description}
          validateOptions={{
            check: true,
            required: true,
          }}
          allowMultiple={item.multiple}
          validate={isValidate}
          validationCallback={(res) =>
            this.setState({ [`has${item.name}Error`]: res, isValidate: false })
          }
          value={fields[item.name] || ""}
          onCustomDropDownClick={(value) =>
            this.handleCustomDropDownClick(item.values, value, item.name)
          }
          theme={theme}
          iconName={"icon-money5"}
          layoutTheme={siteData.theme}
          displayicon={dataIcon}
          data={this.state.fields}
          themecolors={themecolors.colors(
            theme,
            siteData.theme,
            siteFormData.page_title
          )}
          activestep={activeStep}
        />
      </div>
    );
  };

  handleChangeRadio = (e, data) => {
    const { fields } = this.state;
    fields[e.target.name] = e.target.value;
    data.isOtherSelected = false;
    data.isOtherText = "";
    var status = e.target.value === "other" ? true : false;
    this.setState({ fields, [`has${[e.target.name]}Error`]: status }, () => {
      this.setTimeOutForNextClick();
    });
  };

  handleChangeRadioOther = (otherTxt, isSelected, data) => {
    data.isOtherSelected = isSelected;
    data.isOtherText = otherTxt;

    const { fields } = this.state;
    fields[data.name] = "other";

    var status = otherTxt === "" ? true : false;
    this.setState({ fields, [`has${[data.name]}Error`]: status });
  };

  radioboxTag = (item, index) => {
    const { isValidate, fields, activeStep } = this.state;
    const { siteData } = this.props;

    var theme = siteData.primary_color;
    const dataIcon = siteData.content_manage_sites.filter(
      (item) => item.page_id === 1
    )[0].col_three;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    return (
      <div
        className="space-between radioBtnsGroup"
        key={index}
        style={{ display: "flex" }}
      >
        <RadioBox
          type={"survey"}
          data={item}
          key={index}
          value={fields[item.name] || ""}
          name={item.name}
          onChangeHandle={(res) => this.handleChangeRadio(res, item)}
          validateOptions={{
            required: item.required,
          }}
          validate={isValidate}
          validationCallback={(res) =>
            this.setState({ [`has${item.name}Error`]: res, isValidate: false })
          }
          onChangeOther={(res, res1) =>
            this.handleChangeRadioOther(res, res1, item)
          }
          iconName={"icon-date"}
          theme={theme}
          templatetheme={siteData.theme}
          displayicon={dataIcon}
          themecolors={themecolors.colors(
            theme,
            siteData.theme,
            siteFormData.page_title
          )}
          activestep={activeStep}
        />
      </div>
    );
  };

  AutocompleteTag = (item, index) => {
    const { fields, isValidate } = this.state;

    return (
      <div className="space-between" key={index}>
        <ComboBox
          type={"survey"}
          clearOnEscape={true}
          classname={item.className}
          name={item.name}
          arrList={item.values}
          label={item.label}
          handleChange={(value) => this.comboChange(value, item.name, item)}
          handelClickEvent={(value) =>
            this.comboClick(value, item.name, "value")
          }
          validateOptions={{
            check: true,
            required: item.required,
          }}
          validate={isValidate}
          validationCallback={(res) =>
            this.setState({ [`has${item.name}Error`]: res, isValidate: false })
          }
          value={fields[item.name] || ""}
          placeholder={item.placeholder}
          requireValidOption={item.requireValidOption}
        />
      </div>
    );
  };

  paragraphTag = (item, index) => (
    <div key={index} className="space-between">
      <item.subtype
        key={index}
        id={`step_${this.state.activeStep}_${item.name}`}
      >
        {item.label}
      </item.subtype>
    </div>
  );

  getCommaSepratedValuesCheckbox = (data) => {
    var selectedValues = "";
    data &&
      data.values &&
      data.values.map((item) => {
        if (item.selected === true) {
          return (selectedValues += "," + item.label);
        }
      });
    return (selectedValues = selectedValues.replace(/^,|,$/g, ""));
  };

  checkboxErrorStatus = (data, selectedValues) => {
    return data.isOtherSelected && data.isOtherText === ""
      ? true
      : data.isOtherSelected && data.isOtherText !== ""
        ? false
        : selectedValues === ""
          ? true
          : false;
  };

  handleChangeCheckbox = (e, data) => {
    const { fields } = this.state;
    var selectedValues = this.getCommaSepratedValuesCheckbox(data);

    fields[data.name] = selectedValues;
    var status = this.checkboxErrorStatus(data, selectedValues);
    this.setState({ fields, [`has${[data.name]}Error`]: status });
  };

  handleChangeCheckboxOther = (otherTxt, isSelected, data) => {
    data.isOtherSelected = isSelected;
    data.isOtherText = otherTxt;
    var selectedValues = this.getCommaSepratedValuesCheckbox(data);
    var status = this.checkboxErrorStatus(data, selectedValues);
    this.setState({ [`has${[data.name]}Error`]: status });
  };

  checkboxTag = (item, index) => {
    const { fields, isValidate, activeStep } = this.state;
    const { siteData } = this.props;
    const dataIcon = siteData.content_manage_sites.filter(
      (item) => item.page_id === 1
    )[0].col_three;
    var theme = siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    return (
      <div key={index} className="space-between chkbox_survey">
        <CheckBox
          type={"survey"}
          label={item.label}
          className={item.className}
          options={item.values || []}
          name={item.name}
          validateOptions={{
            required: item.required,
          }}
          validate={isValidate}
          toggle={item.toggle}
          inline={item.inline}
          other={item.other}
          validationCallback={(res) =>
            this.setState({ [`has${item.name}Error`]: res, isValidate: false })
          }
          id={"isHelpdesk_id"}
          value={fields[item.name] || ""}
          optionText={""}
          isDisabled={false}
          onChange={(res) => this.handleChangeCheckbox(res, item)}
          onChangeOther={(res, res1) =>
            this.handleChangeCheckboxOther(res, res1, item)
          }
          displayName={"title"}
          description={item.description}
          data={item}
          theme={theme}
          templatetheme={siteData.theme}
          iconName={"icon-money4"}
          displayicon={dataIcon}
          themecolors={themecolors.colors(
            theme,
            siteData.theme,
            siteFormData.page_title
          )}
          activestep={activeStep}
        />
      </div>
    );
  };

  handleDateChange = (value, field) => {
    const { fields } = this.state;
    fields[field] = value;
    this.setState({ fields, [`has${[field]}Error`]: false }, () => {
      this.setTimeOutForNextClick();
    });
  };

  setTimeOutForNextClick = () => {
    setTimeout(() => {
      this.handleNextStep();
    }, 200);
  };

  dateTag = (data, index) => {
    var maxdate = new Date(
      new Date().getFullYear() + 100,
      new Date().getMonth(),
      new Date().getDate()
    );
    var mindate = new Date(
      new Date().getFullYear() - 100,
      new Date().getMonth(),
      new Date().getDate()
    );
    const { siteData } = this.props;
    var theme = siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    let isdob =
      data.className.includes("dob") ||
      data.className.includes("DOB") ||
      data.className.includes("Dob");

    if (isdob) {
      maxdate = new Date(
        new Date().getFullYear() + 100,
        new Date().getMonth(),
        new Date().getDate()
      );
      mindate = new Date(
        new Date().getFullYear() - 100,
        new Date().getMonth(),
        new Date().getDate()
      );
    }
    const { fields, isValidate } = this.state;
    return (
      <div
        key={index}
        className="space-between input_date"
        style={{ padding: "0px" }}
      >
        <DateField
          type={"survey"}
          key={index}
          label={data.label}
          description={data.description}
          name={data.name}
          validateOptions={{
            required: data.required,
          }}
          validate={isValidate}
          validationCallback={(res) =>
            this.setState({ [`has${data.name}Error`]: res, isValidate: false })
          }
          fieldAttributes={{
            placeholderText: data.placeholder,
            // onChange: (date) => this.handleDateChange(date, data.name),
            // value: fields[data.name] || "",
          }}
          onDateChange={(date) => this.handleDateChange(date, data.name)}
          value={fields[data.name] || ""}
          maxdate={maxdate}
          mindate={mindate}
          theme={theme}
          iconName={`${data.icon}`}
          themecolors={themecolors.colors(
            theme,
            siteData.theme,
            siteFormData.page_title
          )}
          activestep={this.state.activeStep}
        />
      </div>
    );
  };

  fileTag = (data, index) => {
    const { fields, isValidate, activeStep } = this.state;
    switch (data.subtype) {
      case "file":
        return (
          <div key={index} className="space-between">
            <FileField
              type={"survey"}
              key={index}
              label={data.label}
              placeHolder={data.placeholder}
              name={data.name}
              description={data.description}
              validateOptions={{
                required: data.required,
              }}
              multiple={data.multiple}
              validate={isValidate}
              validationCallback={(res) =>
                this.setState({
                  [`has${data.name}Error`]: res,
                  isValidate: false,
                })
              }
              fieldAttributes={{
                autoComplete: "off",
                placeholder: data.placeholder,
                onChange: this.handleChange.bind(this, data.name, data),
                value: fields[data.name] || "",
              }}
              activestep={activeStep}
            />
          </div>
        );

      default:
        return <></>;
    }
  };

  handleRangeSliderChange = (name, value) => {
    const { fields } = this.state;
    fields[name] = value;
    this.setState({
      name,
      [`has${[name]}Error`]: false,
      isPhoneValid: true,
    });
  };

  numberFieldTag = (data, index) => {
    const { fields, isValidate, activeStep } = this.state;
    const { siteData } = this.props;
    var theme = siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];

    let isRangeSlider =
      data.className.toLowerCase().includes("rangeslider") ||
      data.className.toLowerCase().includes("range-slider");
    var selectedValue = isNaN(fields[data.name])
      ? 0
      : parseInt(fields[data.name]);
    return (
      <div key={index} className="space-between">
        {isRangeSlider ? (
          <>
            <RangeSlider
              type={"survey"}
              key={index}
              index={index}
              label={data.label}
              placeHolder={data.placeholder}
              name={data.name}
              description={data.description}
              className={data.className}
              min={data.min}
              max={data.max}
              step={data.step}
              theme={theme}
              iconName={data.icon}
              onRangeSliderChange={this.handleRangeSliderChange.bind()}
              value={selectedValue}
              themecolors={themecolors.colors(
                theme,
                siteData.theme,
                siteFormData.page_title
              )}
              activestep={activeStep}
            />
          </>
        ) : (
          <NumberField
            type={"survey"}
            key={index}
            label={data.label}
            placeHolder={data.placeholder}
            name={data.name}
            description={data.description}
            // className={data.className}
            min={data.min}
            max={data.max}
            step={data.step}
            theme={theme}
            iconName={data.icon}
            validateOptions={{
              check: true,
              required: data.required,
            }}
            validate={isValidate}
            validationCallback={(res) =>
              this.setState({
                [`has${data.name}Error`]: res,
                isValidate: false,
              })
            }
            fieldAttributes={{
              autoComplete: "off",
              type: "number",
              placeholder: data.placeholder,
              onChange: this.handleChange.bind(this, data.name, data),
              value: fields[data.name] || "",
            }}
            themecolors={themecolors.colors(
              theme,
              siteData.theme,
              siteFormData.page_title
            )}
            activestep={activeStep}
          />
        )}
      </div>
    );
  };

  textTag = (data, index) => {
    const {
      EMAIL_REGEX,
      MOBILE_NUMBER,
      PASSWORD_REGEX,
      MOBILE_NUMBER_GENERIC,
    } = constants;
    const { fields, isValidate, isPhoneValid, activeStep } = this.state;
    const { siteData } = this.props;
    var theme = siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    switch (data.subtype) {
      case "text":
      case "email":
      case "tel":
      case "password":
        return (
          <div key={index} className="space-between" style={{ padding: "0px" }}>
            <InputField
              type={"survey"}
              key={index}
              label={data.label}
              placeHolder={data.placeholder}
              name={data.name}
              description={data.description}
              isPhoneValid={isPhoneValid}
              subtype={data.subtype}
              theme={theme}
              FirstName={this.state.fields.firstName}
              validateOptions={{
                check: true,
                reg:
                  data.subtype === "email"
                    ? EMAIL_REGEX
                    : data.subtype === "tel"
                      ? siteData.country !== "AU"
                        ? MOBILE_NUMBER_GENERIC
                        : MOBILE_NUMBER
                      : data.subtype === "password"
                        ? PASSWORD_REGEX
                        : "",
                regMsg:
                  data.subtype === "email"
                    ? `Please enter a valid email address`
                    : data.subtype === "tel"
                      ? `Please enter a valid ${siteData.country === "NZ"
                        ? "New Zealand"
                        : siteData.country === "CA"
                          ? "Canadian"
                          : "Australian"
                      } phone number`
                      : data.subtype === "password"
                        ? "Password should have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                        : "",
                required: data.required,
              }}
              validate={isValidate}
              validationCallback={(res) =>
                this.setState({
                  [`has${data.name}Error`]: res,
                  isValidate: false,
                })
              }
              fieldAttributes={{
                autoComplete: "off",
                type: data.subtype === "tel" ? "phone" : data.subtype,
                placeholder: data.placeholder,
                onChange: this.handleChange.bind(this, data.name, data),
                value: fields[data.name] || "",
              }}
              iconName={data.icon}
              themecolors={themecolors.colors(
                theme,
                siteData.theme,
                siteFormData.page_title
              )}
              activestep={activeStep}
            />
          </div>
        );

      default:
        return <></>;
    }
  };

  textAreaTag = (data, index) => {
    const { fields, isValidate, activeStep } = this.state;
    const { siteData } = this.props;
    var theme = siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    switch (data.subtype) {
      case "textarea":
        return (
          <div
            key={index}
            className="space-between text_areainput"
            style={{ padding: "0px" }}
          >
            <TextArea
              type={"survey"}
              key={index}
              label={data.label}
              placeHolder={data.placeholder}
              name={data.name}
              validateOptions={{
                required: data.required,
              }}
              className={data.className}
              description={data.description}
              validate={isValidate}
              theme={theme}
              iconName={`${data.icon}`}
              validationCallback={(res) =>
                this.setState({
                  [`has${data.name}Error`]: res,
                  isValidate: false,
                })
              }
              fieldAttributes={{
                autoComplete: "off",
                maxLength: data.maxlength,
                rows: data.rows,
                onChange: this.handleChange.bind(this, data.name, data),
                value: fields[data.name] || "",
              }}
              themecolors={themecolors.colors(
                theme,
                siteData.theme,
                siteFormData.page_title
              )}
              activestep={activeStep}
            />
          </div>
        );

      default:
        return <></>;
    }
  };

  callSwitch = (item) => {
    switch (item.type) {
      case "header":
        return this.headerTag(item, 1);
      case "text":
        return this.textTag(item, 2);
      case "button":
        return this.buttonTag(item, 3);
      case "select":
        return this.selectTag(item, 4);
      case "autocomplete":
        //  return this.AutocompleteTag(item, 5);
        if (item.className.includes("postcode_suburb")) {
          return this.PostZipAutoCompleteTag(item, 5);
        } else {
          return this.DropDownAutocompleteTag(item, 5);
        }
      case "radio-group":
        return this.radioboxTag(item, 6);
      case "paragraph":
        return this.paragraphTag(item, 7);
      case "checkbox-group":
        return this.checkboxTag(item, 8);
      case "date":
        return this.dateTag(item, 9);
      case "file":
        return this.fileTag(item, 10);
      case "textarea":
        return this.textAreaTag(item, 11);
      case "number":
        return this.numberFieldTag(item, 12);
      default:
        return <></>;
    }
  };

  redirectToDoNotDisturb = (fieldName, value) => {

    var host = window.location.host;
    if (
      (host === "www.nz2.quickandeasyhearing.com" ||
        host === "nz2.quickandeasyhearing.com" ||
        host === "www.nz.quickandeasyhearing.com" ||
        host === "nz.quickandeasyhearing.com") &&
      fieldName.toLowerCase() === "postcode"
    ) {
      var postcodeArray = value.split(",");
      var postcode =
        postcodeArray && postcodeArray.length > 1 && postcodeArray[2].trim();
      if (
        (parseInt(postcode) >= 4000 && parseInt(postcode) <= 4999) ||
        (parseInt(postcode) >= 9000 && parseInt(postcode) <= 9999)
      ) {
        Utils.historyPush("/do-not-qualify");
      }
    }

    if (
      ["www.2022.myenergycompare.com.au",
        "2022.myenergycompare.com.au",
        "www.gs.myenergycompare.com.au",
        "gs.myenergycompare.com.au",
        "www.myenergycompare.com.au",
        "myenergycompare.com.au",
        "www.aussiesgivingback.com.au",
        "aussiesgivingback.com.au"].includes(host) &&
      (fieldName.toLowerCase() === "postcode" ||
        fieldName.toLowerCase() === "zip")
    ) {
      var postcodeArray = value.split(",");
      var postcode =
        postcodeArray && postcodeArray.length > 1 && postcodeArray[2].trim();
      var state =
        postcodeArray && postcodeArray.length > 1 && postcodeArray[1].trim();
      if (!["www.aussiesgivingback.com.au",
        "aussiesgivingback.com.au"].includes(host) &&
        (parseInt(postcode) < 2000 ||
          parseInt(postcode) > 4000 ||
          (state.toLowerCase() !== "nsw" && state.toLowerCase() !== "vic"))
      ) {
        Utils.historyPush("/do-not-qualify");
      }

      if (["www.aussiesgivingback.com.au",
        "aussiesgivingback.com.au"].includes(host) &&
        (["vic", "wa", "nt"].includes(state.toLowerCase()))
      ) {
        Utils.historyPush("/do-not-qualify");
      }
    }

    if (
      (host === "www.businessfundstoday.net" ||
        host === "businessfundstoday.net" ||
        host === "biz.businessfundstoday.net" ||
        host === "www.biz.businessfundstoday.net" ||
        host === "v2.businessfundstoday.net" ||
        host === "www.v2.businessfundstoday.net" ||
        host === "businessloan.financesolver.co" ||
        host === "www.businessloan.financesolver.co" ||
        host === "native.financesolver.co" ||
        host === "www.native.financesolver.co") &&
      ((fieldName.toLowerCase() === "est_time_trading" &&
        parseInt(value) < "12") ||
        (fieldName.toLowerCase() === "abn" && value.toLowerCase() === "no"))
    ) {
      Utils.historyPush("/do-not-qualify");
    }
    if (
      (host === "www.cbhs.myhealthcompare.com.au" ||
        host === "cbhs.myhealthcompare.com.au") &&
      ((fieldName.toLowerCase() === "eligibilty" && value === "none") ||
        (fieldName.toLowerCase() === "ausresident" &&
          value.toLowerCase() === "no"))
    ) {
      Utils.historyPush("/do-not-qualify");
    }

    if (
      (host === "www.edm.ozhouseprice.com.au" ||
        host === "edm.ozhouseprice.com.au") &&
      fieldName.toLowerCase() === "lookingto" &&
      (value.toLowerCase() ===
        "find the value of a property i’m interested in purchasing" ||
        value.toLowerCase() === "none of the above")
    ) {
      Utils.historyPush("/do-not-qualify");
    }

    if (
      (host === "v3.ozhouseprice.com.au" ||
        host === "www.v3.ozhouseprice.com.au") &&
      fieldName.toLowerCase() === "homeowner" &&
      value.toLowerCase() === "no"
    ) {
      Utils.historyPush("/do-not-qualify");
    }

    if (
      (host === "comparesuper.mysuperannuationcompare.com.au" ||
        host === "www.comparesuper.mysuperannuationcompare.com.au" ||
        host === "www.superannuation.financesolver.co" ||
        host === "superannuation.financesolver.co") &&
      fieldName.toLowerCase() === "selectedage" &&
      (value === "60-69" || value === "70-79" || value === "80-90")
    ) {
      Utils.historyPush("/do-not-qualify");
    }

    if (
      (host === "www.financesolver.co" || host === "financesolver.co") &&
      ((fieldName.toLowerCase() === "selectedage" &&
        (value === "20-29" || value === "70-79" || value === "80-90")) ||
        (fieldName.toLowerCase() === "super_balance" &&
          value === "$0 - $50,000"))
    ) {
      Utils.historyPush("/do-not-qualify");
    }

    if (
      (host === "www.1stenergy.myenergycompare.com.au" ||
        host === "1stenergy.myenergycompare.com.au") &&
      fieldName.toLowerCase() === "state" &&
      (value.toLowerCase() === "sa" ||
        value.toLowerCase() === "qld" ||
        value.toLowerCase() === "nsw")
    ) {
      Utils.historyPush("/do-not-qualify");
    }

    if (
      (host === "www.momentum.mylifeinsurancecompare.co.nz" ||
        host === "momentum.mylifeinsurancecompare.co.nz") &&
      ((fieldName.toLowerCase() === "nzresident" &&
        value.toLowerCase() === "no") ||
        (fieldName.toLowerCase() === "birthyear" &&
          value.toLowerCase() === "1983 or later"))
    ) {
      Utils.historyPush("/do-not-qualify");
    }

    if (
      host === "www.mypetinsurancecompare.co" ||
      host === "mypetinsurancecompare.co"
    ) {
      if (
        (value.toLowerCase().trim() == "none of these" ||
          value.toLowerCase().trim() == "noneofthese") &&
        fieldName.toLowerCase() === "petnsurtype"
      ) {
        Utils.historyPush("/do-not-qualify");
      }

      var petType = this.state?.fields["petnsurtype"]?.toLowerCase()?.trim();
      var petValue = parseInt(value.trim());
      if (
        ((petType == "cat" || petType == "dog") &&
          fieldName.toLowerCase() === "petnsurage" &&
          petValue >= 10) ||
        (petType == "horse" &&
          fieldName.toLowerCase() === "petnsurage" &&
          petValue >= 11) ||
        (petType == "rabbit" &&
          fieldName.toLowerCase() === "petnsurage" &&
          petValue >= 3)
      ) {
        Utils.historyPush("/do-not-qualify");
      }
    }

    if (
      host === "www.mycarinsurancecompare.co" ||
      host === "mycarinsurancecompare.co"
    ) {
      if (
        value.toLowerCase().trim() == "200000" &&
        fieldName.toLowerCase() === "kmtravel"
      ) {
        Utils.historyPush("/do-not-qualify");
      }

      if (
        (value.toLowerCase().trim() == "2006orbefore" ||
          value.toLowerCase().trim() == "2007") &&
        fieldName.toLowerCase() === "manufactureyear"
      ) {
        Utils.historyPush("/do-not-qualify");
      }
    }
    if (["aus.mysolarcompare.com.au",
      "www.aus.mysolarcompare.com.au",
      "localsolarquotes.mysolarcompare.com.au",
      "www.localsolarquotes.mysolarcompare.com.au"].includes(host) &&
      fieldName.toLowerCase() === "home_owner" &&
      value.toLowerCase() === "renter"
    ) {
      Utils.historyPush("/do-not-qualify");
    }

    if (fieldName.toLowerCase() === "broadband_provider" && value.toLowerCase() === "vodafone" &&
      ["www.mybroadbandcompare.co.nz", "mybroadbandcompare.co.nz", "localhost:3000"].includes(window.location.host)) {
      Utils.historyPush('/do-not-qualify');
    }
    if (fieldName.toLowerCase() === "qualifyhotwater" && value.toLowerCase() === "gas/solar/heat pump" &&
      ["www.energyefficiency.financesolver.co", "energyefficiency.financesolver.co"].includes(window.location.host)) {
      Utils.historyPush('/do-not-qualify');
    }
  };

  handleNextStep = () => {
    const { data } = this.props;
    const { activeStep } = this.state;
    if (activeStep === data.length - 1) return false;

    this.setState({ isValidate: true });
    let fieldName = data[activeStep].name;
    let fieldValue = this.state.fields[fieldName];
    this.redirectToDoNotDisturb(fieldName, fieldValue);

    var phoneData = data.filter((item) => {
      return item.subtype === "tel";
    });
    var phoneName = phoneData && phoneData.length > 0 && phoneData[0].name;
    if (fieldName === phoneName) {
      var phoneValue = this.state.fields[phoneName];

      if (phoneValue !== "")
        this.validatePhoneStep(this, phoneValue, data, activeStep);
    } else {
      let hasError = this.state[`has${data[activeStep].name}Error`];
      if (!hasError) {
        //dependancy questions logic start here
        var totalQLength = data && data.length;
        let fieldType = data[activeStep].type;
        if (fieldType === "radio-group" || fieldType === "select") {
          var currentFieldData = data[activeStep];

          var next_stpe_value =
            currentFieldData.values.filter((item) => {
              return item.value === fieldValue;
            }).length > 0
              ? currentFieldData.values.filter((item) => {
                return item.value === fieldValue;
              })[0].next_question
              : "0";
          next_stpe_value =
            next_stpe_value !== undefined &&
              next_stpe_value !== null &&
              next_stpe_value !== "" &&
              next_stpe_value !== "0"
              ? parseInt(next_stpe_value) > totalQLength
                ? activeStep + 1
                : parseInt(next_stpe_value) - 1
              : activeStep + 1;

          next_stpe_value =
            next_stpe_value <= activeStep ? activeStep + 1 : next_stpe_value;
          this.state.backSteps[this.state.backSteps.length] = activeStep;
          this.setState({
            activeStep: next_stpe_value,
            isValidate: false,
            isDependantQuestion: true,
          });

          this.trackSteps(next_stpe_value, fieldName, fieldValue);
        } else {
          this.state.backSteps[this.state.backSteps.length] = activeStep;
          this.setState({
            activeStep: activeStep + 1,
            isValidate: false,
          });

          this.trackSteps(activeStep + 1, fieldName, fieldValue);
        }
        //dependancy questions logic end here
      }
    }
  };
  trackSteps(step, text, value) {
    const sessionId = localStorage.getItem("sessionId");
    if (sessionId && step >= 0) {
      Api.trackMicrositeSteps(sessionId, step, text, value, "");
    }
  }

  validatePhoneStep(thiss, phoneValue, data, activeStep) {
    try {
      const { siteData } = thiss.props;
      phoneValue = phoneValue
        .replace("+61", "0")
        .replace("+64", "0")
        .replace("+1", "");
      var baseurl = "";
      if (process.env.WDS_SOCKET_PATH === "release") {
        baseurl = "https://helathinsurancefind.com.au/api";
      } else {
        baseurl = "http://localhost:8034/api";
      }
      var config = {
        method: "get",
        url: `${baseurl}/neutrinoapiV3/${phoneValue}/${siteData.country}/${siteData.id}`,
      };
      axios(config)
        .then(function (response) {
          var isvalid =
            response && response?.data && response?.data?.message === "true";
          if (!isvalid) {
            thiss.setState({
              isPhoneValid: false,
              isValidate: true,
              [`has${[data[activeStep].name]}Error`]: true,
              isLoading: false,
            });
          } else {
            let hasError = thiss.state[`has${data[activeStep].name}Error`];
            if (!hasError) {
              thiss.state.backSteps[thiss.state.backSteps.length] = activeStep;
              var acst =
                data.length - 1 === activeStep ? activeStep : activeStep + 1;

              thiss.setState({
                activeStep: acst,
                isValidate: false,
                isPhoneValid: true,
                [`has${[data[activeStep].name]}Error`]: false,
              });

              const sessionId = localStorage.getItem("sessionId");
              if (sessionId && acst >= 0) {
                Api.trackMicrositeSteps(
                  sessionId,
                  acst,
                  "phone",
                  phoneValue,
                  ""
                );
              }
            }
          }
        })
        .catch(function (error) {
          console.log("error Numverify Validation ===", error);
        });
    } catch (ex) {
      console.log("Numverify Validation exception", ex);
    }
  }

  handleBackStep = () => {
    var presentInx = this.state.backSteps[this.state.backSteps.length - 1];
    this.state.backSteps.splice(this.state.backSteps.length - 1, 1);
    this.setState({
      activeStep: presentInx,
      isValidate: false,
    });
  };

  handleSuccessCallback = (showProgress, otp) => {
    const { leadData } = this.props;
    const { fieldsData } = this.state;
    this.setState({ showOtpPop: false });
    showProgress && this.setState({ isLoading: true });
    if (
      fieldsData.domain === "biz.businessfundstoday.net" ||
      fieldsData.domain === "www.biz.businessfundstoday.net" ||
      fieldsData.domain === "v2.businessfundstoday.net" ||
      fieldsData.domain === "www.v2.businessfundstoday.net" ||
      fieldsData.domain === "businessloan.financesolver.co" ||
      fieldsData.domain === "www.businessloan.financesolver.co" ||
      fieldsData.domain === "native.financesolver.co" ||
      fieldsData.domain === "www.native.financesolver.co" ||
      fieldsData.domain === "bizau.businessfundstoday.net" ||
      fieldsData.domain === "www.bizau.businessfundstoday.net"
    ) {
      fieldsData.lead_description = `${otp}`;
    }
    leadData(fieldsData);
  };

  render() {
    const { data, heading, siteData, isFromHome } = this.props;
    const { activeStep, isLoading, showOtpPop, fieldsData } = this.state;

    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];

    return (
      <>
        {showOtpPop && (
          <Otpcard
            phoneval={fieldsData["phone_number"]}
            country={siteData.country}
            callback={this.handleSuccessCallback}
          />
        )}
        {isLoading && <Card />}
        <SurveyFormSliderNew
          heading={heading}
          data={data}
          activeStep={activeStep}
          handleNextStep={() => this.handleNextStep.bind(this)}
          handleBackStep={() => this.handleBackStep.bind(this)}
          callSwitch={this.callSwitch}
          value={this.state.fields}
          handleSubmit={this.handleSubmit}
          layoutTheme={this.props.theme_id}
          surveyLayout={siteFormData.page_title}
          isFromHome={isFromHome}
          showFinish={data.length - 1 === activeStep}
          siteFormData={siteFormData}
          isLoading={isLoading}
        />
      </>
    );
  }
}
const mapStateToProps = ({
  ManageSite: { isLoading, getSiteDataObject, getSiteStatus, getSiteError },
}) => ({ isLoading, getSiteDataObject, getSiteStatus, getSiteError });
export default connect(mapStateToProps, { leadData })(SurveyFormNew);

SurveyFormNew.defaultProp = {
  theme_id: 0,
  QBData: {},
  isFromHome: false,
};
