import React from "react";
import Firstsection from "./section_first";
import Secondsection from "./section_second";
import Thirdsection from "./section_third";
import Fourthsection from "./section_fourth";

const LayoutBody = (props) => {
  const { siteData } = props;
  let siteDataValue = siteData.content_manage_sites.filter(filterByID);

  if (siteDataValue.length === 0) {
    window.location.href ='/' + window.location.pathname.split('/')[1] + "/page-not-found3";
  }

  function filterByID(item) {
    if (item.page_id === 1 || item.page_id === "1") {
      return item;
    }
  }

  return (
    <>
      <Firstsection siteData={siteDataValue[0]} mainData={siteData} />
      <Secondsection siteData={siteDataValue[0]} mainData={siteData} />
      <Thirdsection siteData={siteDataValue[0]} mainData={siteData} />
      <Fourthsection siteData={siteDataValue[0]} mainData={siteData} />
    </>
  );
};

export default LayoutBody;
