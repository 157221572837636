import React, { Suspense, Component } from "react";
import { Provider } from "react-redux";
import Routes from "./routes/index";
import store from "./store";
import "./assets/styles/index.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class App extends Component {
  render() {
    return (
      <Suspense>
        <Provider store={store()}>
          <Routes />
          <ToastContainer />
        </Provider>
      </Suspense>
    );
  }
}
export default App;
