import React, { useState, useEffect } from "react";
import SurveyFormNew from "../survey-form/survey-form-new";
// import BlogSurveyForm from "../survey-form/blog-survery-form";
import DynamicForm from "../dynamic-form";
import { string, object } from "prop-types";
import { Helmet } from "react-helmet";
import FifthLayoutSurvey from "../survey-form/fifth-layout-survey";
import Utils from "../../utils/index";
import trustpilot from "../../assets/images/review.png";
import timeImg from "../../assets/images/time.svg";
import reviewImageWithoutGoogleText from "../../assets/images/reviews.png";
// import { makeStyles } from "@material-ui/core/styles";
import "animate.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const SurveyFormBodyNew = (props) => {
  const { siteData, layoutType } = props;
  const [formstate, setformstate] = useState(false);
  const matches = useMediaQuery("(max-width:600px)");

  let siteDataValue = siteData.content_manage_sites.filter(filterByIDData)[0];

  let siteDataSurveyForm = siteData.content_manage_sites.filter((item) => {
    if (item.page_id === 6) {
      return item;
    }
  })[0];
  var surveyLayout = siteDataSurveyForm && siteDataSurveyForm.page_title;
  let siteFormData = siteData.dynamic_forms.filter(filterByID)[0];
  var content = Utils.getPageContent(siteData, 6);
  var googleReviewImage = Utils.googleReviewImageWithoutGoogleText()
    ? reviewImageWithoutGoogleText
    : trustpilot;

  if (siteDataValue.length === 0) {
    window.location.href = '/' + window.location.pathname.split('/')[1] +"/page-not-found9";
  }

  function filterByIDData(item) {
    if (item.page_id === 1) {
      return item;
    }
  }
  const [DisplayDiv, SetDisplayDiv] = useState(false);
  const [hideReviewDiv, SetHideReviewDiv] = useState(false);

  const hideDiv = () => {
    SetDisplayDiv(true);
  };
  const hideReview = () => {
    SetHideReviewDiv(true);
  };
  function filterByID(item) {
    if (item.page_id === 6) {
      return item;
    }
  }
  const renderMetaTags = (siteData, isStaticContent, siteDataSurveyForm) => {
    return (
      <Helmet>
        <link rel="icon" href={siteData.favicon} sizes="16x16" />
        <title>
          {isStaticContent
            ? siteDataSurveyForm && siteDataSurveyForm.meta_title
            : "Survey Page"}
        </title>
        <meta
          name="description"
          content={siteDataSurveyForm && siteDataSurveyForm.meta_description}
        />
        <link rel="icon" href={siteData.favicon} sizes="16x16" />
      </Helmet>
    );
  };

  const isStaticContent =
    siteDataSurveyForm && siteDataSurveyForm.page_sub_title === "yes";

  return (
    <>
      {layoutType === "1" && (
        <>
          {renderMetaTags(siteData, isStaticContent, siteDataSurveyForm)}
          {/* Survey First Layout Starts */}
          {surveyLayout === "firstlayout" && (
            <div className="newSurveyForm">
              <div
                className="surveyContent"
                style={{
                  backgroundImage: `url(${siteDataSurveyForm && siteDataSurveyForm.section_third_img
                    })`,
                }}
              >
                <div
                  className={`${isStaticContent && "surveyPageText"} text-left`}
                >
                  <div className="newSurveyPageContainer">
                    {siteFormData &&
                      siteFormData.data &&
                      siteFormData.data.data ? (
                      <SurveyFormNew
                        theme_id={"1"}
                        heading={
                          siteFormData && siteFormData.form_heading
                            ? siteFormData.form_heading
                            : ""
                        }
                        siteData={siteData}
                        className="modal-content"
                        data={
                          siteFormData &&
                            siteFormData.data &&
                            siteFormData.data.data
                            ? siteFormData.data.data
                            : ""
                        }
                      />
                    ) : (
                      <div className="noSurvey">No survey found</div>
                    )}
                  </div>
                  {/* //Survey page content */}
                  {isStaticContent && (
                    <div className="surveyTextFields">
                      <div className="whySwitch">
                        <h2
                          dangerouslySetInnerHTML={{
                            __html:
                              siteDataSurveyForm && siteDataSurveyForm.heading,
                          }}
                        ></h2>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              siteDataSurveyForm && siteDataSurveyForm.content,
                          }}
                        ></div>
                      </div>
                      <div className="ourCustomers">
                        {siteDataSurveyForm &&
                          siteDataSurveyForm.col_one !== "no" && (
                            <>
                              <h2>{siteDataSurveyForm.section_heading}</h2>
                              <blockquote>
                                <div className="customerdetails">
                                  <div className="customerImg">
                                    <img
                                      src={
                                        siteDataSurveyForm &&
                                        siteDataSurveyForm.section_first_img
                                      }
                                      alt={
                                        siteDataSurveyForm &&
                                        siteDataSurveyForm.sfi_alt_text
                                      }
                                    />
                                  </div>
                                  <div className="customerName">
                                    {siteDataSurveyForm &&
                                      siteDataSurveyForm.sub_heading}
                                  </div>
                                </div>
                                <p>
                                  {siteDataSurveyForm &&
                                    siteDataSurveyForm.section_sub_heading}
                                </p>
                              </blockquote>
                            </>
                          )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* Survey First Layout Ends*/}

          {/* Survey Second Layout Starts*/}
          {surveyLayout === "secondlayout" && (
            <div className="newSurveyForm">
              <div className="surveyPagelayout">
                <div
                  className="surveyPagelayout-left"
                  style={{
                    backgroundImage: `url(${siteDataSurveyForm && siteDataSurveyForm.section_third_img
                      })`,
                  }}
                >
                  {siteFormData &&
                    siteFormData.data &&
                    siteFormData.data.data ? (
                    <SurveyFormNew
                      theme_id={"1"}
                      heading={
                        siteFormData && siteFormData.form_heading
                          ? siteFormData.form_heading
                          : ""
                      }
                      siteData={siteData}
                      className="modal-content"
                      data={
                        siteFormData &&
                          siteFormData.data &&
                          siteFormData.data.data
                          ? siteFormData.data.data
                          : ""
                      }
                    />
                  ) : (
                    <div className="noSurvey">No survey found</div>
                  )}
                </div>
                {isStaticContent && (
                  <div className="surveyPagelayout-right">
                    {isStaticContent && (
                      <div className="surveyTextFields">
                        <div className="whySwitch">
                          <h2
                            dangerouslySetInnerHTML={{
                              __html:
                                siteDataSurveyForm &&
                                siteDataSurveyForm.heading,
                            }}
                          ></h2>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                siteDataSurveyForm &&
                                siteDataSurveyForm.content,
                            }}
                          ></div>
                        </div>
                        {siteDataSurveyForm?.section_sub_heading && (
                          <div className="ourCustomers">
                            {siteDataSurveyForm &&
                              siteDataSurveyForm.col_one !== "no" && (
                                <>
                                  <h2>{siteDataSurveyForm.section_heading}</h2>
                                  <blockquote>
                                    <p>
                                      {siteDataSurveyForm &&
                                        siteDataSurveyForm.section_sub_heading}
                                    </p>
                                    <div className="customerdetails">
                                      <div className="customerImg">
                                        <img
                                          src={
                                            siteDataSurveyForm &&
                                            siteDataSurveyForm.section_first_img
                                          }
                                          alt={
                                            siteDataSurveyForm &&
                                            siteDataSurveyForm.sfi_alt_text
                                          }
                                        />
                                      </div>
                                      <div className="customerName">
                                        {siteDataSurveyForm &&
                                          siteDataSurveyForm.sub_heading}
                                      </div>
                                    </div>
                                  </blockquote>
                                </>
                              )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {/* Survey Second Layout Ends*/}

          {/* Survey Third Layout Starts */}
          {surveyLayout === "thirdlayout" && (
            <div className="newSurveyForm-layoutNew">
              <div
                className="surveyContent"
                style={{
                  backgroundImage: `url(${siteDataSurveyForm && siteDataSurveyForm.section_third_img
                    })`,
                }}
              >
                <div className="container">
                  <div
                    className={`${isStaticContent && "surveyPageContentArea"
                      } text-left`}
                  >
                    {/* //Survey page content */}
                    {isStaticContent && (
                      <div className="surveyTextFields">
                        <div className="whySwitch">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                siteDataSurveyForm &&
                                siteDataSurveyForm.content,
                            }}
                          ></div>
                        </div>
                      </div>
                    )}
                    <div className="newSurveyPageContainer">
                      {siteFormData &&
                        siteFormData.data &&
                        siteFormData.data.data ? (
                        <>
                          <h2>
                            {siteFormData && siteFormData.form_heading
                              ? siteFormData.form_heading
                              : ""}
                          </h2>
                          <DynamicForm
                            data={siteFormData.data.data}
                            siteData={siteData}
                            page_id={6}
                            theme_id={1}
                            heading={
                              siteFormData && siteFormData.form_heading
                                ? siteFormData.form_heading
                                : ""
                            }
                            surveyLayout={"thirdlayout"}
                            formtype={"survey"}
                          />
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                siteDataSurveyForm &&
                                siteDataSurveyForm.section_first_paragraph,
                            }}
                          ></p>
                        </>
                      ) : (
                        <div className="noSurvey">No survey found</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Survey Third Layout Ends*/}

          {/* Survey Fourth Layout Starts */}
          {surveyLayout === "fourthlayout" && (
            <div className="newSurveyForm-layoutNewFourth">
              <div className="fourthLayoutContent">
                {/* <div className="surveyFeatureImage">
                  <img
                              alt={siteDataValue.sfi_alt_text}
                              src={siteDataSurveyForm && siteDataSurveyForm.section_third_img}
                              className="img-fluid"
                              title={siteDataValue.sfi_alt_text}
                            />
                  </div> */}
                <div className="container">
                  <div className="surveyContent">
                    <div className="surveyPartContent">
                      <div className="surveyFeatureImage">
                        <img
                          alt={siteDataValue.sfi_alt_text}
                          src={
                            siteDataSurveyForm &&
                            siteDataSurveyForm.section_third_img
                          }
                          className="img-fluid"
                          title={siteDataValue.sfi_alt_text}
                        />
                      </div>
                      <div
                        className={`${isStaticContent && "surveyPageContentArea"
                          } text-left`}
                      >
                        {/* //Survey page content */}
                        {isStaticContent && (
                          <div className="surveyTextFields">
                            <p
                              dangerouslySetInnerHTML={{
                                __html:
                                  siteDataSurveyForm &&
                                  siteDataSurveyForm.section_first_paragraph,
                              }}
                            ></p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="surveyPartform">
                      <div className="newSurveyPageContainer">
                        {siteFormData &&
                          siteFormData.data &&
                          siteFormData.data.data ? (
                          <>
                            <h2>
                              {siteFormData && siteFormData.form_heading
                                ? siteFormData.form_heading
                                : ""}
                            </h2>
                            <div className="whySwitch">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    siteDataSurveyForm &&
                                    siteDataSurveyForm.content,
                                }}
                              ></div>
                            </div>
                            <DynamicForm
                              data={siteFormData.data.data}
                              siteData={siteData}
                              page_id={6}
                              theme_id={1}
                              heading={
                                siteFormData && siteFormData.form_heading
                                  ? siteFormData.form_heading
                                  : ""
                              }
                              surveyLayout={"thirdlayout"}
                              formtype={"survey"}
                            />
                          </>
                        ) : (
                          <div className="noSurvey">No survey found</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Survey Fifth Layout starts*/}
          {surveyLayout === "fifthlayout" && (
            <div className="newSurveyForm">
              <div
                className="surveyContent newLayoutSurveyContent"
                style={{
                  backgroundImage: `url(${siteDataSurveyForm && siteDataSurveyForm.section_third_img
                    })`,
                }}
              >
                <div
                  className={`${isStaticContent && "surveyPageText"
                    } text-left newLayoutSurvey`}
                >
                  <div
                    className="surveyHeading"
                    style={{ display: DisplayDiv ? "none" : "block" }}
                  >
                    <div className="container" id="surveyFirstContent">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: content.first_survey_content,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div
                    className={`newSurveyPageContainer ${DisplayDiv ? "SurveyContentClass" : ""
                      }`}
                  >
                    <div className="newSurveyFormBox">
                      {siteFormData &&
                        siteFormData.data &&
                        siteFormData.data.data ? (
                        <FifthLayoutSurvey
                          theme_id={"1"}
                          onClick={hideDiv.bind(this)}
                          hideReview={hideReview.bind(this)}
                          heading={
                            siteFormData && siteFormData.form_heading
                              ? siteFormData.form_heading
                              : ""
                          }
                          siteData={siteData}
                          className="modal-content"
                          data={
                            siteFormData &&
                              siteFormData.data &&
                              siteFormData.data.data
                              ? siteFormData.data.data
                              : ""
                          }
                        />
                      ) : (
                        <div className="noSurvey">No survey found</div>
                      )}
                    </div>
                  </div>
                  {/* //Survey page content */}
                  {isStaticContent && (
                    <div className="surveyTextFields">
                      <div
                        className="whySwitch"
                        style={{ display: DisplayDiv ? "none" : "block" }}
                      >
                        <div className="container">
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                siteDataSurveyForm &&
                                siteDataSurveyForm.content,
                            }}
                          ></div>
                        </div>
                      </div>
                      <hr
                        className="horizontalLine"
                        style={{ display: DisplayDiv ? "none" : "block" }}
                      ></hr>

                      <div
                        style={{ display: hideReviewDiv ? "none" : "block" }}
                        className={`ourCustomers`}
                      >
                        <div className="container">
                          {siteDataSurveyForm &&
                            siteDataSurveyForm.col_one !== "no" && (
                              <>
                                <h2>
                                  <span className="trustpilot">
                                    <img
                                      src={googleReviewImage}
                                      alt="Google Review"
                                    />
                                  </span>
                                  {siteDataSurveyForm.heading}
                                </h2>

                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: content.col_four,
                                  }}
                                ></div>
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* Survey Fifth Layout Ends*/}
        </>
      )}

      {layoutType === "4" && (
        <>
          {surveyLayout === "firstlayout" && (
            <>
              <secton className="bgbluew"></secton>
              <div
                className="surveyForm"
                style={{
                  backgroundImage: `url(${siteDataSurveyForm && siteDataSurveyForm.section_third_img
                    })`,
                }}
              >
                <div className="form-survey-section">
                  <Helmet>
                    <title>{"Survey Page"}</title>
                    <link rel="icon" href={siteData.favicon} sizes="16x16" />
                  </Helmet>

                  <div className="newSurveyForm">
                    <div className="surveyContent">
                      <div className="surveyPageText">
                        <div className="newSurveyPageContainer">
                          {siteFormData &&
                            siteFormData.data &&
                            siteFormData.data.data ? (
                            <SurveyFormNew
                              theme_id={"4"}
                              heading={
                                siteFormData.form_heading
                                  ? siteFormData.form_heading
                                  : ""
                              }
                              siteData={siteData}
                              className="modal-content"
                              data={
                                siteFormData &&
                                  siteFormData.data &&
                                  siteFormData.data.data
                                  ? siteFormData.data.data
                                  : this.state.data
                              }
                            />
                          ) : (
                            <div className="noSurvey">No survey found</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {surveyLayout === "secondlayout" && (
            <>
              <div className="surveyForm">
                <Helmet>
                  <title>{"Survey Page"}</title>
                  <link rel="icon" href={siteData.favicon} sizes="16x16" />
                </Helmet>

                <div
                  className="newSurveyForm"
                  style={{
                    backgroundImage: `url(${siteDataSurveyForm && siteDataSurveyForm.section_third_img
                      })`,
                  }}
                >
                  <div className="surveyContainer">
                    <div className="surveyPagelayout-left">
                      {siteFormData &&
                        siteFormData.data &&
                        siteFormData.data.data ? (
                        <SurveyFormNew
                          theme_id={"4"}
                          heading={
                            siteFormData.form_heading
                              ? siteFormData.form_heading
                              : ""
                          }
                          siteData={siteData}
                          className="modal-content"
                          data={
                            siteFormData &&
                              siteFormData.data &&
                              siteFormData.data.data
                              ? siteFormData.data.data
                              : this.state.data
                          }
                        />
                      ) : (
                        <div className="noSurvey">No survey found</div>
                      )}
                    </div>

                    {isStaticContent && (
                      <>
                        {" "}
                        <div className="surveyPagelayout-right">
                          <div className="surveyTextFields">
                            <div className="whySwitch">
                              <h2
                                dangerouslySetInnerHTML={{
                                  __html:
                                    siteDataSurveyForm &&
                                    siteDataSurveyForm.heading,
                                }}
                              ></h2>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    siteDataSurveyForm &&
                                    siteDataSurveyForm.content,
                                }}
                              ></div>
                            </div>
                            <div className="ourCustomers">
                              {siteDataSurveyForm &&
                                siteDataSurveyForm.col_one !== "no" && (
                                  <>
                                    <h2>
                                      {siteDataSurveyForm.section_heading}
                                    </h2>
                                    <blockquote>
                                      <p>
                                        {siteDataSurveyForm &&
                                          siteDataSurveyForm.section_sub_heading}
                                      </p>
                                      <div className="customerdetails">
                                        <div className="customerImg">
                                          <img
                                            src={
                                              siteDataSurveyForm &&
                                              siteDataSurveyForm.section_first_img
                                            }
                                            alt={
                                              siteDataSurveyForm &&
                                              siteDataSurveyForm.sfi_alt_text
                                            }
                                          />
                                        </div>
                                        <div className="customerName">
                                          {siteDataSurveyForm &&
                                            siteDataSurveyForm.sub_heading}
                                        </div>
                                      </div>
                                    </blockquote>
                                  </>
                                )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
      {/* AZX Theme */}
      {(layoutType === "5" || layoutType === 5) && (
        <>
          {renderMetaTags(siteData, isStaticContent, siteDataSurveyForm)}
          {/* Survey AZX-2 Layout starts*/}
          {surveyLayout === "sixthlayout" && (
            <div className="sixth_layout_global">
              <div className="newSurveyForm">
                <div className="surveySixth">
                  <div
                    className={`${isStaticContent && "surveyPageText"
                      } surveySixthContent`}
                  >
                    <div
                      className="surveyHeading"
                      style={{ display: hideReviewDiv ? "none" : "block" }}
                    >
                      <div className="container" id="surveyFirstContent">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: content.first_survey_content,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div
                      className={`newSurveyPageContainer ${DisplayDiv ? "SurveyContentClass" : ""
                        }`}
                    >
                      <div className="surveySixthFormBox">
                        {siteFormData &&
                          siteFormData.data &&
                          siteFormData.data.data ? (
                          <FifthLayoutSurvey
                            theme_id={"5"}
                            onClick={hideDiv.bind(this)}
                            hideReview={hideReview.bind(this)}
                            heading={
                              siteFormData && siteFormData.form_heading
                                ? siteFormData.form_heading
                                : ""
                            }
                            siteData={siteData}
                            className="modal-content"
                            data={
                              siteFormData &&
                                siteFormData.data &&
                                siteFormData.data.data
                                ? siteFormData.data.data
                                : ""
                            }
                          />
                        ) : (
                          <div className="noSurvey">No survey found</div>
                        )}
                      </div>
                    </div>
                    {/* //Survey page content */}
                    {isStaticContent && (
                      <div className="surveyTextFields">
                        <div
                          className="whySwitchOutside"
                          style={{ display: DisplayDiv ? "none" : "block" }}
                        >
                          <div className="whySwitch">
                            <div className="container">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    siteDataSurveyForm &&
                                    siteDataSurveyForm.content,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <hr
                          className="horizontalLine"
                          style={{ display: DisplayDiv ? "none" : "block" }}
                        ></hr>

                        {content.col_four && (
                          <div
                            style={{
                              display: hideReviewDiv ? "none" : "block",
                            }}
                            className={`ourCustomersOutside`}
                          >
                            <div className={`ourCustomers`}>
                              <div className="container">
                                {siteDataSurveyForm &&
                                  siteDataSurveyForm.col_one !== "no" && (
                                    <>
                                      <h2>
                                        <span className="trustpilot">
                                          <img src={googleReviewImage} alt="" />
                                        </span>
                                        {siteDataSurveyForm.heading}
                                      </h2>

                                      <div
                                        className="testimonialsListing"
                                        dangerouslySetInnerHTML={{
                                          __html: content.col_four,
                                        }}
                                      ></div>
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/*Survey AZX-1 Layout starts*/}
          {surveyLayout === "fifthlayout" && (
            <div className="sixth_layout_global">
              <div className="newSurveyForm">
                <div className="surveyContent newLayoutSurveyContent">
                  <div
                    className={`${isStaticContent && "surveyPageText"
                      } text-left newLayoutSurvey`}
                  >
                    <div
                      className="surveyHeading"
                      style={{ display: hideReviewDiv ? "none" : "block" }}
                    >
                      <div className="container" id="surveyFirstContent">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: content.first_survey_content,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div
                      className={`newSurveyPageContainer ${DisplayDiv ? "SurveyContentClass" : ""
                        }`}
                    >
                      <div className="newSurveyFormBox">
                        {siteFormData &&
                          siteFormData.data &&
                          siteFormData.data.data ? (
                          <FifthLayoutSurvey
                            theme_id={"5"}
                            onClick={hideDiv.bind(this)}
                            hideReview={hideReview.bind(this)}
                            heading={
                              siteFormData && siteFormData.form_heading
                                ? siteFormData.form_heading
                                : ""
                            }
                            siteData={siteData}
                            className="modal-content"
                            data={
                              siteFormData &&
                                siteFormData.data &&
                                siteFormData.data.data
                                ? siteFormData.data.data
                                : ""
                            }
                          />
                        ) : (
                          <div className="noSurvey">No survey found</div>
                        )}
                      </div>
                    </div>

                    {isStaticContent && (
                      <div className="surveyTextFields">
                        <div
                          className="whySwitchOutside"
                          style={{ display: DisplayDiv ? "none" : "block" }}
                        >
                          <div className="whySwitch">
                            <div className="container">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    siteDataSurveyForm &&
                                    siteDataSurveyForm.content,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <hr
                          className="horizontalLine"
                          style={{ display: DisplayDiv ? "none" : "block" }}
                        ></hr>

                        {content?.col_four && (
                          <div
                            style={{
                              display: hideReviewDiv ? "none" : "block",
                            }}
                            className={`ourCustomersOutside`}
                          >
                            <div className={`ourCustomers`}>
                              <div className="container">
                                {siteDataSurveyForm &&
                                  siteDataSurveyForm.col_one !== "no" && (
                                    <>
                                      <h2>
                                        <span className="trustpilot">
                                          <img src={googleReviewImage} alt="" />
                                        </span>
                                        {siteDataSurveyForm.heading}
                                      </h2>

                                      <div
                                        className="testimonialsListing"
                                        dangerouslySetInnerHTML={{
                                          __html: content.col_four,
                                        }}
                                      ></div>
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* New layout AZX-3 2022 */}
          {surveyLayout === "azx3layout" && (
            <div className="sixth_layout_global">
              <div className="newSurveyForm">
                <div className="surveyContent pageSurveyNewContent">
                  <div
                    className={`${isStaticContent && "surveyPageText"
                      } text-left newLayoutSurvey`}
                  >
                    <div
                      className="pageSurveyTitle"
                      style={{ display: hideReviewDiv ? "none" : "block" }}
                    >
                      <div className="container" id="surveyFirstContent">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: content.first_survey_content,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div
                      className={`newSurveyPageContainer ${DisplayDiv ? "SurveyContentClass" : ""
                        }`}
                    >
                      <div className="newSurveyFormBox iframeSurvey">
                        {(window.location.host ===
                          "hellosolar.mysolarcompare.com.au" ||
                          window.location.host ===
                          "www.hellosolar.mysolarcompare.com.au" ||
                          window.location.host ===
                          "zeroupfront.mysolarcompare.com.au" ||
                          window.location.host ===
                          "www.zeroupfront.mysolarcompare.com.au") && (
                            <>
                              <div class="ratio ratio-1x1">
                                <iframe
                                  src="https://form.typeform.com/to/J1zvbAfw"
                                  title="solar zero"
                                  scrolling="no"
                                  width="100%"
                                  height="580"
                                  frameborder="0"
                                  align="middle"
                                ></iframe>
                              </div>
                            </>
                          )}

                        {siteFormData &&
                          siteFormData.data &&
                          siteFormData.data.data &&
                          window.location.host !==
                          "hellosolar.mysolarcompare.com.au" &&
                          window.location.host !==
                          "www.hellosolar.mysolarcompare.com.au" &&
                          window.location.host !==
                          "zeroupfront.mysolarcompare.com.au" &&
                          window.location.host !==
                          "www.zeroupfront.mysolarcompare.com.au" ? (
                          <SurveyFormNew
                            theme_id={"5"}
                            heading={""}
                            siteData={siteData}
                            className="modal-content"
                            data={
                              siteFormData &&
                                siteFormData.data &&
                                siteFormData.data.data
                                ? siteFormData.data.data
                                : ""
                            }
                          />
                        ) : (
                          <div className="noSurvey"></div>
                        )}
                      </div>
                    </div>

                    {isStaticContent && (
                      <div className="surveyTextFields">
                        <div
                          className="whySwitchOutside"
                          style={{ display: DisplayDiv ? "none" : "block" }}
                        >
                          <div className="whySwitch">
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  siteDataSurveyForm &&
                                  siteDataSurveyForm.content,
                              }}
                            ></div>
                          </div>
                        </div>
                        <hr
                          className="horizontalLine"
                          style={{ display: DisplayDiv ? "none" : "block" }}
                        ></hr>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* New layout AZX-4 2022  */}
          {surveyLayout === "azx4layout" && (
            <div className="sixth_layout_global">
              <div className="newSurveyForm newDesignSurvey">
                <div className="surveyContent">
                  <div
                    className={`${isStaticContent && "surveyPageText"
                      } text-left newLayoutSurvey`}
                  >
                    <div className="container">
                      <div className="row d-flex align-items-center newSurveyDesignRow">
                        <div className="col-md-6">
                          <div
                            className="pageSurveyTitle"
                            style={{
                              display: hideReviewDiv ? "none" : "block",
                            }}
                          >
                            <div className="" id="surveyFirstContent">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: matches
                                    ? content.second_survey_content
                                      ? content.second_survey_content
                                      : content.first_survey_content
                                    : content.first_survey_content,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className={`newSurveyPageContainer ${DisplayDiv ? "SurveyContentClass" : ""
                              }`}
                          >
                            <div className="newSurveyFormBox">
                              {siteFormData &&
                                siteFormData.data &&
                                siteFormData.data.data ? (
                                <SurveyFormNew
                                  theme_id={"5"}
                                  heading={siteFormData?.form_heading || ""}
                                  siteData={siteData}
                                  className="modal-content"
                                  data={
                                    siteFormData &&
                                      siteFormData.data &&
                                      siteFormData.data.data
                                      ? siteFormData.data.data
                                      : ""
                                  }
                                />
                              ) : (
                                <div className="noSurvey">No survey found</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isStaticContent && (
                      <div className="surveyTextFields">
                        <div
                          className="whySwitchOutside"
                          style={{ display: DisplayDiv ? "none" : "block" }}
                        >
                          <div className="whySwitch">
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  siteDataSurveyForm &&
                                  siteDataSurveyForm.content,
                              }}
                            ></div>
                          </div>
                        </div>
                        <hr
                          className="horizontalLine"
                          style={{ display: DisplayDiv ? "none" : "block" }}
                        ></hr>

                        {content?.col_four && (
                          <div
                            style={{
                              display: hideReviewDiv ? "none" : "block",
                            }}
                            className={`ourCustomersOutside`}
                          >
                            <div className={`ourCustomers`}>
                              <div className="container">
                                {siteDataSurveyForm &&
                                  siteDataSurveyForm.col_one !== "no" && (
                                    <>
                                      <h2>
                                        <span className="trustpilot">
                                          <img src={googleReviewImage} alt="" />
                                        </span>
                                        {siteDataSurveyForm.heading}
                                      </h2>

                                      <div
                                        className="testimonialsListing"
                                        dangerouslySetInnerHTML={{
                                          __html: content.col_four,
                                        }}
                                      ></div>
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {siteDataSurveyForm &&
                    siteDataSurveyForm.section_third_paragraph ? (
                    <>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            siteDataSurveyForm &&
                            siteDataSurveyForm.section_third_paragraph,
                        }}
                      ></div>
                    </>
                  ) : (
                    <></>
                  )}

                  {siteDataSurveyForm &&
                    siteDataSurveyForm.section_second_paragraph ? (
                    <>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            siteDataSurveyForm &&
                            siteDataSurveyForm.section_second_paragraph,
                        }}
                      ></div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* New layout AZX-5 2022  */}
          {surveyLayout === "azx5layout" && (
            <div className="surveyFifthLayout">
              <div
                className={`${isStaticContent && "surveyFifthLayoutContent"}`}
              >
                <div className="surveyFifthLayoutHead">
                  <div
                    className="pageSurveyTitle"
                    style={{
                      display: "block",
                    }}
                  >
                    <div
                      className="animate__animated animate__bounceInDown"
                      id="surveyFirstContent"
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: hideReviewDiv
                            ? content.second_survey_content
                            : content.first_survey_content,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="surveyFifthLayoutForm">
                  <div
                    className={`newSurveyPageContainer ${DisplayDiv ? "SurveyContentClass" : ""
                      }`}
                  >
                    <div className="productImg animate__animated animate__bounceInLeft">
                      {siteDataSurveyForm &&
                        siteDataSurveyForm.section_third_img ? (
                        <img
                          src={siteDataSurveyForm.section_third_img}
                          alt=""
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="newSurveyFormBox">
                      {siteFormData &&
                        siteFormData.data &&
                        siteFormData.data.data ? (
                        <FifthLayoutSurvey
                          theme_id={"5"}
                          onClick={hideDiv.bind(this)}
                          hideReview={hideReview.bind(this)}
                          heading={
                            siteFormData && siteFormData.form_heading
                              ? siteFormData.form_heading
                              : ""
                          }
                          siteData={siteData}
                          className="modal-content"
                          data={
                            siteFormData &&
                              siteFormData.data &&
                              siteFormData.data.data
                              ? siteFormData.data.data
                              : ""
                          }
                        />
                      ) : (
                        <div className="noSurvey">No survey found</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="StepsSection">
                <div className="StepsSectionContent animate__animated animate__fadeInUp">
                  <div
                    className={`${isStaticContent && ""
                      } text-left newLayoutSurvey`}
                  >
                    {isStaticContent && (
                      <div className="surveyTextFields">
                        <div
                          className="whySwitchOutside"
                          style={{ display: DisplayDiv ? "none" : "block" }}
                        >
                          <div className="whySwitch">
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  siteDataSurveyForm &&
                                  siteDataSurveyForm.content,
                              }}
                            ></div>
                          </div>
                        </div>
                        <hr
                          className="horizontalLine"
                          style={{ display: DisplayDiv ? "none" : "block" }}
                        ></hr>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/*New layout AZX-6 2022 */}
          {surveyLayout === "azx6layout" && (
            <div
              className="surveySixthLayout"
              style={{
                backgroundImage: `url(${siteDataSurveyForm && siteDataSurveyForm.section_third_img
                  })`,
                backgroundPositionX: "center",
                backgroundPositionY: "center",
                width: "100%",
                backgroundSize: "cover",
              }}
            >
              <div className="newSurveyForm">
                <div className="surveyContent pageSurveyNewContent">
                  <div
                    className={`${isStaticContent && "surveyPageText"
                      } text-left newLayoutSurvey`}
                  >
                    <div
                      className="pageSurveyTitle animate__animated animate__bounceInDown"
                      style={{ display: hideReviewDiv ? "none" : "block" }}
                    >
                      <div className="container" id="surveyFirstContent">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: content.first_survey_content,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div
                      className={`newSurveyPageContainer animate__animated animate__fadeInRight ${DisplayDiv ? "SurveyContentClass" : ""
                        }`}
                    >
                      <div className="newSurveyFormBox">
                        {siteFormData &&
                          siteFormData.data &&
                          siteFormData.data.data ? (
                          <SurveyFormNew
                            theme_id={"5"}
                            heading={""}
                            siteData={siteData}
                            className="modal-content"
                            data={
                              siteFormData &&
                                siteFormData.data &&
                                siteFormData.data.data
                                ? siteFormData.data.data
                                : ""
                            }
                          />
                        ) : (
                          <div className="noSurvey">No survey found</div>
                        )}
                      </div>
                    </div>

                    {isStaticContent && (
                      <div className="surveyTextFields animate__animated animate__zoomIn">
                        <div
                          className="whySwitchOutside"
                          style={{ display: DisplayDiv ? "none" : "block" }}
                        >
                          <div className="whySwitch">
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  siteDataSurveyForm &&
                                  siteDataSurveyForm.content,
                              }}
                            ></div>
                          </div>
                        </div>
                        <hr
                          className="horizontalLine"
                          style={{ display: DisplayDiv ? "none" : "block" }}
                        ></hr>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* New layout AZX-7 2022  */}
          {surveyLayout === "azx7layout" && (
            <div className="sixth_layout_global">
              <div className="newSurveyForm seventhLayoutSurvey">
                <div className="surveyContent">
                  <div
                    className={`${isStaticContent && "surveyPageText"
                      } text-left newLayoutSurvey`}
                  >
                    <div className="container">
                      <div className="row d-flex align-items-center newSurveyDesignRow">
                        <div className="col-md-6">
                          <div
                            className="pageSurveyTitle"
                            style={{
                              display: hideReviewDiv ? "none" : "block",
                            }}
                          >
                            <div className="" id="surveyFirstContent">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: matches
                                    ? content.second_survey_content
                                      ? content.second_survey_content
                                      : content.first_survey_content
                                    : content.first_survey_content,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className={`newSurveyPageContainer ${DisplayDiv ? "SurveyContentClass" : ""
                              }`}
                          >
                            <div className="newSurveyFormBox">
                              {siteFormData &&
                                siteFormData.data &&
                                siteFormData.data.data ? (
                                <SurveyFormNew
                                  theme_id={"5"}
                                  heading={siteFormData?.form_heading || ""}
                                  siteData={siteData}
                                  className="modal-content"
                                  data={
                                    siteFormData &&
                                      siteFormData.data &&
                                      siteFormData.data.data
                                      ? siteFormData.data.data
                                      : ""
                                  }
                                />
                              ) : (
                                <div className="noSurvey">No survey found</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isStaticContent && (
                      <div className="surveyTextFields">
                        <div
                          className="whySwitchOutside"
                          style={{ display: DisplayDiv ? "none" : "block" }}
                        >
                          <div className="whySwitchSection">
                            <div className="whySwitchImg">
                              <img
                                src={
                                  siteDataSurveyForm &&
                                  siteDataSurveyForm.section_third_img
                                }
                              />
                            </div>
                            <div className="whySwitch">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    siteDataSurveyForm &&
                                    siteDataSurveyForm.content,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <hr
                          className="horizontalLine"
                          style={{ display: DisplayDiv ? "none" : "block" }}
                        ></hr>

                        {content?.col_four && (
                          <div
                            style={{
                              display: hideReviewDiv ? "none" : "block",
                            }}
                            className={`ourCustomersOutside`}
                          >
                            <div className={`ourCustomers`}>
                              <div className="container">
                                {siteDataSurveyForm &&
                                  siteDataSurveyForm.col_one !== "no" && (
                                    <>
                                      <h2>
                                        <span className="trustpilot">
                                          <img src={googleReviewImage} alt="" />
                                        </span>
                                        {siteDataSurveyForm.heading}
                                      </h2>

                                      <div
                                        className="testimonialsListing"
                                        dangerouslySetInnerHTML={{
                                          __html: content.col_four,
                                        }}
                                      ></div>
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* New layout AZX-8 2022  */}
          {surveyLayout === "azx8layout" && (
            <div className="sixth_layout_global">
              <div
                className="newSurveyForm eighthLayoutSurvey"
                style={{
                  backgroundImage: `url(${siteDataSurveyForm && siteDataSurveyForm.section_third_img
                    })`,
                }}
              >
                <div className="surveyContent">
                  <div
                    className={`${isStaticContent && "surveyPageText"
                      } text-left newLayoutSurvey`}
                  >
                    <div className="formSectionEighth">
                      <div className="container">
                        <div className="row d-flex align-items-center newSurveyDesignRow">
                          <div className="col-md-6">
                            <div
                              className="pageSurveyTitle"
                              style={{
                                display: hideReviewDiv ? "none" : "block",
                              }}
                            >
                              <div className="" id="surveyFirstContent">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: matches
                                      ? content.second_survey_content
                                        ? content.second_survey_content
                                        : content.first_survey_content
                                      : content.first_survey_content,
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                              className={`newSurveyPageContainer ${DisplayDiv ? "SurveyContentClass" : ""
                                }`}
                            >
                              <div className="newSurveyFormBox">
                                {siteFormData &&
                                  siteFormData.data &&
                                  siteFormData.data.data ? (
                                  <SurveyFormNew
                                    theme_id={"5"}
                                    heading={siteFormData?.form_heading || ""}
                                    siteData={siteData}
                                    className="modal-content"
                                    data={
                                      siteFormData &&
                                        siteFormData.data &&
                                        siteFormData.data.data
                                        ? siteFormData.data.data
                                        : ""
                                    }
                                  />
                                ) : (
                                  <div className="noSurvey">
                                    No survey found
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isStaticContent && (
                      <div className="surveyTextFields">
                        <div
                          className="whySwitchOutside"
                          style={{ display: DisplayDiv ? "none" : "block" }}
                        >
                          <div className="whySwitch">
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  siteDataSurveyForm &&
                                  siteDataSurveyForm.content,
                              }}
                            ></div>
                          </div>
                        </div>
                        <hr
                          className="horizontalLine"
                          style={{ display: DisplayDiv ? "none" : "block" }}
                        ></hr>

                        {content?.col_four && (
                          <div
                            style={{
                              display: hideReviewDiv ? "none" : "block",
                            }}
                            className={`ourCustomersOutside`}
                          >
                            <div className={`ourCustomers`}>
                              <div className="container">
                                {siteDataSurveyForm &&
                                  siteDataSurveyForm.col_one !== "no" && (
                                    <>
                                      <h2>
                                        <span className="trustpilot">
                                          <img src={googleReviewImage} alt="" />
                                        </span>
                                        {siteDataSurveyForm.heading}
                                      </h2>

                                      <div
                                        className="testimonialsListing"
                                        dangerouslySetInnerHTML={{
                                          __html: content.col_four,
                                        }}
                                      ></div>
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* New layout AZX-9 2022  */}
          {surveyLayout === "azx9layout" && (
            <div className="sixth_layout_global">
              <div
                className="newSurveyForm ninthLayoutSurvey"
                style={{
                  backgroundImage: `url(${siteDataSurveyForm && siteDataSurveyForm.section_third_img
                    })`,
                }}
              >
                <div className="surveyContent">
                  <div
                    className={`${isStaticContent && "surveyPageText"
                      } text-left newLayoutSurvey`}
                  >
                    <div className="formSectionNinth">
                      <div className="container">
                        <div className="row d-flex align-items-center newSurveyDesignRow">
                          <div className="col-md-6">
                            <div className="leftSectionninthForm">
                              <div
                                className="pageSurveyTitle"
                                style={{
                                  display: hideReviewDiv ? "none" : "block",
                                }}
                              >
                                <div className="" id="surveyFirstContent">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: matches
                                        ? content.second_survey_content
                                          ? content.second_survey_content
                                          : content.first_survey_content
                                        : content.first_survey_content,
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <div
                                className={`newSurveyPageContainer ${DisplayDiv ? "SurveyContentClass" : ""
                                  }`}
                              >
                                <div className="newSurveyFormBox">
                                  {siteFormData &&
                                    siteFormData.data &&
                                    siteFormData.data.data ? (
                                    <SurveyFormNew
                                      theme_id={"5"}
                                      heading={siteFormData?.form_heading || ""}
                                      siteData={siteData}
                                      className="modal-content"
                                      data={
                                        siteFormData &&
                                          siteFormData.data &&
                                          siteFormData.data.data
                                          ? siteFormData.data.data
                                          : ""
                                      }
                                    />
                                  ) : (
                                    <div className="noSurvey">
                                      No survey found
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            {isStaticContent && (
                              <div className="surveyTextFields">
                                <div
                                  className="whySwitchOutside"
                                  style={{
                                    display: DisplayDiv ? "none" : "block",
                                  }}
                                >
                                  <div className="whySwitch">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          siteDataSurveyForm &&
                                          siteDataSurveyForm.content,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                                <hr
                                  className="horizontalLine"
                                  style={{
                                    display: DisplayDiv ? "none" : "block",
                                  }}
                                ></hr>

                                {content?.col_four && (
                                  <div
                                    style={{
                                      display: hideReviewDiv ? "none" : "block",
                                    }}
                                    className={`ourCustomersOutside`}
                                  >
                                    <div className={`ourCustomers`}>
                                      <div className="container">
                                        {siteDataSurveyForm &&
                                          siteDataSurveyForm.col_one !==
                                          "no" && (
                                            <>
                                              <h2>
                                                <span className="trustpilot">
                                                  <img
                                                    src={googleReviewImage}
                                                    alt=""
                                                  />
                                                </span>
                                                {siteDataSurveyForm.heading}
                                              </h2>

                                              <div
                                                className="testimonialsListing"
                                                dangerouslySetInnerHTML={{
                                                  __html: content.col_four,
                                                }}
                                              ></div>
                                            </>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/*Survey AZX-10 Layout starts*/}
          {surveyLayout === "azx10layout" && (
            <div className="sixth_layout_global">
              <div className="newSurveyForm SurveyLayout10">
                <div className="surveyContent ">
                  <div
                    className={`${
                      isStaticContent && "surveyPageText"
                    } text-left newLayoutSurvey`}
                  >
                    
                    <div className="mainQuestionBlock">
                      <div
                        className="surveyHeading"
                        style={{ display: hideReviewDiv ? "none" : "block" }}
                      >
                        <div className="container" id="surveyFirstContent">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: content.first_survey_content,
                            }}
                          ></div>
                        </div>
                      </div>
                      <div
                        className={`newSurveyPageContainer ${
                          DisplayDiv ? "SurveyContentClass" : ""
                        }`}
                      >
                        <div className="newSurveyFormBox">
                          <div className="formTitleHeading">
                            <h2>Get Started</h2>
                            <p>Take the Quiz Below</p>
                            <svg xmlns="http://www.w3.org/2000/svg" class="arrowSvg" width="162.945" height="221" viewBox="0 0 162.945 221">
                              <path id="Path_1" data-name="Path 1" d="M8,138C17,94,33,11,87,12c7,.1,46,14,30,152-2.4-6.2-5.1-12.5-8-19-3.6-8.1-7.3-15.8-11-23a345.507,345.507,0,0,0,8,49,341.465,341.465,0,0,0,12.9,42c1.2,3.3,2.3,6,3.1,8a181.419,181.419,0,0,0,21-31c16.3-30.5,22.5-63.7,19-65-1.8-.7-7.3,6.8-24,43C146,66,137,0,87,0,50,0,7,42,0,131c0,8,1,13,3,13C7,144,7.6,140,8,138Z"/>
                            </svg>
                          </div>
                          {siteFormData &&
                          siteFormData.data &&
                          siteFormData.data.data ? (
                            <FifthLayoutSurvey
                              theme_id={"5"}
                              onClick={hideDiv.bind(this)}
                              hideReview={hideReview.bind(this)}
                              heading={
                                siteFormData && siteFormData.form_heading
                                  ? siteFormData.form_heading
                                  : ""
                              }
                              siteData={siteData}
                              className="modal-content"
                              data={
                                siteFormData &&
                                siteFormData.data &&
                                siteFormData.data.data
                                  ? siteFormData.data.data
                                  : ""
                              }
                            />
                          ) : (
                            <div className="noSurvey">No survey found</div>
                          )}
                        </div>
                      </div>
                    </div>


                    {isStaticContent && (
                      <div className="moreContent">
                        <div
                          className="moreContentOutSide"
                          style={{ display: DisplayDiv ? "none" : "block" }}
                        >
                              <div className="moreContainer"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    siteDataSurveyForm &&
                                    siteDataSurveyForm.content,
                                }}
                              ></div>
                            
                        </div>
                        <hr
                          className="horizontalLine"
                          style={{ display: DisplayDiv ? "none" : "block" }}
                        ></hr>

                        {content?.col_four && (
                          <div
                            style={{
                              display: hideReviewDiv ? "none" : "block",
                            }}
                            className={`ourCustomersOutside`}
                          >
                            <div className={`ourCustomers`}>
                              <div className="container">
                                {siteDataSurveyForm &&
                                  siteDataSurveyForm.col_one !== "no" && (
                                    <>
                                      <h2>
                                        <span className="trustpilot">
                                          <img src={googleReviewImage} alt="" />
                                        </span>
                                        {siteDataSurveyForm.heading}
                                      </h2>

                                      <div
                                        className="testimonialsListing"
                                        dangerouslySetInnerHTML={{
                                          __html: content.col_four,
                                        }}
                                      ></div>
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/*Survey AZX-11 Layout starts*/}
          {surveyLayout === "azx11layout" && (
            <div className="sixth_layout_global">
              <div className="surveyLayout11">
                <div className="surveyContent newLayoutSurveyContent">
                  <div
                    className={`${isStaticContent && "surveyPageText"
                      } text-left newLayoutSurvey`}
                  >
                    <div
                      className="surveyHeading"
                      style={{ display: hideReviewDiv ? "none" : "block" }}
                    >
                      <div className="container" id="surveyFirstContent">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: content.first_survey_content,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div
                      className={`newSurveyPageContainer ${DisplayDiv ? "SurveyContentClass" : ""
                        }`}
                    >
                      <div className="newSurveyFormBox">
                        {
                          siteFormData?.data?.data ? (
                          <SurveyFormNew
                            theme_id={"5"}
                            heading={siteFormData?.form_heading || ""}
                            siteData={siteData}
                            className="modal-content"
                            data={
                              siteFormData?.data?.data ?? ""
                            }
                          />
                        ) : (
                          <div className="noSurvey">No survey found</div>
                        )}
                        <div className="completeForm"><img src={timeImg} alt="sdas" /> Complete our short form. It only takes a minute </div>
                      </div>
                    </div>

                    {isStaticContent && (
                      <div className="surveyTextFields">
                        <div
                          className="whySwitchOutside"
                          style={{ display: DisplayDiv ? "none" : "block" }}
                        >
                          <div className="whySwitch">
                            <div className="container">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    siteDataSurveyForm &&
                                    siteDataSurveyForm.content,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <hr
                          className="horizontalLine"
                          style={{ display: DisplayDiv ? "none" : "block" }}
                        ></hr>

                        {content?.col_four && (
                          <div
                            style={{
                              display: hideReviewDiv ? "none" : "block",
                            }}
                            className={`ourCustomersOutside`}
                          >
                            <div className={`ourCustomers`}>
                              <div className="container">
                                {siteDataSurveyForm &&
                                  siteDataSurveyForm.col_one !== "no" && (
                                    <>
                                      <h2>
                                        <span className="trustpilot">
                                          <img src={googleReviewImage} alt="" />
                                        </span>
                                        {siteDataSurveyForm.heading}
                                      </h2>

                                      <div
                                        className="testimonialsListing"
                                        dangerouslySetInnerHTML={{
                                          __html: content.col_four,
                                        }}
                                      ></div>
                                    </>
                                  )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

        </>
      )}

      {(layoutType === "2" || layoutType === 2) && (
        <>
          {renderMetaTags(siteData, isStaticContent, siteDataSurveyForm)}

          {siteDataValue ? (
            <div className="bodyContentArea">
              <section className="firstSection">
                <main>
                  <section className="competitionBannerSection">
                    <div className="container">
                      <img
                        // alt={siteData.lsi_alt_tex}
                        src={
                          siteDataSurveyForm &&
                          siteDataSurveyForm.section_third_img
                        }
                        // title={siteData.lsi_alt_tex}
                        className="img-fluid"
                      />
                    </div>
                  </section>
                  <section className="formSection">
                    <div className="container">
                      <div className="formBlock">
                        <div className="formBlockContainer">
                          {formstate === false && (
                            <div
                              className="formHead"
                              dangerouslySetInnerHTML={{
                                __html:
                                  siteDataSurveyForm &&
                                  siteDataSurveyForm.section_third_paragraph,
                              }}
                            ></div>
                          )}
                          <DynamicForm
                            data={
                              Utils.getDynamciFormContent(siteData, 1).data.data
                            }
                            siteData={siteData}
                            page_id={1}
                            theme_id={2}
                            setformstate={setformstate}
                            formstate={formstate}
                          />
                          {formstate === false && (
                            <div className="info_sm">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html:
                                    siteDataSurveyForm &&
                                    siteDataSurveyForm.section_first_paragraph,
                                }}
                              ></p>
                            </div>
                          )}
                        </div>

                        {formstate === true && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: Utils.getPageContent(siteData, 7)
                                .section_second_paragraph,
                            }}
                          ></div>
                        )}
                      </div>
                    </div>
                  </section>
                </main>
              </section>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
SurveyFormBodyNew.propTypes = {
  siteData: object,
  layoutType: string,
};

SurveyFormBodyNew.defaultProp = {
  layoutType: "1",
  surveyLayout: "firstlayout",
};
export default SurveyFormBodyNew;
