import React, { Component } from "react";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import LayoutFirstDemo from "../layout-first-demo";
import LayoutSecond from "../layout-shopadocket";
import LayoutFourth from "../layout-fourth";
import LayoutSixth from "../layout-sixth";
import LayoutSeventh from "../layout-seventh";
import SurvayFormMainNew from "./survey-page-new";
import { getSiteData } from "../../actions/manage-site-action-type";
import Utils from "../../utils";
import Api from "../../api";
import queryString from "query-string";
import history from "../../routes/history";
import { v4 as uuidv4 } from "uuid";
import "./survey-page.scss";
class SurveyPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: "first",
      page: 6,
      isPageRefreshed: false,
    };
  }

  componentDidMount() {
    let queries = queryString.parse(
      history.location.search ? history.location.search.toLowerCase() : ""
    );
    let query = null;
    if (queries && (queries.utm_source || queries.gclid)) {
      query =
        typeof queries === "object" && queries !== null
          ? JSON.stringify(queries)
          : queries.toString();
    }

    const sessionId = uuidv4();
    localStorage.setItem("sessionId", sessionId);
    Api.trackMicrositeSteps(sessionId, 0, "", "", query);

    const { getSiteData, getSiteDataObject } = this.props;
    localStorage.removeItem("userdata");
    if (getSiteDataObject.length === 0) {
      this.setState({ isPageRefreshed: true });
      getSiteData(this.state.page);
    } else {
      Utils.renderFont(getSiteDataObject);
    }
  }

  render() {
    const { getSiteDataObject } = this.props;
    const { page, isPageRefreshed } = this.state;

    return getSiteDataObject &&
      getSiteDataObject.content_manage_sites &&
      getSiteDataObject.content_manage_sites[0] ? (
      getSiteDataObject.theme === "1" ? (
        <>
          <LayoutFirstDemo
            siteData={getSiteDataObject}
            pageType={"survey"}
            page={page}
            isPageRefreshed={isPageRefreshed}
          >
            <SurvayFormMainNew
              siteData={getSiteDataObject}
              layoutType={"1"}
              page={page}
            />
          </LayoutFirstDemo>
        </>
      ) : getSiteDataObject.theme === "5" ? (
        <>
          <LayoutSixth
            siteData={getSiteDataObject}
            pageType={"survey"}
            page={page}
            isPageRefreshed={isPageRefreshed}
          >
            <SurvayFormMainNew
              siteData={getSiteDataObject}
              layoutType={"5"}
              page={page}
            />
          </LayoutSixth>
        </>
      ) : getSiteDataObject.theme === "7" ? (
        <>
          <LayoutSeventh
            siteData={getSiteDataObject}
            pageType={"survey"}
            page={page}
            isPageRefreshed={isPageRefreshed}
          >
            <SurvayFormMainNew
              siteData={getSiteDataObject}
              layoutType={"7"}
              page={page}
            />
          </LayoutSeventh>
        </>
      ) : getSiteDataObject.theme === "2" ? (
        <>
          <LayoutSecond
            siteData={getSiteDataObject}
            pageType={"survey"}
            page={page}
            isPageRefreshed={isPageRefreshed}
          >
            <SurvayFormMainNew
              siteData={getSiteDataObject}
              layoutType={"2"}
              page={page}
            />
          </LayoutSecond>
        </>
      ) : (
        <LayoutFourth
          siteData={getSiteDataObject}
          pageType={"survey"}
          page={page}
          isPageRefreshed={isPageRefreshed}
        >
          <SurvayFormMainNew
            siteData={getSiteDataObject}
            layoutType={"4"}
            page={page}
          />
        </LayoutFourth>
      )
    ) : (
      <div className="loader-div">
        <CircularProgress />
      </div>
    );
  }
}

const mapStateToProps = ({
  ManageSite: { isLoading, getSiteDataObject, getSiteStatus, getSiteError },
}) => ({ isLoading, getSiteDataObject, getSiteStatus, getSiteError });
export default connect(mapStateToProps, { getSiteData })(SurveyPage);
