// import { trans } from './index';
const words = [
  "Nuts",
  "nuts",
  "Deez",
  "deez",
  "prick",
  "circumcision",
  "hell",
  "bag",
  "nis",
  "pe",
  "shit ass",
  "shitass",
  "shitass",
  "asshole",
  "asshole",
  "shit ass",
  "shit ass",
  "ass",
  "asshole",
  "shitass",
  "ass",
  "ass",
  "cunt",
  "dick",
  "suck",
  "hole",
  "clown",
  "cunt",
  "horseshit",
  "fuck",
  "bastard",
  "Christ on a bike",
  "shit",
  "godsdamn",
  "motherfucker",
  "sweet Jesus",
  "twat",
  "bollocks",
  "Christ on a cracker",
  "holy shit",
  "son of a whore",
  "child-fucker",
  "son of a motherless goat",
  "nigger",
  "son of a bitch",
  "cunt",
  "bitch",
  "crap",
  "goddamn",
  "damn",
  "slut",
  "nigga",
  "frigger",
  "frigger",
  "nigga",
  "slut",
  "damn",
  "goddamn",
  "crap",
  "bitch",
  "cunt",
  "son of a bitch",
  "nigger",
  "son of a motherless goat",
  "child-fucker",
  "son of a whore",
  "holy shit",
  "Christ on a cracker",
  "bollocks",
  "twat",
  "sweet Jesus",
  "prick",
  "motherfucker",
  "godsdamn",
  "shit",
  "Christ on a bike",
  "bastard",
  "fuck",
  "horseshit",
  "twat",
  "sweet Jesus",
  "son of a whore",
  "son of a motherless goat",
  "son of a bitch",
  "slut",
  "shit",
  "prick",
  "nigger",
  "nigga",
  "motherfucker",
  "horseshit",
  "holy shit",
  "godsdamn",
  "goddamn",
  "fuck",
  "frigger",
  "damn",
  "cunt",
  "crap",
  "Christ on a cracker",
  "Christ on a bike",
  "child-fucker",
  "bollocks",
  "bitch",
  "bastard",
  "Dad",
  "mom",
  "papa",
  "mommy",
  "mummy",
  "your",
  "our",
  "my",
  "me",
  "other",
  "son",
  "dog",
  "anonymous"
];

const mincharlabels = [
  "name",
  "fullname",
  "full-name",
  "firstname",
  "fname",
  "first-name",
  "lastname",
  "lname",
  "last-name",
];

const required = (v, label) => {
  let errorMsg = "";

  if (/^\s*$/.test(v)) {
    errorMsg = `${label} is required`;
  }

  return errorMsg;
};


const requiredMinChar = (v, label) => {
  if (/^\s*$/.test(v)) {
    return `${label} is required`;
  }
  var res = mincharlabels.filter(
    (mincharlabels) =>
      mincharlabels.toLowerCase() === label.trim().toLowerCase()
  );
  if (res.length > 0 && v.length < 3) {
    return `The minimum character length should be of three characters`;
  }
  if (v.length < 2) {
    return `The minimum character length should be of two characters`;
  }
};

const checkRedunctWord = (item, label) => {
  for (var i = 0; i <= item.length; i++) {
    if (i >= 2) {
      if (item[i - 2] === item[i - 1] && item[i] === item[i - 1] && !(/^\d+$/.test(item[i-1]))) {
        return `this repetitive word '${item[i]}' is not allowed!`;
      }
    }
  }
  var res = words.filter((word) => word.toLowerCase() === item.toLowerCase());
  if (res.length > 0 ) {
    return `this repetitive word '${item}' is not allowed.`;
  }
};

const checkSameNames = () => {
  var errorMsg = "first Name and last Name can't be same";
  return errorMsg;
};

const mobileCheck = (v) => {
  let errorMsg = "";

  if (v.length !== 10) {
    errorMsg = "Mobile no. should have at least 10 digits.";
  }

  return errorMsg;
};

const checkPassword = (password) => {
  let errMsg = "";
  if (password.length < 8) {
    errMsg = "Your password must have at least 8 characters";
  }

  // if (!/\d/.test(password) || !/[a-zA-Z]/.test(password)) {
  //   errMsg='Your password should contain alphabet and number';

  // }

  // if (/\s/g.test(password)) {
  //     errMsg= 'The password you submitted contains spaces. Did you copy it? May be try typing it in';

  // }

  return errMsg;
};

const validateConfirmPassword = (confirmPassword, password) => {
  let errorMsg = "";

  if (confirmPassword !== password) {
    errorMsg =
      "The password and confirm password are not matched. Please try again";
  }

  return errorMsg;
};

const reg = (regex, v, regMsg) => {
  let err = "";

  if (!regex.test(v)) {
    err = regMsg;
  }
  return err;
};

const validateEmail = (v) => {
  let errorMsg = "";

  if (!v.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
    errorMsg = "Please enter valid email.";
  }
  return errorMsg;
};

const validateField = {
  checkPassword,
  mobileCheck,
  reg,
  required,
  validateConfirmPassword,
  validateEmail,
  requiredMinChar,
  checkRedunctWord,
  checkSameNames,
};

export default validateField;

export const checkValidationRule = (type, validateRule) => {
  if (type === "VALIDATE_ON_FORM_SUBMIT") {
    // will remove when VALIDATE_ON_FORM_SUBMIT is added to all inputbox.
    return true;
  } else if (validateRule) {
    return validateRule.indexOf(type) > -1;
  }

  return false;
};
