import React, { useState, useEffect } from "react";
import Autosuggest from "react-autosuggest";
import "./autocomplete-post-suburb.scss";
import { checkValidationRule } from "../../utils/validate";
import { Grid } from "@material-ui/core";
import Error from "../../components/error/index";
import FormLabel from "@material-ui/core/FormLabel";
import Tooltip from "../../views/tool-tip";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Utils from "../../utils";
import Api from "../../api";

const useStyles = makeStyles((theme) => ({
  otherLayouts: {
    height: "59px",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
  surveyMain: {
    height: "59px",
    borderRadius: "4px 0px 0px 4px",
    padding: "2px",
    backgroundColor: "white",
    flex: "0 0 50px",
    maxWidth: "50px",
    lineHeight: "50px",
    position: "relative",
    zIndex: "1",
  },
  OtherMain: {
    height: "45px",
    marginBottom: "-50px",
    borderRadius: "4px 0px 0px 4px",
    padding: "2px",
    backgroundColor: "white",
  },
  icon: {
    width: "35px",
    height: "35px",
    display: "block",
    fontSize: "27px",
  },
  mainSelect: {
    marginLeft: "41px",
    marginTop: "5px",
    textAlign: "start",
  },

  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
}));

const AutoCompletePostSuburb = ({
  theme_id,
  options,
  name1,
  label,
  description,
  validateOptions,
  type,
  validate,
  validationCallback,
  value,
  handleChange,
  iconName,
  placeholder,
  controltype,
  country,
  themecolors,
  activestep,
}) => {
  const backgroundCol = themecolors.onHoverInColor;
  const [val, setVal] = useState(value);
  const [suggestions, setSuggestions] = useState([]);
  const [colHovor, SetolHovor] = useState(themecolors.textColor);
  const [col, SetColor] = useState(themecolors.textColor);
  const [errors, setError] = useState({});
  const [typingTimeout, setTypingTimeout] = useState(0);
  const { [name1]: error = "" } = errors;
  const [jsonPostcodeList, setJsonPostcodeList] = useState([]);
  let names = [];

  const getStates = async (text, country) => {
    if (text) {
      var baseurl = Utils.getHost().apiUrl;

      var data = JSON.stringify({
        text: text,
        country: country,
        host: window.location.host,
      });

      var config = {
        method: "post",
        url: `${baseurl}getjsonstates`,
        headers: {
          Authorization: "c2xkZ21sa21nbG1s",
          "Content-Type": "application/json",
        },
        data: data,
      };
      try {
        const result = await axios(config);
        localStorage.setItem("addArr", JSON.stringify(result.data.states));
        setJsonPostcodeList(result.data.states);
        let textArry = [];
        let arr = result.data.states?.map((element) => {
          return textArry.push(element.text);
        });
        return textArry;
      } catch (err) {
        console.log("err", err);
        return [];
      }
    }
  };

  const zipcode = jsonPostcodeList;

  const handleErrors = (errorMsg, isVisible, e) => {
    const err = { [name1]: errorMsg };
    if (checkValidationRule(isVisible)) {
      setError({ ...err });
    } else if (isVisible === "VALIDATE_ON_CLICK" && e.key !== "Enter") {
      // this added because we want to unset error on type
      setError({ err: "" });
    }

    validationCallback(!!errorMsg);
  };

  const validateFields = (isVisible, e) => {
    //debugger;
    const { required } = validateOptions;
    if (required) {
      let errorMsg;

      if (value === "" || value === null) {
        errorMsg = `${name1} is required`;
      } else {
        errorMsg = "";
      }
      if (errorMsg) {
        handleErrors(errorMsg, isVisible, e);
        return;
      }
    }
    handleErrors("", isVisible, e);
  };

  // componentDidupdate
  useEffect(() => {
    if (validate) {
      validateFields("VALIDATE_ON_FORM_SUBMIT");
    }
    //$("*").css("font-family", localStorage.getItem("fontFamily"));
    //setVal(value);
  });

  const findAddressMetadata = (value) => {
    const addrArr = localStorage.getItem("addArr");

    if (addrArr) {
      var found = JSON.parse(addrArr).find(function (element) {
        return element.text == value;
      });
    }

    const resss = Api.getAddressMetadataWithPxid(found.pxid).then(
      (response) => {
        localStorage.setItem("pacode", JSON.stringify(response.data.message));
      }
    );
  };

  const handleChangeAuto = (event, value1) => {
    if (value1 !== null) {
      if (validateOptions.required) {
        value = value1;
        validateFields("VALIDATE_ON_FORM_SUBMIT", event);
      }
      //setVal(value1);
      value = value1;
      handleChange(value1);
      if (
        country == "NZ" &&
        (window.location.host === "kiwihouseprice.com" ||
          window.location.host === "www.kiwihouseprice.com" ||
          window.location.host === "www.mybroadbandcompare.co.nz" ||
          window.location.host === "mybroadbandcompare.co.nz" ||
          window.location.host === "mycarinsurancecompare.co" ||
          window.location.host === "www.mycarinsurancecompare.co")
      ) {
        findAddressMetadata(value1);
      }
    } else {
      if (validateOptions.required) {
        value = "";
        validateFields("VALIDATE_ON_FORM_SUBMIT", event);
      }
    }
  };

  // console.log("options", options);
  if (controltype.includes("postcode_suburb")) {
    names = zipcode.zipcode;
  } else if (options && options.length > 0) {
    options.map((item) => {
      names.push(item.label);
    });
  }

  const escapeRegexCharacters = (str) =>
    str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

  const getSuggestionsVal = (value) => {
    let escapedValue = escapeRegexCharacters(value);

    SetolHovor(backgroundCol);
    SetColor(backgroundCol);
    if (escapedValue === "") {
      return controltype.includes("postcode_suburb") ? [] : names;
    }
    if (escapedValue && escapedValue.length > 2) {
      let resss = getStates(escapedValue, country);
      resss.then((response) => {
        setSuggestions(response);
      });
    }
  };

  const getSuggestionValue = (suggestion) => suggestion;

  const renderSuggestion = (suggestion) => suggestion;

  const renderInputComponent = (inputProps) => (
    <div
      id={`step_${activestep}_${name1}_div`}
      className={
        theme_id === "2" ? "inputContainer form-group" : "inputContainer"
      }
      style={{
        border: ` ${
          type === "survey" ? `2px solid ${colHovor}` : "1px solid #ced4da"
        }`,
        background: "#fff",
      }}
    >
      <i
        className={`demo-icon ${iconName} ${classes.icon}`}
        style={{ color: `${type === "survey" ? colHovor : "#7a7a7a"}` }}
      ></i>
      <input id={`step_${activestep}_${name1}`} {...inputProps} />
      {theme_id === "2" && (
        <label id={`step_${activestep}_${name1}_label`}>
          {label}
          <span>*</span>
        </label>
      )}
    </div>
  );

  const onChange = (event, { newValue, method }) => {
    setVal(newValue);
  };

  function shouldRenderSuggestions(value, reason) {
    return (
      (controltype.includes("postcode_suburb") && value.trim().length > 2) ||
      !controltype.includes("postcode_suburb")
    );
  }

  const onSuggestionsSelected = (event, value) => {
    handleChangeAuto(event, value.suggestionValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(function () {
        getSuggestionsVal(value);
      }, 500)
    );
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const checkFocus = () => {
    SetolHovor(backgroundCol);
    SetColor(backgroundCol);
  };

  const MenuClose = () => {
    SetolHovor(themecolors.textColor);
    SetColor(themecolors.textColor);
  };

  const inputProps = {
    placeholder: placeholder,
    value: val,
    onChange: onChange,
    style: { color: theme_id === "2" ? "#000" : `${col}` },
    onBlur: MenuClose,
  };

  const classes = useStyles();
  return (
    <Grid
      onMouseOver={checkFocus}
      onMouseLeave={MenuClose}
      className={`asd select-box  ${!!error && "select-field--error"}`}
    >
      {!!label && theme_id !== 3 && theme_id !== "2" && (
        <FormLabel style={{ fontWeight: "500", display: "flex" }}>
          {label}&nbsp;
          {validateOptions.required && type !== "survey" && (
            <span className="mendatory">*</span>
          )}
          {!!description && <Tooltip title={description} />}
        </FormLabel>
      )}
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={renderInputComponent}
        onSuggestionSelected={(e, value) => onSuggestionsSelected(e, value)}
        shouldRenderSuggestions={(e, value) =>
          shouldRenderSuggestions(e, value)
        }
      />

      {!!error && theme_id !== 3 && <Error errorMessage={error} />}
    </Grid>
  );
};
AutoCompletePostSuburb.defaultProps = {
  controltype: "postcode_suburb",
  country: "AU",
};

export default AutoCompletePostSuburb;
