import React from "react";
import DynamicForm from "../dynamic-form";
import { Helmet } from "react-helmet";
import { object } from "prop-types";

const LayoutBody = (props) => {
  const { siteData, layoutType } = props;

  let siteDataValue = siteData.content_manage_sites.filter(filterByID)[0];
  let siteFormData = siteData.dynamic_forms.filter(filterByID)[0];

  if (siteDataValue === undefined && siteDataValue === null) {
    window.location.href = '/' + window.location.pathname.split('/')[1];
  }

  function filterByID(item) {
    if (item.page_id === 4) {
      return item;
    }
  }

  return (
    <>
      {layoutType === "8" && (
        <>
          <div
            className="pageTitle"
            style={{ backgroundColor: siteData.primary_color }}
          >
            <Helmet>
              <title>{siteDataValue.meta_title}</title>
              <meta
                name="description"
                content={siteDataValue.meta_description}
              />
              <link rel="icon" href={siteData.favicon} sizes="16x16" />
            </Helmet>
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <h1>{siteDataValue.page_title}</h1>
                  <p>{siteDataValue.page_sub_title}</p>
                </div>
              </div>
            </div>
          </div>
          <section className="innerPageContent">
            <div className="container">
              <h2>{siteDataValue.heading}</h2>
              <div className="row">
                {siteDataValue.content ? (
                  <div className="col-sm-12">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: siteDataValue.content,
                      }}
                    ></div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </section>
          {siteFormData && siteFormData.data && siteFormData.data.data ? (
            <section className="contact-block contactWrapperinner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <form id="contact-form" className="contactWrapper1 width80">
                      <div className="form-heading">
                        <h2>{siteDataValue.section_heading}</h2>
                        <div className="messages">
                          {siteDataValue.section_sub_heading}
                        </div>
                      </div>

                      <div className="controls">
                        <div className="row">
                          {siteFormData.form_heading && (
                            <h1 className="form-heading-css">
                              {siteFormData.form_heading}
                            </h1>
                          )}
                          <DynamicForm
                            data={siteFormData.data.data}
                            siteData={siteData}
                            page_id={4}
                            theme_id={1}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <></>
          )}
        </>
      )}
      {layoutType === "1" && (
        <>
          <div
            className="pageTitle"
            style={{ backgroundColor: siteData.primary_color }}
          >
            <Helmet>
              <title>{siteDataValue.meta_title}</title>
              <meta
                name="description"
                content={siteDataValue.meta_description}
              />
              <link rel="icon" href={siteData.favicon} sizes="16x16" />
            </Helmet>
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <h1>{siteDataValue.page_title}</h1>
                  <p>{siteDataValue.page_sub_title}</p>
                </div>
              </div>
            </div>
          </div>
          <section className="innerPageContent">
            <div className="container">
              <h2>{siteDataValue.heading}</h2>
              <div className="row">
                {siteDataValue.content ? (
                  <div className="col-sm-12">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: siteDataValue.content,
                      }}
                    ></div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </section>
          {siteFormData && siteFormData.data && siteFormData.data.data ? (
            <section className="contact-block contactWrapperinner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <form id="contact-form" className="contactWrapper1 width80">
                      <div className="form-heading">
                        <h2>{siteDataValue.section_heading}</h2>
                        <div className="messages">
                          {siteDataValue.section_sub_heading}
                        </div>
                      </div>

                      <div className="controls">
                        <div className="row">
                          {siteFormData.form_heading && (
                            <h1 className="form-heading-css">
                              {siteFormData.form_heading}
                            </h1>
                          )}
                          <DynamicForm
                            data={siteFormData.data.data}
                            siteData={siteData}
                            page_id={4}
                            theme_id={1}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <></>
          )}
        </>
      )}
     
      {layoutType === "5" && (
        <>
          <div
            className="pageTitle"
            // style={{ backgroundColor: siteData.primary_color }}
          >
            <Helmet>
              <title>{siteDataValue.meta_title}</title>
              <meta
                name="description"
                content={siteDataValue.meta_description}
              />
              <link rel="icon" href={siteData.favicon} sizes="16x16" />
            </Helmet>
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <h1>{siteDataValue.page_title}</h1>
                  <p>{siteDataValue.page_sub_title}</p>
                </div>
              </div>
            </div>
          </div>
          <section className="innerPageContent">
            <div className="container">
              <h2>{siteDataValue.heading}</h2>
              <div className="row">
                {siteDataValue.content ? (
                  <div className="col-sm-12">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: siteDataValue.content,
                      }}
                    ></div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </section>
          {siteFormData && siteFormData.data && siteFormData.data.data ? (
            <section className="contact-block contactWrapperinner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <form id="contact-form" className="contactWrapper1 width80">
                      <div className="form-heading">
                        <h2>{siteDataValue.section_heading}</h2>
                        <div className="messages">
                          {siteDataValue.section_sub_heading}
                        </div>
                      </div>

                      <div className="controls">
                        <div className="row">
                          {siteFormData.form_heading && (
                            <h1 className="form-heading-css">
                              {siteFormData.form_heading}
                            </h1>
                          )}
                          <DynamicForm
                            data={siteFormData.data.data}
                            siteData={siteData}
                            page_id={4}
                            theme_id={5}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <></>
          )}
        </>
      )}

      {layoutType === "4" && (
        <>
          <section className="bgbluew"></section>
          <Helmet>
            <title>{siteDataValue.meta_title}</title>
            <meta name="description" content={siteDataValue.meta_description} />
            <link rel="icon" href={siteData.favicon} sizes="16x16" />
          </Helmet>
          <section className="innerpage">
            <div className="container">
              <div className="col-sm-12">
                <div className="content-innerpages">
                  <h2>{siteDataValue.page_title}</h2>
                  <div className="bototmmcontent">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: siteDataValue.section_second_paragraph,
                      }}
                    ></div>
                  </div>

                  {siteFormData && siteFormData.data && siteFormData.data.data && (
                    <div className="controls">
                      <DynamicForm
                        data={siteFormData.data.data}
                        siteData={siteData}
                        page_id={2}
                        theme_id={4}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      {layoutType === "7" && (
        <>
          <Helmet>
            <title>{siteDataValue.meta_title}</title>
            <meta name="description" content={siteDataValue.meta_description} />
            <link rel="icon" href={siteData.favicon} sizes="16x16" />
          </Helmet>
          <section className="newThemeinnerPage">
            <div className="container">
              <div className="col-sm-12">
                <div className="pageTitleInner">
                  <h1>{siteDataValue.page_title}</h1>
                  <h2>{siteDataValue.page_sub_title}</h2>
                </div>
                <div
                  className="privacyAreaInner"
                  dangerouslySetInnerHTML={{ __html: siteDataValue.content }}
                ></div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

LayoutBody.propTypes = {
  siteData: object,
};

LayoutBody.defaultProp = {
  layoutType: "1",
};

export default LayoutBody;
