import React, { Fragment, useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { string, number, oneOfType, func, bool } from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Error from "../../components/error";
import { checkValidationRule } from "../../utils/validate";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import InputField from "../input-field/input-field";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "../../views/tool-tip";
import Switch from "@material-ui/core/Switch";
import FormLabel from "@material-ui/core/FormLabel";
//import { ReactComponent as Icon } from "./../../assets/icons/checkbox.svg";
const useStyles = makeStyles((theme) => ({
  formControl: {},
  root: {
    marginLeft: "10px",
    backgroundColor: "#fff",
    // borderRadius: "4px",
    padding: "6px",
  },
  rootInput: {
    maxWidth: "250px",
    marginLeft: "16px",
  },
  formLabelrootInline: {
    paddingLeft: "0px ",
    color: "#585555 !important",
    fontSize: "30px",
    padding: " 10px",
    fontWeight: "500",
    display: "flex",
  },
  formLabelroot: {
    display: "flex",
    paddingLeft: "0px",
    alignItems: "center",
    color: "#000 !important",
    fontSize: "14px",
    fontWeight: "bold",
  },
  checkMain: {
    width: "100%",
    marginLeft: "0px",
  },
  formControlMain: {
    fontSize: "1.3rem",
    marginRight: "0px",
  },
  itemMain: {
    height: "33px",
    width: "33px",
    marginRight: "14px",
    fontSize: "27px",
    color: "rgb(122, 122, 122)",
  },
  spanMain: {
    marginLeft: "15px",
  },
  iconsSecond: {
    height: "33px",
    width: "33px",
    marginRight: "14px",
    color: `#7a7a7a`,
    fontSize: "27px",
  },
}));

const CheckBox = ({
  type,
  options,
  label,
  name,
  className,
  other,
  inline,
  toggle,
  onChange,
  validate,
  validationRule,
  validateOptions,
  validationCallback,
  // value,
  description,
  onChangeOther,
  data,
  theme_id,
  theme,
  iconName,
  themecolors,
  activestep,
}) => {
  const [errors, setError] = useState({});
  const [valueCheckboxOther, setValueCheckboxOther] = React.useState("");
  const [otherTxt, setField] = React.useState("");
  const classes = useStyles();
  const [valueCheckbox, setValueCheckbox] = React.useState(options);
  const col = themecolors.onHoverInColor;

  //   const [test, setTest] = useState(() => {
  //       } else if (theme === "first-theme") {
  //  if (theme === "default-theme") {
  //       setCol("#19ad91");
  //        setCol("#405d90");
  //     } else if (theme === "second-theme") {
  //       setCol("#aba8ff");
  //     } else if (theme === "third-theme") {
  //       setCol("#4094a9");
  //     } else if (theme === "sixth-theme") {
  //       setCol("#5C0F8B");
  //     }
  //   });
  const setSelectedOptions = (e) => {
    valueCheckbox.filter((item) => {
      if (item.value === e.target.value) {
        item.selected = e.target.checked;
      }
      return item;
    });
    onChange(e, valueCheckbox);
    setValueCheckbox(valueCheckbox);
  };
  // const initialValue = [];

  const onChangeCheckBox = (e) => {
    setSelectedOptions(e);
    setError({});
  };

  const onChangeCheckBoxOther = (e) => {
    if (e.target.checked) {
      setValueCheckboxOther(e.target.value);
      onChangeOther("", true, "");
    } else {
      setValueCheckboxOther("");
      onChangeOther("", false, "");
    }
    setField("");
  };

  const handleOtherChange = (e) => {
    setField(e.target.value);
    onChangeOther(e.target.value, true, "");
  };

  const validationCallbackChek = (res) => {
    setError(res);
  };

  const { [name]: error = "" } = errors;

  const handleErrors = (errorMsg, isVisible, e) => {
    const err = { [name]: errorMsg };
    if (checkValidationRule(isVisible, validationRule)) {
      setError({ ...err });
    } else if (isVisible === "VALIDATE_ON_CLICK" && e.key !== "Enter") {
      // this added because we want to unset error on type
      setError({ err: "" });
    }

    validationCallback(!!errorMsg);
  };

  const validateFields = (isVisible, e) => {
    const { required } = validateOptions;

    if (required) {
      let errorMsg;

      var ifExist =
        valueCheckbox.filter((item) => {
          return item.selected === true;
        }).length > 0;

      var isOtherSelected = data && data.isOtherSelected;
      var isOtherText = data && data.isOtherText;

      var status =
        isOtherSelected && isOtherText === ""
          ? true
          : isOtherSelected && isOtherText !== ""
          ? false
          : !ifExist;

      if (status) {
        errorMsg = `${name} is required`;
      } else {
        errorMsg = "";
      }
      if (errorMsg) {
        handleErrors(errorMsg, isVisible, e);

        return;
      }
    }
    handleErrors("", isVisible, e);
  };

  // componentDidupdate
  useEffect(() => {
    if (validate) {
      validateFields("VALIDATE_ON_FORM_SUBMIT");
    }
  });

  return (
    <FormControl
      className={`${!error ? "chk_box_root" : "chk_box_root__error"} ${
        classes.root
      } ${classes.checkMain}`}
      style={{ border: `solid 1px ${!error ? "#ced4da" : "#db0020"} ` }}
    >
      <div style={{ marginLeft: "23px" }}>
        <FormLabel
          className={
            type === "survey"
              ? classes.formLabelrootInline
              : classes.formLabelroot
          }
        >
          {label}&nbsp;
          {validateOptions.required && type !== "survey" && (
            <span className="mendatory">*</span>
          )}
          {description && <Tooltip title={description} />}
        </FormLabel>

        <FormGroup
          style={{ display: inline ? "inline" : "inharite" }}
          id={`step_${activestep}_${name}_formgroup`}
        >
          {options.map((item, index) => {
            return (
              <Fragment key={index}>
                {toggle ? (
                  <FormControlLabel
                    name={name}
                    value={item.value || ""}
                    className={(classes.root, classes.formControlMain)}
                    style={{ display: `${item.inline}` }}
                    id={`step_${activestep}_${name}_formcontrol`}
                    control={
                      <div
                        className={classes.spanMain}
                        id={`step_${activestep}_${name}_div`}
                      >
                        <Switch
                          key={index}
                          value={item.value || ""}
                          onChange={onChangeCheckBox}
                          color="default"
                          checked={item.selected}
                          id={`step_${activestep}_${name}_switch`}
                          //style={{display:"none"}}
                        />

                        <i
                          className={`demo-icon ${item.icon} ${classes.itemMain}`} //${item.icon}`}
                        ></i>
                      </div>
                    }
                    label={item.label}
                  />
                ) : (
                  <FormControlLabel
                    className={classes.root}
                    style={{
                      fontSize: "1.3rem",
                      marginRight: "0px",
                      display: `${item.inline}`,

                      borderBottom: "1px solid #7a7a7a",
                    }}
                    id={`step_${activestep}_${name}_formcontrol`}
                    //    label={item.label}
                    control={
                      <div id={`step_${activestep}_${name}_div`}>
                        <i
                          className={`demo-icon ${item.icon}  ${classes.iconsSecond}`}
                        ></i>
                        <span
                          style={{ fontSize: "16px", color: "black" }}
                          id={`step_${activestep}_${name}_span_center`}
                        >
                          {item.label}
                        </span>
                        <Checkbox
                          value="Other"
                          name="other"
                          onChange={onChangeCheckBoxOther}
                          color="default"
                          id={`step_${activestep}_${name}_chkbox`}
                        />
                      </div>
                    }
                  />
                )}
              </Fragment>
            );
          })}
          {other ? (
            <>
              {toggle ? (
                <FormControlLabel
                  value="other"
                  name="other"
                  className={classes.root}
                  style={{ marginLeft: "10px" }}
                  id={`step_${activestep}_${name}_formcontrol`}
                  control={
                    <div>
                      <Switch
                        value="Other"
                        onChange={onChangeCheckBoxOther}
                        style={{ display: "none" }}
                        // color="primary"
                      />
                      <i
                        className={`demo-icon `}
                        style={{
                          height: "33px",
                          width: "33px",
                          marginRight: "14px",
                          color: `${col}`,
                          fontSize: "27px",
                        }}
                      >
                        {" "}
                      </i>
                    </div>
                  }
                  label="Other"
                />
              ) : (
                <FormControlLabel
                  value="other"
                  style={{ marginLeft: "16px" }}
                  control={
                    <div>
                      <Checkbox
                        value="Other"
                        name="other"
                        onChange={onChangeCheckBoxOther}
                        color="default"
                        style={{ display: "none" }}
                        id={`step_${activestep}_${name}_chkbox`}
                      />{" "}
                      <i
                        className={`demo-icon`}
                        style={{
                          height: "33px",
                          width: "33px",
                          marginRight: "14px",
                          color: `${col}`,
                          fontSize: "27px",
                        }}
                      >
                        {" "}
                      </i>{" "}
                    </div>
                  }
                  label="Other"
                />
              )}
              {valueCheckboxOther === "Other" ? (
                <div className={classes.rootInput}>
                  <InputField
                    className={classes.rootInput}
                    label={""}
                    placeHolder="Enter text here"
                    name={"otherTxt"}
                    validateOptions={{
                      check: true,
                      required: validateOptions.required,
                    }}
                    validate={validate}
                    validationCallback={(res) => validationCallbackChek(res)}
                    fieldAttributes={{
                      autoComplete: "off",
                      type: "text",
                      onChange: handleOtherChange.bind(this),
                      value: otherTxt || "",
                    }}
                    showErrorText={false}
                    themecolors={themecolors}
                  />
                </div>
              ) : (
                <> </>
              )}
            </>
          ) : (
            <></>
          )}
        </FormGroup>
      </div>
      {!!error && theme_id !== 3 && <Error errorMessage={error} />}
    </FormControl>
  );
};
CheckBox.propTypes = {
  disabled: bool,
  id: oneOfType([string.isRequired, number.isRequired]),
  isChecked: bool.isRequired,
  isError: bool,
  optionText: string,
  validationCallback: func,
  isDisabled: bool,
  label: string,
  onChange: func,
};
CheckBox.defaultProps = {
  label: "",
  optionText: "",
  isError: false,
  isChecked: false,
  isDisabled: false,
  type: "standard",
  theme_id: 0,
};
export default CheckBox;
