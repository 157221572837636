import React from "react";
import choosiLogo from "../../assets/images/Choosi-logo.png";
import goswitch from "../../assets/images/go-switch.png";
import justcompare from "../../assets/images/justcompare.png";
import joustLogo from "../../assets/images/joust_logo.png";
import ModalDialog from "../modal-dialog";
import Utils from "../../utils/index";
export default function SurveyFormSliderNew({
  data,
  heading,
  activeStep,
  handleNextStep,
  handleBackStep,
  callSwitch,
  handleSubmit,
  showFinish,
  layoutTheme,
  surveyLayout,
  isFromHome,
  siteFormData,
}) {
  var isDsSupportWithDebt = Utils.showFinishButtonOneStepEarly();
  var showPrivacyPolicyTextUnderSubmitButton =
    Utils.showPrivacyPolicyTextUnderSubmitButton(
      siteFormData.section_first_header
    );

  var showDisclaimerTextInPopup = Utils.showDisclaimerTextInPopup(
    siteFormData.section_first_header
  );

  const stepCount =
    data &&
    data.filter((item) => {
      return (
        item.type === "autocomplete" ||
        item.type === "select" ||
        item.type === "radio-group" ||
        item.type === "date" ||
        item.type === "checkbox-group" ||
        item.className.toLowerCase().includes("nm-view") ||
        item.className.toLowerCase().includes("range-slider")
      );
    }).length;

  const maxSteps = isDsSupportWithDebt
    ? data && data.length - 1
    : data && data.length;

  const progressPercent = ((activeStep + 1) / maxSteps) * 100;
  var showFormLogo = Utils.showPoweredByLogoOnForm();
  var showJustCompareLogo = Utils.showJustCompareLogoOnForm();
  var showJoustLogo = Utils.showJoustLogo();

  var item = data && data.length > 0 && data[activeStep];

  const handleFinish = () => {
    Utils.historyPush("/");
  };

  const showDisclaimerInPopup = () => {
    return (
      showDisclaimerTextInPopup && (
        <ModalDialog
          text="Disclaimer"
          title="Disclaimer"
          hideDialogAction={false}
        >
          <div className="disclaimer_survey">
            <div className="container">
              <p
                dangerouslySetInnerHTML={{
                  __html: siteFormData && siteFormData.section_first_paragraph,
                }}
              ></p>
            </div>
          </div>
        </ModalDialog>
      )
    );
  };

  const showDisclaimerUnderSubmitButton = () => {
    return (
      <>
        {showPrivacyPolicyTextUnderSubmitButton && (
          <div
            style={{
              display: "inline-block",
              marginTop: "10px",
            }}
          >
            {window.location.host === "www.myhomeloancompare.com.au" ||
              window.location.host === "myhomeloancompare.com.au" ? (
              <p className="privacypolicy">
                By submitting you agree for your personal information to be
                collected and used in accordance with this{" "}
                <a
                  style={{
                    fontWeight: "500",
                    textDecoration: "underline",
                  }}
                  href=" https://virginmoney.com.au/content/dam/virginmoney/vma-downloads/home-loans/Reward%20Me%20Home%20Loan%20Privacy%20Notification%20and%20Consent.pdf?cid=HL-59-1"
                  target="blank"
                >
                  Privacy Collection Notice
                </a>
                . We guarantee 100% privacy. Your information will not be
                shared.
              </p>
            ) : (
              <p
                className="privacypolicy"
                dangerouslySetInnerHTML={{
                  __html: siteFormData && siteFormData.section_first_paragraph,
                }}
              ></p>
            )}
          </div>
        )}
      </>
    );
  };

  const secondSurveyLayoutTopNavigation = () => {
    return (
      <div className="topNavigationSurvey">
        <div className="progressBar">
          <div className="progressBarContainer">
            <div className="progressBarText">
              <span>{activeStep + 1}</span> of{" "}
              <span>{layoutTheme === "5" && surveyLayout !== "azx11layout" ? stepCount : maxSteps}</span> answered
            </div>
            <div className="bar">
              <span style={{ width: `${progressPercent}%` }}>&nbsp;</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderNextButton = (cls, inlnecls) => {
    return (
      hideNextButton(item) && (
        <div
          className="newSurveyFooter"
          style={{
            display: activeStep === maxSteps - 1 ? "none" : "inline-block",
          }}
        >
          <button
            className={`btn btn-primary ${cls} nextBtn`}
            onClick={handleNextStep()}
            disabled={activeStep === maxSteps - 1}
            style={{
              display: activeStep === maxSteps - 1 ? "none" : inlnecls,
            }}
          >
            Next
          </button>
        </div>
      )
    );
  };

  const hideNextButton = (item) => {
    return !(
      item.type === "radio-group" ||
      item.type === "select" ||
      (item.type === "autocomplete" &&
        (item.name.toLowerCase() === "postcode" ||
          item.name.toLowerCase() === "zip" ||
          item.name.toLowerCase() === "post-code")) ||
      item.type === "date"  ||
      item.className === "nm-view"
    );
  };

  const themeLayoutItemType = ["autocomplete", "select", "radio-group", "date", "checkbox-group"];

  var submitButtonText = Utils.TextOnSubmit();
  return (
    <>
      {layoutTheme === 8 || layoutTheme === "8" ? (
        <>
          <>
            <div className="betaSurvey">
              {heading && (
                <div className="newSurveyHeading">
                  <h2>{heading}</h2>
                </div>
              )}
              <div className="betaLayoutFormContainer">
                <div className="newSurveyContent">
                  <div className="progressBar">
                    <div className="progressBarContainer">
                      <div className="progressBarText">
                        <span>{activeStep + 1}</span> /{" "}
                        <span>
                          {layoutTheme === "5" ? stepCount : maxSteps}
                        </span>
                      </div>
                      <div className="bar">
                        <span style={{ width: `${progressPercent}%` }}>
                          &nbsp;
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="questionSteps">
                    {callSwitch(item)}

                    {activeStep === maxSteps - 1 ? (
                      <>
                        <div
                          className="newSurveyFooter"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            // paddingTop: "40px",
                            paddingTop: "20px",
                            paddingBottom: "0px",
                          }}
                        >
                          <button
                            className="btn btn-submit btn-primary nextBtn submit-btn"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                        {showDisclaimerUnderSubmitButton()}
                      </>
                    ) : (
                      <></>
                    )}
                    {showFinish && isDsSupportWithDebt && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "30px",
                        }}
                      >
                        <button
                          className="btn btn-submit"
                          onClick={handleFinish}
                        >
                          Finish
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                {renderNextButton("", "")}
                {siteFormData &&
                  siteFormData.section_first_paragraph &&
                  showDisclaimerInPopup()}

                {showFormLogo && (
                  <div className="formlogo">
                    Powered by <img src={goswitch} alt="" />
                  </div>
                )}
                {showJustCompareLogo && (
                  <div className="formlogo">
                    Powered by <img src={justcompare} alt="" />
                  </div>
                )}
              </div>
            </div>
          </>
        </>
      ) : layoutTheme === 1 || layoutTheme === "1" ? (
        <>
          {(surveyLayout === "secondlayout" || isFromHome) && (
            <>
              {secondSurveyLayoutTopNavigation()}
              <div className="newSurveyPageContainer">
                <div className="probar">
                  <div className="bar">
                    <span style={{ width: `${progressPercent}%` }}>&nbsp;</span>
                  </div>
                </div>
                {heading && (
                  <div className="newSurveyHeading">
                    <h2>{heading}</h2>
                  </div>
                )}

                {/* <form> */}
                <div className="newSurveyContent">
                  <div className="questionSteps">
                    {callSwitch(item)}
                    {activeStep === maxSteps - 1 ? (
                      <>
                        <div
                          className="newSurveyFooter"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            // paddingTop: "40px",
                            paddingTop: "20px",
                            paddingBottom: "0px",
                          }}
                        >
                          <button
                            className="btn btn-submit btn-primary nextBtn submit-btn"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                        {showDisclaimerUnderSubmitButton()}
                      </>
                    ) : (
                      <></>
                    )}
                    {showFinish && isDsSupportWithDebt && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "30px",
                        }}
                      >
                        <button
                          className="btn btn-submit"
                          onClick={handleFinish}
                        >
                          Finish
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                {renderNextButton("", "")}
                {siteFormData &&
                  siteFormData.section_first_paragraph &&
                  showDisclaimerInPopup()}

                {showFormLogo && (
                  <div className="formlogo">
                    Powered by <img src={goswitch} alt="" />
                  </div>
                )}
                {showJustCompareLogo && (
                  <div className="formlogo">
                    Powered by <img src={justcompare} alt="" />
                  </div>
                )}
              </div>
            </>
          )}

          {surveyLayout === "firstlayout" && !isFromHome && (
            <>
              {heading && (
                <div className="newSurveyHeading">
                  <h2>{heading}</h2>
                </div>
              )}

              {/* <form> */}
              <div className="newSurveyContent">
                <div className="questionSteps">
                  {callSwitch(item)}

                  {activeStep === maxSteps - 1 ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "30px",
                      }}
                    >
                      <button className="btn btn-submit" onClick={handleSubmit}>
                        Submit
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                  {showFinish && isDsSupportWithDebt && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "30px",
                      }}
                    >
                      <button className="btn btn-submit" onClick={handleFinish}>
                        Finish
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {renderNextButton("float-right", "block")}
              {siteFormData &&
                siteFormData.section_first_paragraph &&
                showDisclaimerInPopup()}
              {showFormLogo && (
                <div className="formlogo">
                  Powered by <img src={choosiLogo} alt="" />
                </div>
              )}
              {showJustCompareLogo && (
                <div className="formlogo">
                  Powered by <img src={justcompare} alt="" />
                </div>
              )}
              <div className="progressBar">
                <div className="progressBarContainer">
                  <div className="progressBarText">
                    <span>{activeStep + 1}</span> of <span>{maxSteps}</span>{" "}
                    answered
                  </div>
                  <div className="bar">
                    <span style={{ width: `${progressPercent}%` }}>&nbsp;</span>
                  </div>
                </div>
              </div>
            </>
          )}

          {surveyLayout === "fifthlayout" && !isFromHome && (
            <>
              <div className="questionSteps">
                {themeLayoutItemType.includes(item.type)
                  ? secondSurveyLayoutTopNavigation()
                  : ""}
              </div>
              {/* <form> */}
              <div className="newSurveyContent">
                <div className="questionSteps">
                  {themeLayoutItemType.includes(item.type)
                    ? callSwitch(item)
                    : callSwitch(data)}
                </div>
              </div>
              {themeLayoutItemType.includes(item.type)
                ? renderNextButton("float-right", "block")
                : ""}
              {siteFormData &&
                siteFormData.section_first_paragraph &&
                showDisclaimerInPopup()}
              {themeLayoutItemType.includes(item.type) ? (
                <></>
              ) : (
                showDisclaimerUnderSubmitButton()
              )}
              {showFormLogo && (
                <div className="formlogo">
                  Powered by <img src={choosiLogo} alt="" />
                </div>
              )}
              {showJustCompareLogo && (
                <div className="formlogo">
                  Powered by <img src={justcompare} alt="" />
                </div>
              )}
            </>
          )}
        </>
      ) : // ) : layoutTheme === 2 || layoutTheme === "2" ? (
        //   <>
        //     {surveyLayout === "firstlayout" && (
        //       <>
        //         <div className="BlogThemeSurvey">
        //           <div className="pageTitleHeader">
        //             <h1>
        //               <span>{heading}</span>
        //             </h1>
        //           </div>
        //           {/* <form> */}
        //           <div className="surveyContent1">
        //             <div className="newSurveyContent">
        //               <div className="questionSteps">
        //                 {callSwitch(item)}
        //                 {activeStep === maxSteps - 1 ? (
        //                   <div
        //                     style={{
        //                       width: "100%",
        //                       display: "flex",
        //                       justifyContent: "center",
        //                       marginTop: "30px",
        //                     }}
        //                   >
        //                     <button
        //                       className={classes.DefaultSurveButton}
        //                       onClick={handleSubmit}
        //                     >
        //                       Submit
        //                     </button>
        //                   </div>
        //                 ) : (
        //                   <></>
        //                 )}
        //               </div>
        //             </div>
        //             {renderNextButton("float-right", "block")}
        //             <div className="progressBar">
        //               <div className="progressBarContainer">
        //                 <div className="progressBarText">
        //                   <span>{activeStep + 1}</span> of <span>{maxSteps}</span>{" "}
        //                   answered
        //                 </div>
        //                 <div className="bar">
        //                   <span style={{ width: `${progressPercent}%` }}>
        //                     &nbsp;
        //                   </span>
        //                 </div>
        //               </div>
        //             </div>
        //           </div>
        //         </div>
        //       </>
        //     )}
        //     {surveyLayout === "secondlayout" && (
        //       <>
        //         {secondSurveyLayoutTopNavigation()}

        //         <div className="newSurveyPageContainer">
        //           <div className="probar">
        //             <div className="bar">
        //               <span style={{ width: `${progressPercent}%` }}>&nbsp;</span>
        //             </div>
        //           </div>
        //           <div className="newSurveyHeading">
        //             <h2>{heading}</h2>
        //           </div>
        //           {/* <form> */}
        //           <div className="newSurveyContent">
        //             <div className="questionSteps">
        //               {callSwitch(item)}
        //               {activeStep === maxSteps - 1 ? (
        //                 <div
        //                   style={{
        //                     width: "100%",
        //                     display: "flex",
        //                     justifyContent: "center",
        //                     marginTop: "30px",
        //                   }}
        //                 >
        //                   <button
        //                     className="btn btn-submit"
        //                     onClick={handleSubmit}
        //                   >
        //                     Submit
        //                   </button>
        //                 </div>
        //               ) : (
        //                 <></>
        //               )}
        //             </div>
        //           </div>
        //           {renderNextButton("", "inline-block")}
        //         </div>
        //       </>
        //     )}
        //   </>
        layoutTheme === 4 || layoutTheme === "4" ? (
          <>
            {surveyLayout === "firstlayout" && (
              <>
                <div className="newSurveyHeading">
                  <h2>{heading}</h2>
                </div>
                {/* <form> */}
                <div className="newSurveyContent">
                  <div className="questionSteps">
                    {callSwitch(item)}

                    {activeStep === maxSteps - 1 ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "30px",
                        }}
                      >
                        <button className="btn btn-submit" onClick={handleSubmit}>
                          Submit
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {renderNextButton("float-right", "block")}
                <div className="progressBar">
                  <div className="progressBarContainer">
                    <div className="progressBarText">
                      <span>{activeStep + 1}</span> of <span>{maxSteps}</span>{" "}
                      answered
                    </div>
                    <div className="bar">
                      <span style={{ width: `${progressPercent}%` }}>&nbsp;</span>
                    </div>
                  </div>
                </div>
              </>
            )}
            {surveyLayout === "secondlayout" && (
              <>
                {secondSurveyLayoutTopNavigation()}

                <div className="newSurveyPageContainer">
                  <div className="probar">
                    <div className="bar">
                      <span style={{ width: `${progressPercent}%` }}>&nbsp;</span>
                    </div>
                  </div>
                  <div className="newSurveyHeading">
                    <h2>{heading}</h2>
                  </div>
                  {/* <form> */}
                  <div className="newSurveyContent">
                    <div className="questionSteps">
                      {callSwitch(item)}

                      {activeStep === maxSteps - 1 ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "30px",
                          }}
                        >
                          <button
                            className="btn btn-submit"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {renderNextButton("", "inline-block")}
                </div>
              </>
            )}
          </>
        ) : layoutTheme === 5 || layoutTheme === "5" ? (
          <>
            {/* input field all together questions - fifth layout then dynamic form */}
            {["fifthlayout", "sixthlayout", "azx5layout", "azx10layout"].includes(surveyLayout) && (
              <>
                <div className="questionSteps">
                  {(themeLayoutItemType.includes(item.type)
                    ||
                    (item.type === "number" && item.className && (item.className.toLowerCase().includes("rangeslider") ||
                      item.className.toLowerCase().includes("range-slider"))) ||
                      (item.className && (item.className.toLowerCase().includes("nm-view")))
                    )? secondSurveyLayoutTopNavigation()
                    : ""}
                </div>

                {/* <form> */}
                <div className="newSurveyContent">
                  <div className="questionSteps">
                    {(themeLayoutItemType.includes(item.type) ||
                      (item.type === "number" && item.className && (item.className.toLowerCase().includes("rangeslider") ||
                        item.className.toLowerCase().includes("range-slider"))) ||
                        (item.className && (item.className.toLowerCase().includes("nm-view"))))
                      ? callSwitch(item)
                      : callSwitch(data)}
                  </div>
                </div>
                {(themeLayoutItemType.includes(item.type) ||
                  (item.type === "number" && item.className && (item.className.toLowerCase().includes("rangeslider") ||
                    item.className.toLowerCase().includes("range-slider")))  ||
                    (item.className && item.className.toLowerCase().includes("nm-view")))
                  ? renderNextButton("float-right", "block")
                  : ""}
                {siteFormData &&
                  siteFormData.section_first_paragraph &&
                  showDisclaimerInPopup()}
                {(themeLayoutItemType.includes(item.type) ||
                  (item.type === "number" && item.className && (item.className.toLowerCase().includes("rangeslider") ||
                    item.className.toLowerCase().includes("range-slider"))) ||
                    item.className && (item.className.toLowerCase().includes("nm-view")))  ? (
                  <></>
                ) : (
                  showDisclaimerUnderSubmitButton()
                )}
                {showFormLogo && (
                  <div className="formlogo">
                    Powered by <img src={choosiLogo} alt="" />
                  </div>
                )}
                {showJustCompareLogo && (
                  <div className="formlogo">
                    Powered by <img src={justcompare} alt="" />
                  </div>
                )}
                {showJoustLogo && (
                  <div className="formlogo">
                    Powered by <img src={joustLogo} alt="" />
                  </div>
                )}
              </>
            )}
            {/* one by one questions - survey form new */}
            {["azx3layout", "azx4layout", "azx6layout", "azx7layout", "azx8layout", "azx9layout", "azx11layout"].includes(surveyLayout)
              && (
                <>
                  <div className="newSurveyHeading">
                    <h2>{heading}</h2>
                  </div>
                  {surveyLayout === "azx11layout" && secondSurveyLayoutTopNavigation()}
                  {/* <form> */}
                  <div className="newSurveyContent">
                    <div className="questionSteps">
                      {callSwitch(item)}

                      {activeStep === maxSteps - 1 ? (
                        <div
                          className="submit_outer"
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "30px",
                            borderRadius: "4px",
                            background: "#282828",
                          }}
                        >
                          <button className="btn btn-submit" onClick={handleSubmit}>
                            {submitButtonText}
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {renderNextButton("float-right", "block")}
                  {activeStep === maxSteps - 1 ? (
                    showDisclaimerUnderSubmitButton()
                  ) : (surveyLayout !== "azx11layout" &&
                    <div className="progressBar azx">
                      <div className="progressBarContainer">
                        <div className="progressBarText">
                          {activeStep + 1} of {maxSteps} answered
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
          </>
        ) : layoutTheme === 7 || layoutTheme === "7" ? (
          <>
            <div className="questionSteps">
              {themeLayoutItemType.includes(item.type)
                ? secondSurveyLayoutTopNavigation()
                : ""}
            </div>

            {/* <form> */}
            <div className="newSurveyContent">
              <div className="questionSteps">
                {themeLayoutItemType.includes(item.type)
                  ? callSwitch(item)
                  : callSwitch(data)}
              </div>
            </div>
            {themeLayoutItemType.includes(item.type)
              ? renderNextButton("float-right", "block")
              : ""}
            {siteFormData &&
              siteFormData.section_first_paragraph &&
              showDisclaimerInPopup()}
            {themeLayoutItemType.includes[item.type] ? (
              <></>
            ) : (
              showDisclaimerUnderSubmitButton()
            )}
            {showFormLogo && (
              <div className="formlogo">
                Powered by <img src={choosiLogo} alt="" />
              </div>
            )}
            {showJustCompareLogo && (
              <div className="formlogo">
                Powered by <img src={justcompare} alt="" />
              </div>
            )}
          </>
        ) : (
          <></>
        )}
    </>
  );
}
SurveyFormSliderNew.defaultProp = {
  layoutTheme: "1",
  surveyLayout: "firstlayout",
  showFinish: false,
};
