import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { object, string, func, bool } from "prop-types";
import "./input-field.scss";
import Error from "../../components/error";
import fieldValidation from "../../utils/validate";
import { checkValidationRule } from "../../utils/validate";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import Tooltip from "../../views/tool-tip";

const InputField = ({
  name,
  fieldAttributes,
  classname,
  label,
  validate,
  validateExecutive,
  placeHolder,
  validationRule,
  validateOptions,
  validationCallback,
  description,
  type,
  showErrorText,
  theme_id,
  isPhoneValid,
  subtype,
  theme,
  iconName,
  surveyLayout,
  FirstName,
  themecolors,
  activestep
}) => {
  const [errors, setError] = useState({});
  const { value } = fieldAttributes;
  const { [name]: error = "" } = errors;

  const { required } = validateOptions;
  const handleErrors = (errorMsg, isVisible, e) => {
    const err = { [name]: errorMsg };

    if (checkValidationRule(isVisible, validationRule)) {
      setError({ ...err });
    } else if (isVisible === "VALIDATE_ON_TYPE" && e.key !== "Enter") {
      // this added because we want to unset error on type
      setError({ err: "" });
    }
    validationCallback(!!errorMsg);
  };
  const useStyles = makeStyles((theme) => ({
    formControl: {},
    root: {
      display: "flex",
      flexDirection: "row",
    },
    formLabelroot: {
      display: "flex",
      paddingLeft: "0px !important",
      alignItems: "center",
      color: "#000 !important",
      fontSize: "14px",
      fontWeight: "bold",
    },
    formLabelrootInline: {
      paddingLeft: "0px !important",
      color: (props) => `${props.themecolors.labelcolor} !important`,
      fontSize: "30px",
      padding: "10px",
      display: "flex",
      fontWeight: "500",
    },
    formInputSize: {
      fontSize: "large !important",
    },
    spanIn: {
      position: "absolute",
      marginLeft: "5px",
      height: "25px",
      display: "flex",
      alignItems: "center",
    },
    inputBox: {
      paddingLeft: "25px",
      height: "20px",
    },
    mainDiv: {
      position: "relative",
      paddingTop: "0",
      margin: "0",
      //marginTop: "-29px",
      display: "inline-block",
      width: "100%",
    },
    icon: {
      // position: "absolute",
      //left: "8px",
      width: "27px",
      fontSize: "27px",
      height: "30px",
    },
    SurveyType: {
      marginTop: "43px",
    },
    OtherType: {
      marginTop: "37px",
    },
    inputDiv: {
      color: (props) => `${props.themecolors.textColor} `,
      margin: "0",
      paddingLeft: "45px !important",
      fontSize: "16px",
    },
    iconDiv: {
      width: "35px",
      height: "30px",
      position: "absolute",
      left: "8px",
      top: "10px",
    },
    themeLayout: {
      "&:hover": {
        border: (props) => `${props.themecolors.onHoverBorder} `,
      },
      border: "2px solid #c5c5c5 !important",
    },
  }));
  const classes = useStyles({ themecolors });

  const validateFields = (isVisible, e) => {
    const { check, required, reg, regMsg } = validateOptions;

    if (!check) {
      return;
    }

    if (required) {
      const errorMsg = fieldValidation.requiredMinChar(value, name);

      if (errorMsg) {
        handleErrors(errorMsg, isVisible, e);
        return;
      }
    }
    let isValidName = fieldValidation.checkRedunctWord(value, name);
    if (isValidName && subtype !== "tel" && subtype !== "email") {
      handleErrors(isValidName, isVisible, e);
      return;
    }
    if (reg) {
      const errorMsg = fieldValidation.reg(reg, value, regMsg);

      if (errorMsg) {
        handleErrors(errorMsg, isVisible, e);
        return;
      } else {

        if (subtype === "tel" && (!isPhoneValid||value==="0400123456"||value==="+61400123456")) {
          handleErrors(regMsg, isVisible, e);
          return;
        }
      }
    }
    if (name === "password") {
      const errorMsg = fieldValidation.checkPassword(value);
      if (errorMsg) {
        handleErrors(errorMsg, isVisible, e);
        return;
      }
    }
    if (name === "lastName") {
      if (FirstName === fieldAttributes.value) {
        const errorMsg = fieldValidation.checkSameNames();
        if (errorMsg) {
          handleErrors(errorMsg, isVisible, e);
          return;
        }
      }
    }

    handleErrors("", isVisible, e);
  };

  // componentDidupdate
  useEffect(() => {
    if (validate) {
      validateFields("VALIDATE_ON_FORM_SUBMIT");
    } else if (validateExecutive) {
      validateFields("VALIDATE_ON_FORM_SUBMIT");
    }
    //$("*").css("font-family", localStorage.getItem("fontFamily"));
  });

  const [colHovor, SetolHovor] = useState(themecolors.textColor);
  const changeColorHoverIn = () => {
    SetolHovor(themecolors.onHoverInColor);
  };
  const changeColorHoverOut = () => {
    SetolHovor(themecolors.textColor);
  };

  return (
    <Grid
      className={`input-box ${
        !!error && type !== "survey" && "input-box--error"
      }`}
    >
      {!!label && theme_id !== 3 && theme_id !== 2  && (
        <FormLabel
          className={
            type === "survey" && surveyLayout !== "thirdlayout"
              ? classes.formLabelrootInline
              : classes.formLabelroot
          }
          // style={{
          //   display: theme_id === "5" || theme_id === 5 ? "none" : "block",
          // }}
        >
          <div
            // style={{
            //   color: theme === "ninth-theme" && theme_id !== "5" ? "white" : "",
            // }}
            className={
              theme === "ninth-theme" && theme_id !== "5" ? "labelColor" : ""
            }
          >
            {label}&nbsp;
          </div>
          {required && type !== "survey" && (
            <span className="mendatory">*</span>
          )}
          {!!description && (
            <Tooltip title={description} matTooltipClass="tooltip" />
          )}
        </FormLabel>
      )}

      <div
        onMouseOver={changeColorHoverIn}
        onFocus={changeColorHoverIn}
        // onBlur={changeColorHoverOut}
        onMouseLeave={changeColorHoverOut}
        className={theme_id===2?"form-group" :`${classes.mainDiv}`}
      >
        <div className={classes.iconDiv}>
          <i
            className={`demo-icon ${iconName} ${classes.icon}
          ${type === "survey" ? classes.SurveyType : classes.OtherType}`}
            style={{
              color: `${colHovor}`,
            }}
          ></i>
        </div>
        <input
        id={`step_${activestep}_${name}`}
          autoComplete="off"
          className={theme_id===2?"form-control form-control-lg" :`${classes.inputDiv} 
          ${classes.themeLayout} input-box__field 
        ${type === "survey" && "input-box__field--survey"} ${classname} 

        ${classname} ${
            !!error && type !== "survey" && "input-box__field--errord"
          } `}
          onBlur={(e) => validateFields("VALIDATE_ON_BLUR", e)}
          onKeyUp={(e) => validateFields("VALIDATE_ON_TYPE", e)}
          {...fieldAttributes}
          placeholder={placeHolder}
          required
        />
        {theme_id===2&&<label>{label}<span>*</span></label>}
      </div>
      
      {!!error && showErrorText && theme_id !== 3 && (
        <Error errorMessage={error} />
      )}
    </Grid>
    
  );
};

InputField.propTypes = {
  fieldAttributes: object,
  classname: string,
  label: string.isRequired,
  name: string.isRequired,
  validationCallback: func.isRequired,
  showErrorText: bool,
};

InputField.defaultProps = {
  fieldAttributes: {},
  classname: "",
  validateOptions: {
    check: false,
  },
  showErrorText: true,
  theme_id: 0,
  fontsize: "",
  color: "",
};

export default InputField;
