import React from "react";
import { object } from "prop-types";
import $ from "jquery";
import FooterDisclaimer from "../../shared/footer-disclaimer";
import Utils from "../../../utils/index";
class FooterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, hasError: false, errorInfo: null };
  }

  componentDidMount = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  handleClick = ({ page_url, is_external, target }) => {
    const { page } = this.props;

    // if (page && page !== undefined && (page === 7 || page === "7")) {
    //   window.location.href = `/${window.location.pathname.split('/')[1]}${page_url}`;
    // } else {
      Utils.handleClickUrl(page_url, is_external, target);
    // }
  };

  renderScript = (getSiteDataObject) => {
    const { isPageRefreshed } = this.props;

    if (isPageRefreshed) {
      $("head").append(
        getSiteDataObject &&
          getSiteDataObject.fb_script &&
          `${getSiteDataObject.fb_script}`
      );
      $("body").append(
        getSiteDataObject &&
          getSiteDataObject.google_script &&
          `${getSiteDataObject.google_script}`
      );
    }
  };

  render() {
    const { siteData } = this.props;
    const privacy_policy = Utils.getPageContent(siteData, 4);
    const terms_conditions = Utils.getPageContent(siteData, 5);
    
    return (
      <div className="section-footer">
        {this.renderScript(siteData)}
        <section
          className="footer-content redesignfooter"
          style={{ backgroundColor: siteData.secondary_color }}
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-4">
                {siteData.logo ? (
                  <img
                    src={siteData.logo}
                    alt={siteData.logo_alt_text}
                    title={siteData.logo_alt_text}
                    className=""
                  />
                ) : (
                  <></>
                )}
              </div>
                
              <div className="col-sm-4 text-center">
              <div
              className="smallText"
              dangerouslySetInnerHTML={{
                __html: siteData.footer_content,
              }}
            ></div>
            <p>
              © {new Date().getFullYear()} Acquirely Pty Ltd
              {/* {siteData.name} */}
            </p>
              </div>
              <div className="col-sm-4">
                <div className="custome-links">
                  <ul>
                    {privacy_policy && (
                      <li>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleClick(privacy_policy)}
                        >
                          Privacy Policy
                        </span>
                      </li>
                    )}
                    {terms_conditions && (
                      <li>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleClick(terms_conditions)}
                        >
                          Terms and conditions
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <FooterDisclaimer siteData={siteData}/>
          </div>
        </section>
        {/* <section
          className="copyright"
          style={{ backgroundColor: siteData.primary_color }}
        >
          <div className="container">
            <p
              className="smallText"
              dangerouslySetInnerHTML={{
                __html: siteData.footer_content,
              }}
            ></p>
            <br></br>

            <p>
              © {new Date().getFullYear()} Acquirely Pty Ltd
            </p>
          </div>
        </section> */}
      </div>
    );
  }
}

FooterPage.propTypes = {
  siteData: object,
};

export default FooterPage;
